import React, { useCallback, useEffect, useState } from 'react';
import { Layout } from '../../components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getConfirmDialog, setConfirmDialog, setSnackbar } from '../../store/ui';
import { FormTypes, SnackbarTypes, TextColors } from '../../constants';
import { Container } from '../../components/Container';
import { Row } from '../../components/Row';
import { Loader } from '../../components/Loader';
import { Text } from '../../components/Text';
import { EditorialApi } from '../../api/editorial.api';
import { useParams } from 'react-router-dom';
import { Button, Table, TableBody, TableHead } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { getConfirmDialogContent, getConfirmDialogHeader } from '../../utilities';
import { TableRow } from '../../components/Table/TableRow';
import { TableCell } from '../../components/Table/TableCell';
import { Column } from '../../components/Column';
import { useUtility } from '../../utilities/hooks';
import { Box } from '../../components/Box';
import { Form } from './Form';
import { Feature } from '../../components/Feature';
import { PostApi } from '../../api/post.api';
import { PostCard } from '../../components/PostCard';
import { PostModel } from '../../models/post.model';
import { UserModel } from '../../models/user.model';
import { UserSettingsModel } from '../../models/user-settings.model';
import DeleteIcon from '@mui/icons-material/Delete';

/** @typedef {import('../../models/editorial-zone.model').EditorialZoneModel} EditorialZoneModel */
/** @typedef {import('../../models/editorial-zone.model').EditorialZoneDataModel} EditorialZoneDataModel */
/** @typedef {import('../../models/eidtorial-post.model').EditorialPostModel} EditorialPostModel */

export const EditorialContentPosts = () => {
  const dispatch = useDispatch();
  const { placement, zone } = useParams();
  /** @type {[EditorialPostModel[], (v: EditorialPostModel[]) => void]} */
  const [editorialPosts, setEditorialPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const confirmDialogProps = useSelector(getConfirmDialog);
  const { 
    result: deletedResult,
    data: deletedData,
  } = confirmDialogProps;
  const [isFormOpen, setIsFormOpen] = useState(false);

  const form = useFormik({
    initialValues: {
      placement: '',
      zone: '',
      postId: '',
    },
    validationSchema: yup.object().shape({
      placement: yup.string().required(),
      zone: yup.string().required(),
      postId: yup.string()
        .matches(
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i, 
          'The post ID has to be uuid'
        )
        .required('the post ID is required field')
    })
  });

  const {
    values,
    isValid,
    setValues,
  } = form;

  const loadEditorial = useCallback(async () => {
    try {
      setLoading(true);
      const newEditorialPosts = await EditorialApi.getEditorialPosts({
        placement,
        zone,
      });
      const postIds = newEditorialPosts.map(v => v.postId);
      if (postIds.length) {
        try {
          const { data: posts } = await PostApi.getPostsOfRecrdById({
            postIds,
          });
          newEditorialPosts.forEach(v => {
            const post = posts.find(p => p.post_id === v.postId);
            if (post) {
              v.post = new PostModel({
                post_id: post.post_id,
                title: post.title,
                post_status: post.post_status,
                video_type: post.video_type,
                width: post.width,
                height: post.height,
                thumb_url: post.thumb,
                hashtags: post.hashtag?.split(',') ?? [],
                user: new UserModel({
                  user_name: post.user_name,
                  full_name: post.user_fullname,
                  userSettings: new UserSettingsModel({
                    image_url: post.user_image,
                  })
                })
              })
            }
          });
        } catch (error) {
          console.error(error);
        }
      }
      setEditorialPosts(newEditorialPosts);
      setValues({
        placement,
        zone,
        postId: '',
      });
    } catch (error) {
      console.error(error);
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "Error: The editorial posts can't be loaded",
      }))
    } finally {
      setLoading(false);
    }
  }, [placement, zone, dispatch, setValues]);

  useEffect(() => {
    loadEditorial();
  }, [loadEditorial]);

  const createEditorialPost = useCallback(async () => {
    try {
      if (!isValid) {
        throw Error();
      }
      setLoading(true);
      const {
        placement,
        zone,
        postId,
      } = values;
      await EditorialApi.createEditorialPost({
        placement,
        zone,
        postId,
      });
      loadEditorial();
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: "The editorial post has been created",
      }))
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "Error: The editorial post hasn't been created",
      }))
    } finally {
      setLoading(false);
    }
  }, [values, isValid, dispatch, loadEditorial]);

  const deleteEditorialPost = useCallback(async () => {
    try {
      setLoading(true);
      /** @type {EditorialPostModel} */
      const {
        postId
      } = deletedData;
      await EditorialApi.deleteEditorialPost({
        placement,
        zone,
        postId,
      })
      loadEditorial();
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: "The editorial post has been deleted",
      }))
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "Error: The editorial post hasn't been deleted",
      }))
    } finally {
      setLoading(false);
    }
  }, [deletedData, zone, placement, dispatch, loadEditorial]);

  useEffect(() => {
    if (deletedResult === true) {
      dispatch(setConfirmDialog({
        result: null,
      }));
      deleteEditorialPost();
    }
  }, [deletedResult, dispatch, deleteEditorialPost])

  const {
    navigateClick,
  } = useUtility();

  const editorialPostOpenClick = useCallback((editorialPost) => {
    return navigateClick(`/posts/${editorialPost.postId}`, { label: 'Post' });
  }, [navigateClick]);

  const editorialPostDeleteClick = useCallback((editorialPost) => () => {
    dispatch(setConfirmDialog({
      open: true,
      header: getConfirmDialogHeader('the editorial post'),
      content: getConfirmDialogContent('the editorial post'),
      data: editorialPost,
      result: null,
    }))
  }, [dispatch]);

  const createEditorialPostClick = useCallback(() => {
    setIsFormOpen(true);
  }, []);

  const formSubmit = useCallback(() => {
    setIsFormOpen(false);
    createEditorialPost();
  }, [createEditorialPost]);

  const formCancel = useCallback(() => {
    setIsFormOpen(false);
  }, []);

  return (
    <Layout
      breadcrumbs={[
        {
          link: '/',
          label: 'Home',
        },
        {
          label: 'Editorial Content',
          link: '/editorial-content',
        },
        {
          label: 'Editorial Feature',
          link: `/editorial-content/features/${zone}`,
        },
        {
          label: 'Editorial Posts',
          link: `/editorial-content/${zone}/${placement}`,
        }
      ]}
    >
      <Container>
        <Column gap="5px" padding="0 0 20px 0">
          <Row gap="5px" justifyContent="space-between">
            <Box gap="5px">
              <Text color={TextColors.Label}>Placement:</Text>
              <Text color={TextColors.ID}>{placement}</Text>
            </Box>
            <Box>
              <Feature features={['insert-editorial-post']}>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={createEditorialPostClick}
                >
                  Create
                </Button>
              </Feature>
            </Box>
          </Row>
          <Row gap="5px">
            <Text color={TextColors.Label}>Zone:</Text>
            <Text color={TextColors.Status}>{zone}</Text>
          </Row>
        </Column>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Post
              </TableCell>
              <TableCell>
                Views
              </TableCell>
              <TableCell>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {editorialPosts.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>
                  No posts
                </TableCell>
              </TableRow>
            )}
            {editorialPosts.map((v, i) => (
              <TableRow key={v.zone + i}>
                <TableCell>
                  <Box maxWidth="64vw" overflow="hidden">
                    {v?.post && (
                      <PostCard
                        post={v?.post}
                        onClick={editorialPostOpenClick(v)}
                        isDeleteButton={false}
                      />
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  {v.postViews}
                </TableCell>
                <TableCell>
                  <Row gap="10px" width="100px" alignItems="center">
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={editorialPostOpenClick(v)}
                    >
                      Open
                    </Button>
                    <DeleteIcon
                      style={{ fill: 'red' }}
                      onClick={editorialPostDeleteClick(v)}
                    />
                  </Row>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <FormikProvider value={form}>
          <Form
            formType={FormTypes.Create}
            isOpen={isFormOpen}
            onSubmit={formSubmit}
            onCancel={formCancel}
          />
        </FormikProvider>
        <Loader loading={loading} />
      </Container>
    </Layout>
  );
};  