import { urlSearchParamSetter } from "../utilities";
import { http, httpOfRecrd } from "./http";

export class PartnerApi {
  static async getPartners(data = {}) {
    const {
      code,
      cursor,
      size,
    } = data;
    const params = new URLSearchParams();
    const setter = urlSearchParamSetter(params);
    setter('cursor', cursor);
    setter('size', size);
    setter('code', code);
    const response = await httpOfRecrd.get(`/internal/creator_partners/v3/codes?${params}`);
    return response.data;
  }

  static async createPartner(data = {}) {
    const {
      code,
      userId,
    } = data;
    if (code === undefined || userId === undefined) {
      throw Error();
    }
    await http.post(`/partners`, {
      code,
      userId,
    });
  }

  static async updatePartner(data = {}) {
    const {
      codeOld,
      codeNew,
    } = data;
    if (codeOld === undefined || codeNew === undefined) {
      throw Error();
    }
    await httpOfRecrd.put(`/internal/creator_partners/v3/code_change`, {
      code_old: codeOld,
      code_new: codeNew,
    });
  }

  static async getUserPartners(data = {}) {
    const {
      code,
      cursor,
      size,
    } = data;
    if (code === undefined) {
      throw Error();
    }
    const params = new URLSearchParams();
    const setter = urlSearchParamSetter(params);
    setter('code', code);
    setter('cursor', cursor);
    setter('size', size);
    const response = await httpOfRecrd.get(`/internal/creator_partners/v3/users?${params}`);
    return response.data;
  }
}