import React, { useCallback, useEffect, useState } from 'react';
import { Layout } from '../../components/Layout';
import styled from '@emotion/styled';
import { Button, Table, TableBody, TableHead } from '@mui/material';
import { AccessApi } from '../../api/access.api';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MuiDeleteIcon from '@mui/icons-material/Delete';
import { TableRow } from '../../components/Table/TableRow';
import { TableCell } from '../../components/Table/TableCell';
import { Box } from '../../components/Box';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getConfirmDialog, setConfirmDialog, setSnackbar } from '../../store/ui';
import { getConfirmDialogContent, getConfirmDialogHeader} from '../../utilities';
import { FormTypes, SnackbarTypes } from '../../constants';
import { useSearch } from '../../components/Search/hook';
import { Search } from '../../components/Search';
import * as yup from 'yup';
import { Form } from './Form';
import { Text } from '../../components/Text';
import { Row } from '../../components/Row';
import { DialogOfRoles } from '../../components/DialogOfRoles';
import { Features as AllFeatures } from '../../constants/features';

const DeleteIcon = styled(MuiDeleteIcon)`
  color: #f00;
  fill: #f00;
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FeatureKeys = {
  FeatureSlug: 'feature_slug',
  FeatureDescription: 'feature_description',
  FeaturePrivileges: 'privileges'
}

const searchItems = [
  {
    label: 'Featue Slug',
    value: FeatureKeys.FeatureSlug,
  },
  {
    label: 'Feature Description',
    value: FeatureKeys.FeatureDescription,
  },
  {
    label: 'Feature Privileges',
    value: FeatureKeys.FeaturePrivileges,
  }
]

export const Features = () => {
  const dispatch = useDispatch();
  const [privileges, setPrivileges] = useState([]);
  const [features, setFeatures] = useState([]);
  const [formType, setFormType] = useState(FormTypes.Create);
  const [feature, setFeature] = useState(null);
  const [rows, setRows] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const confirmDialogProps = useSelector(getConfirmDialog);
  const { 
    result: confirmDialogResult,
    data: confirmDialogData,
  } = confirmDialogProps;

  const {
    searchItem,
    searchText,
    searchInputChange,
    searchSelectChange,
  } = useSearch({
    key: 'search:features',
    item: searchItems[0],
    text: '',
  });
  const [dialogOfRoles, setDialogOfRoles] = useState({
    open: false,
    value: [],
    title: 'Privileges',
    readonly: true,
  });

  useEffect(() => {
    const handler = event => {
      if (event.key === '\\') {
        console.log(AllFeatures);
      }
    };
    window.addEventListener('keyup', handler)
    return () => window.removeEventListener('keyup', handler);
  }, []);

  useEffect(() => {
    const {
      value,
    } = searchItem;
    if (searchText === '') {
      setRows(features);
      return;
    }
    if (value === FeatureKeys.FeatureSlug) {
      const filteredRows = features.filter(
        v => v.feature_slug.toLowerCase().includes(searchText.toLowerCase()),
      );
      setRows(filteredRows);
    }
    if (value === FeatureKeys.FeatureDescription) {
      const filteredRows = features.filter(
        v => v.feature_description.toLowerCase().includes(searchText.toLowerCase()),
      );
      setRows(filteredRows);
    }
    if (value === FeatureKeys.FeaturePrivileges) {
      const filteredRows = features.filter(
        feature => {
          const privileges = feature?.privileges ?? [];
          const foundPrivilege = privileges.find(
            privilege => privilege.toLowerCase().includes(searchText.toLowerCase())
          );
          return !!foundPrivilege;
        }
      );
      setRows(filteredRows);
    }
  }, [features, searchText, searchItem]);

  const form = useFormik({
    initialValues: {
      featureSlug: '',
      featureDescription: '',
      privileges: [],
    },
    validationSchema: yup.object().shape({
      featureSlug: yup.string()
        .min(2, 'The feature slug must be at least 2 characters')
        .required('The feature slug is required field'),
      featureDescription: yup.string()
        .min(2, 'The feature description must be at least 2 characters')
        .required('The feature description is required field'),
      privileges: yup.array()
        .of(yup.string())
        .min(1, 'The privileges should have at least one privilege')
        .required(),
    })
  });

  const {
    values,
    isValid,
    setValues,
  } = form;

  const loadFeatures = useCallback(async () => {
    const newFeatures = await AccessApi.getFeatures();
    setFeatures(newFeatures);
    const newPrivileges = 
      newFeatures
        .map(feature => feature.privileges)
        .flat()
        .filter((feature, index, features) => features.indexOf(feature) === index);
    setPrivileges(newPrivileges);
  }, []);

  useEffect(() => {
    loadFeatures();
  }, [loadFeatures]);

  const deleteFeature = useCallback(async () => {
    try {
      dispatch(setConfirmDialog({
        open: false,
      }));
      await AccessApi.deleteFeature(confirmDialogData.feature_slug);
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: 'The feature has been deleted',
      }));
      loadFeatures();
    } catch (error) {
      dispatch(setSnackbar({
        open: false,
        type: SnackbarTypes.Error,
        content: 'Error: The feature hasn\'t been deleted',
      }));
    }
  }, [confirmDialogData, dispatch, loadFeatures]);

  useEffect(() => {
    if (confirmDialogResult === true) {
      deleteFeature()
    }
  }, [confirmDialogResult, deleteFeature]);

  const createFeatureClick = useCallback(() => {
    setFormType(FormTypes.Create);
    setFeature(null);
    setValues({
      ...values,
      featureSlug: '',
      featureDescription: '',
      privileges: [],
    })
    setDialogOpen(true);
  }, [values, setValues]);

  const editFeatureClick = useCallback((selectedFeature) => () => {
    setFormType(FormTypes.Update);
    setFeature(selectedFeature);
    setValues({
      ...values,
      featureSlug: selectedFeature.feature_slug,
      featureDescription: selectedFeature.feature_description,
      privileges: [...(selectedFeature?.privileges ?? [])],
    })
    setDialogOpen(true);
  }, [values, setValues]);

  const deleteFeatureClick = useCallback((selectedFeature) => () => {
    dispatch(setConfirmDialog({
      open: true,
      header: getConfirmDialogHeader(),
      content: getConfirmDialogContent('feature'),
      result: null,
      data: selectedFeature,
    }))
  }, [dispatch]);

  const createFeature = useCallback(async () => {
    try {
      await AccessApi.createFeature({
        featureSlug: values.featureSlug,
        featureDescription: values.featureDescription,
        privileges: values.privileges,
      });
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: 'the new feature has been created'
      }));
      loadFeatures();
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: 'Error: the new feature hasn\'t been created',
      }));
    } finally {
      setDialogOpen(false);
    }
  }, [values, loadFeatures, dispatch]);

  const updateFeature = useCallback(async () => {
    try {
      await AccessApi.updateFeature(feature.feature_slug, {
        featureSlug: values.featureSlug,
        featureDescription: values.featureDescription,
        privileges: values.privileges,
      });
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: 'the new feature has been updated'
      }));
      loadFeatures();
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: 'Error: the new feature hasn\'t been updated',
      }));
    } finally {
      setDialogOpen(false);
    }
  }, [feature, values, loadFeatures, dispatch]);

  const dialogClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const dialogSubmit = useCallback(() => {
    if (!isValid) {
      return;
    }
    if (formType === FormTypes.Create) {
      createFeature()
    }
    if (formType === FormTypes.Update) {
      updateFeature();
    }
  }, [formType, isValid, createFeature, updateFeature]);

  const dialogOfRolesOpen = useCallback((feature) => () => {
    setDialogOfRoles(state => ({
      ...state,
      open: true,
      value: feature.privileges,
    }))
  }, []);

  const dialogOfRolesClose = useCallback(() => {
    setDialogOfRoles(state => ({
      ...state,
      open: false,
    }))
  }, []);

  return (
    <Layout
      breadcrumbs={[
        {
          link: '/',
          label: 'Home',
        },
        {
          label: 'Feature Access',
          link: '/features',
        }, 
      ]}
    >
      <Container>
        <Row gap="10px">
          <Box flex="1">
            <Search
              items={searchItems}
              item={searchItem}
              text={searchText}
              onInputChange={searchInputChange}
              onSelectChange={searchSelectChange}
            />
          </Box>
          <Box padding="0px 0 0 180px" height="56px">
            <Button
              variant="contained"
              color="warning"
              onClick={createFeatureClick}
            >
              Create
            </Button>
          </Box>
        </Row>
        <Container>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Text>
                    Feature Slug
                  </Text>
                </TableCell>
                <TableCell>
                  <Text>
                    Feature Description
                  </Text>
                </TableCell>
                <TableCell>
                  <Text>
                    Privileges
                  </Text>
                </TableCell>
                <TableCell>
                  <Text>
                    Actions
                  </Text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(feature => (
                <TableRow key={feature.feature_slug}>
                  <TableCell>
                    <Text>{feature.feature_slug}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{feature.feature_description}</Text>
                  </TableCell>
                  <TableCell>
                    <Box onClick={dialogOfRolesOpen(feature)}>
                      <Text>{feature.privileges?.join(', ')}</Text>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="inline-flex">
                      <Box>
                        <ModeEditIcon onClick={editFeatureClick(feature)} />
                      </Box>
                      <Box>
                        <DeleteIcon onClick={deleteFeatureClick(feature)} />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <FormikProvider value={form}>
            <Form
              formType={formType}
              show={dialogOpen}
              privileges={privileges}
              onCancel={dialogClose}
              onSubmit={dialogSubmit}
            />
          </FormikProvider>
          <DialogOfRoles
            title={dialogOfRoles.title}
            open={dialogOfRoles.open}
            value={dialogOfRoles.value}
            readonly={dialogOfRoles.readonly}
            onClose={dialogOfRolesClose}
          />
        </Container>
      </Container>
    </Layout>
  )
};