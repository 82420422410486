import axios from "axios";
import { getStore } from '../store';
import { logoutAction } from "../store/auth";

export const httpOfMusi = axios.create({
  baseURL: process.env.REACT_APP_MUSI_BASE_URL,
});

let retryOfMusi = 0;

httpOfMusi.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, error => {
    return Promise.reject(error);
  }
);

httpOfMusi.interceptors.response.use(
  response => {
    retryOfMusi = 0;
    return response;
  },
  error => {
    if (error?.response?.status === 401) {
      retryOfMusi++;
      if (retryOfMusi === 3) {
        const store = getStore();
        store.dispatch(logoutAction());
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export const httpOfRecrd = axios.create({
  baseURL: process.env.REACT_APP_RECRD_BASE_URL,
});

let retryOfRecrd = 0;

httpOfRecrd.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token:web');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, error => {
    return Promise.reject(error);
  }
);

httpOfRecrd.interceptors.response.use(
  response => {
    retryOfRecrd = 0;
    return response;
  },
  error => {
    if (error?.response?.status === 401) {
      retryOfRecrd++;
      if (retryOfRecrd === 3) {
        const store = getStore();
        store.dispatch(logoutAction());
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export const http = httpOfMusi;