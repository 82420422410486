import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { grey } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { store } from './store';
import { App } from './App';

import './index.css';
import { CssBaseline } from '@mui/material';

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#fff',
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#fff',
          fill: '#fff',
          cursor: 'pointer',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: '#fff',
          },
          color: '#fff',
        }
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': {
            border: '1px solid #9e9e9e',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '& .Mui-disabled': {
            color: '#9e9e9e !important',
          },
        },
        root: {
          '& .Mui-disabled': {
            color: '#9e9e9e !important',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            color: '#9e9e9e !important',
          },
          color: '#9e9e9e !important',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#ff0039',
            color: '#fff',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#ff0039',
            color: '#fff',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            '-webkit-text-fill-color': '#9e9e9e !important',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#9e9e9e',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#9e9e9e',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#9e9e9e',
          },
          color: '#9e9e9e',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: 'white',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: 'auto',
        },
      },
    },
  },
  palette: {
    background: {
      default: '#1a1b1f',
      paper: '#1a1b1f',
    },
    primary: {
      main: grey[500],
    },
    secondary: {
      main: '#ff0039',
    },
    text: {
      primary: grey[500],
      secondary: grey[500],
    },
  },
  typography: {
    h6: {
      color: grey[500],
    },
  },
});

ReactDOM.render(
  <>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <App />
        </LocalizationProvider>
      </Provider>
    </ThemeProvider>
  </>,
  document.getElementById('root')
);
