import { useCallback, useRef, useState } from "react";

export const useSearch = (props = {
  key: undefined,
  item: null,
  text: '',
}) => {
  const key = props?.key;
  let text = props?.text ?? '';
  const item = props?.item;

  const storage = JSON.parse(localStorage.getItem('search') ?? '{}');
  if (key && storage[key]) {
    text = storage[key].text;
  }

  const searchRef = useRef({
    searchText: text,
    searchTextAsDebounced: text,
    searchItem: item,
  });
  
  const debounceRef = useRef(0);
 
  const [searchTextAsDebounced, setSearchTextAsDebounced] = useState(text ?? '');
  const [searchText, setSearchText] = useState(text ?? '');
  const [searchItem, setSearchItem] = useState(item);

  const storePagination = useCallback((field, value) => {
    if (key === undefined) {
      return;
    }
    const storage = JSON.parse(localStorage.getItem('search') ?? '{}');
    storage[key] = storage[key] ?? {};
    storage[key][field] = value;
    localStorage.setItem('search', JSON.stringify(storage));
  }, [key]);

  const getSearchTextByItemValue = useCallback((searchItemValue, isDebounced = false) => {
    const {
      searchItem,
      searchText,
      searchTextAsDebounced,
    } = searchRef.current;
    const text = isDebounced ? searchTextAsDebounced : searchText;
    if (searchItem.value !== searchItemValue) {
      return undefined;
    }
    if (text === '') {
      return undefined;
    }
    return text;
  }, []);

  const searchInputChange = useCallback((text) => {
    setSearchText(text);
    searchRef.current.searchText = text;
    storePagination('text', text);
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      setSearchTextAsDebounced(text);
      searchRef.current.searchTextAsDebounced = text;
    }, 500);
  }, [storePagination]);

  const searchSelectChange = useCallback((item) => {
    setSearchItem(item)
    searchRef.current.searchItem = item;
    storePagination('item', item);
  }, [storePagination]);

  return {
    searchRef,
    searchText,
    searchTextAsDebounced,
    searchItem,
    getSearchTextByItemValue,
    searchInputChange,
    searchSelectChange,
  }
}