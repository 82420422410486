import React from 'react';
import { Container } from "../Container";
import { Text } from "../Text";
import { Layout } from '../Layout';

export const NoPage = () => {
  return (
    <Layout>
      <Container>
        <Text>The page is under construction.</Text>
      </Container>
    </Layout>
  )
};