import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row } from "../../../../../components/Row";
import { Container } from "../../../../../components/Container";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Text } from '../../../../../components/Text';
import { TextColors } from '../../../../../constants';
import styled from '@emotion/styled';
import { useUtility } from '../../../../../utilities/hooks';
import { Collapse } from '@mui/material';

const StyledCollapse = styled(Collapse)`
  background-color: #111;
  border-radius: 6px;
  width: 100%;
`;

const Code = styled.pre`
  color: #fff;
  padding: 10px;
  width: 100%;
`;

const Hightlight = styled.code`
  color: yellow;
`;

/** @typedef {import('../../../../../models/s3-post.model').S3PostModel} S3PostModel */

/**
 * @param {object} props
 * @param {S3PostModel} props.s3Post 
 * @param {string} props.searchValue
 * @returns {import('react').ReactNode}
 */
export const S3Card = ({
  s3Post,
  searchValue,
}) => {
  const [jsonText, setJsonText] = useState('');
  const [open, setOpen] = useState(false);
  const [amountOfLines, setAmountOfLines] = useState(0);
  const {
    copyClick,
  } = useUtility();

  useEffect(() => {
    try {
      const { json } = s3Post;
      const newJsonText = JSON.stringify(json, null, 2);
      const newAmountOfLines = newJsonText.split('\n').length;
      setAmountOfLines(newAmountOfLines)
      setJsonText(newJsonText);
      setOpen(newAmountOfLines <= 3);
    } catch { }
  }, [s3Post]);

  const arrowClick = useCallback(() => {
    const newOpen = !open;
    setOpen(newOpen);
  }, [open]);

  const formattedJsonText = useMemo(() => {
    if (searchValue === '' || searchValue === undefined) {
      return jsonText;
    }
    const index = jsonText.indexOf(searchValue);
    if (index === -1) {
      return jsonText;
    }
    let left = jsonText.slice(0, index);
    let target = jsonText.slice(index, index + searchValue.length);
    let right = jsonText.slice(index + searchValue.length);
    return (
      <>
        <span>{left}</span>
        <Hightlight>{target}</Hightlight>
        <span>{right}</span>
      </>
    )
  }, [searchValue, jsonText])

  return (
    <Container>
      <Row gap="5px">
        <Text>Bucket:</Text>
        <Text color={TextColors.ID}>{s3Post.bucket}</Text>
          <ContentCopyIcon 
            onClick={copyClick(
              jsonText, 
              `The content of ${s3Post.bucket} bucket has been copied`,
            )} 
          />
          {amountOfLines > 3 && (
            <>
              {open && (
                <ArrowDropUpIcon onClick={arrowClick} />
              )}
              {!open && (
                
                <ArrowDropDownIcon onClick={arrowClick} />
              )}
            </>
          )}
      </Row>
      <Row>
        <StyledCollapse in={open} collapsedSize="103px">
          <Code>
            {formattedJsonText}
          </Code>
        </StyledCollapse>
      </Row>
    </Container>
  )
}