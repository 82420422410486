import React from "react";
import styled from "@emotion/styled";
import { Icon, Text } from "../shared";
import { Card } from "./Card";
import { CardTypes, PreviewTypes } from "../constants";

const View = styled.div`
  display: flex;
  gap: 12px;
  padding: 110.17px;
`;

const ViewLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ViewRight = styled.div`
  display: flex;
  flex-direction: column;
  padding: 45.95px 0 0 0;
  width: 738.5px;
  height: auto;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TitleText = styled.div`
  display: flex;
`;

const TitleActions = styled.div`
  display: flex;
  gap: 8.5px;
`;

const TitleButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 89.25px;
  height: 89.25px;
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor ?? '#1A1A1A'};
  cursor: pointer;
`;

const Description = styled.div`
  display: flex;
  width: 738.5px;
  height: 114.84px;
  border-radius: 8px;
  background-color: #FFC8FB;
  padding: 13.95px 170.5px 13.95px 11.75px;
`;

const Cards = styled.div`
  display: flex;
  gap: 10px;
  margin: 12.31px 0 0 0;
`;

export const Preview = ({
  type,
  posts,
  texts,
  prompts,
}) => (
  <View>
    <ViewLeft>
      <Card 
        type={type === PreviewTypes.Featured ? CardTypes.Large : CardTypes.Small} 
        postImageUrl={posts[0]?.thumb ?? "/images/preview-thumb-1.png"}
        postHashtags={posts[0]?.hashtag?.split(',') ?? []}
        postDescription={posts[0]?.title}
        userName={posts[0]?.user_fullname || posts[0]?.user_name}
        userImage={posts[0]?.user_image}
        repliedUserName={posts[0]?.origin?.user_fullname || posts[0]?.origin?.user_name}
        repliedUserImage={posts[0]?.origin?.user_image}
        repliedPostTitle={posts[0]?.origin?.title}
      />
      {type === PreviewTypes.Editorial && (
        <Card 
          type={CardTypes.Small} 
          postImageUrl={posts[3]?.thumb ?? "/images/preview-thumb-1.png"}
          postHashtags={posts[3]?.hashtag?.split(',') ?? []}
          postDescription={posts[3]?.title}
          userName={posts[3]?.user_fullname || posts[3]?.user_name}
          userImage={posts[3]?.user_image}
          repliedUserName={posts[3]?.origin?.user_fullname || posts[3]?.origin?.user_name}
          repliedUserImage={posts[3]?.origin?.user_image}
          repliedPostTitle={posts[3]?.origin?.title}
        />
      )}
    </ViewLeft>
    <ViewRight>
      <Title>
        <TitleText>
          <Text
            fontFamily="Suisse Intl Condensed"
            fontWeight="700"
            fontSize="120px"
            lineHeight="105.6px"
            letterSpacing="0px"
            color="#000000"
            textTransform="uppercase"
          >
            {texts?.title ?? 'FEATURED'}
          </Text>
        </TitleText>
        <TitleActions>
          <TitleButton backgroundColor="#FFC8FB">
            <Icon
              src="/icons/preview-action-camera.svg"
              width="43.21px"
              height="43.21px"
            />
          </TitleButton>
          <TitleButton >
            <Icon
              src="/icons/preview-action-next.svg"
              width="43.21px"
              height="43.21px"
            />
          </TitleButton>
        </TitleActions>
      </Title>
      <Description>
        <Text
          fontWeight="500"
          fontSize="18px"
          lineHeight="23.4px"
          color="#1A1A1A"
        >
          {texts?.body ?? `
            Immerse yourself in a world where the stars align with innovation, 
            as renowned personalities showcase their unique experiences and interactions within the Recrd community.
          `}
        </Text>
      </Description>
      <Cards>
        <Card
          postImageUrl={posts[1]?.thumb ?? "/images/preview-thumb-2.png"}
          postHashtags={posts[1]?.hashtag?.split(',') ?? []}
          postDescription={posts[1]?.title}
          userName={posts[1]?.user_fullname || posts[1]?.user_name}
          userImage={posts[1]?.user_image}
          repliedUserName={posts[1]?.origin?.user_fullname || posts[1]?.origin?.user_name}
          repliedUserImage={posts[1]?.origin?.user_image}
          repliedPostTitle={posts[1]?.origin?.title}
        />
        <Card
          postImageUrl={posts[2]?.thumb ?? "/images/preview-thumb-3.png"}
          postHashtags={posts[2]?.hashtag?.split(',') ?? []}
          postDescription={posts[2]?.title}
          userName={posts[2]?.user_fullname || posts[2]?.user_name}
          userImage={posts[2]?.user_image}
          repliedUserName={posts[2]?.origin?.user_fullname || posts[2]?.origin?.user_name}
          repliedUserImage={posts[2]?.origin?.user_image}
          repliedPostTitle={posts[2]?.origin?.title}
        />
      </Cards>
    </ViewRight>
  </View>
)