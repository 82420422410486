import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container } from "../../components/Container";
import { Layout } from '../../components/Layout';
import { Button, Table, TableBody, TableHead } from '@mui/material';
import { Row } from '../../components/Row';
import { Box } from '../../components/Box';
import { FormTypes, SnackbarTypes, TextColors } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { hasFeatures } from '../../store/auth';
import { EditorialApi } from '../../api/editorial.api';
import { TableRow } from '../../components/Table/TableRow';
import { TableCell } from '../../components/Table/TableCell';
import { Loader } from '../../components/Loader';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Form } from './Form'; 
import { useUtility } from '../../utilities/hooks';
import { setSnackbar } from '../../store/ui';
import { Text } from '../../components/Text';
import styled from '@emotion/styled';

/** @typedef {import('../../models/editorial-zone.model').EditorialZoneModel} EditorialZoneModel */

const ZoneRow = styled(TableRow)`
  transition: .3s all;
  cursor: pointer;
  &:hover {
    background-color: black;
  }
`;

export const EditorialContent = () => {
  const dispatch = useDispatch();
  const isCreate = useSelector(hasFeatures(['insert-editorial-zone']));
  /** @type {[EditorialZoneModel[], (v: EditorialZoneModel[]) => void]} */
  const [zones, setZones] = useState([]);
  const [isoCodes, setIsoCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const {
    navigateClick
  } = useUtility();

  const testForOptionaFields = (fields) => 
    fields.country?.trim()?.length > 0 ||
    fields.region?.trim()?.length > 0 ||
    fields.city?.trim()?.length > 0 ||
    fields.locale?.trim()?.length > 0;

  const form = useFormik({
    initialValues: {
      zone: '',
      country: '',
      region: '',
      city: '',
      locale: '',
    },
    validationSchema: yup.object().shape({
      zone: yup.string()
        .test({
          test: (value, _context) => value?.trim()?.length > 0,
          message: 'the zone has to have at least 1 symbol'
        })
        .required(),
      country: yup.string()
        .test({ test: (_, context) => testForOptionaFields(context.parent) }),
      region: yup.string()
        .test({ test: (_, context) => testForOptionaFields(context.parent) }),
      city: yup.string()
        .test({ test: (_, context) => testForOptionaFields(context.parent) }),
      locale: yup.string()
        .test({ test: (_, context) => testForOptionaFields(context.parent) }),
    })
  });

  const {
    values,
    isValid,
    setFieldValue,
    setValues,
  } = form;

  const load = useCallback(async () => {
    try {
      setLoading(true);
      const { data: newZones } = await EditorialApi.getZones();
      const { data: newIsoCodes } = await EditorialApi.getIsoCodes();
      const newZonesWithCountryNames = newZones.map(zone => {
        const isoCode = newIsoCodes.find(isoCode => isoCode.iso === zone.country);
        if (isoCode) {
          zone.countryName = isoCode.name;
        }
        return zone;
      });
      setZones(newZonesWithCountryNames);
      setIsoCodes(newIsoCodes);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const zoneFeaturesClick = useCallback(
    (zoneData) => navigateClick(`/editorial-content/features/${zoneData.zone}`, { label: 'Editorial Feature' }),
    [navigateClick],
  );

  const createZone = useCallback(async () => {
    try {
      if (!isValid) {
        throw Error();
      }
      setLoading(true);
      const {
        placement,
        country,
        city,
        locale,
        region,
        zone,
      } = values;
      await EditorialApi.createEditorialZone({
        placement,
        country,
        city,
        locale,
        region,
        zone,
      });
      setValues({
        placement,
        city: '',
        country: '',
        locale: '',
        region: '',
        zone: '',
      });
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: "The new zone has been created",
      }));
      load();
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "Error: The new zone hasn't been created",
      }))
    } finally {
      setLoading(false);
    }
  }, [values, isValid, setValues, dispatch, load]);

  const createZoneClick = useCallback(
    () => {
      setValues({
        ...values,
      })
      setIsFormOpen(true);
    }, [values, setValues],
  );

  const formSubmit = useCallback(() => {
    setIsFormOpen(false);
    createZone();
  }, [createZone]);

  const formCancel = useCallback(() => {
    setIsFormOpen(false);
  }, []);

  const regions = useMemo(() => {
    const result = isoCodes
      .map(v => v.region)
      .filter((v, i, a) => a.indexOf(v) === i)
      .filter(v => v !== '');
    return result;
  }, [isoCodes]);

  const region = values.region;

  useEffect(() => {
    setFieldValue('country', '');
  }, [region, setFieldValue])

  const countries = useMemo(() => {
    if (region === '') {
      return isoCodes;
    }
    const result = isoCodes
      .filter(v => v.region === region);
    return result;
  }, [region, isoCodes]);

  return (
    <Layout
      breadcrumbs={[
        {
          link: '/',
          label: 'Home',
        },
        {
          label: 'Editorial Content',
          link: '/editorial-content',
        }
      ]}
    >
      <Container>
        <Row justifyContent="flex-end" padding="0 0 20px 0">
          <Box>
            {isCreate && (
              <Button
              variant="contained"
              color="warning"
              onClick={createZoneClick}
              >
                Create
              </Button>
            )}
           </Box>
        </Row>
        <Container>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Text color={TextColors.Label}>
                    Zone
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color={TextColors.Label}>
                    Region
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color={TextColors.Label}>
                    Country
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color={TextColors.Label}>
                    City
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color={TextColors.Label}>
                    Location
                  </Text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {zones.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7}>
                    No zones
                  </TableCell>
                </TableRow>
              )}
              {zones.map((v, index) => (
                <ZoneRow key={v.zone + index} onClick={zoneFeaturesClick(v)}>
                  <TableCell>
                    <Text color={TextColors.ID}>
                      {v.zone}
                    </Text>
                  </TableCell>
                  <TableCell>
                    {v.region}
                  </TableCell>
                  <TableCell>
                    ({v.country}) {v.countryName}
                  </TableCell>
                  <TableCell>
                    {v.city}
                  </TableCell>
                  <TableCell>
                    {v.locale}
                  </TableCell>
                </ZoneRow>
              ))}
            </TableBody>
          </Table>
        </Container>
        <FormikProvider value={form}>
          <Form
            formType={FormTypes.Create}
            isOpen={isFormOpen}
            countries={countries}
            regions={regions}
            onSubmit={formSubmit}
            onCancel={formCancel}
          />
        </FormikProvider>
        <Loader loading={loading} />
      </Container>
    </Layout>
  )
};