import { FeedbackModel } from '../models/feedback.model';
import { urlSearchParamSetter } from '../utilities';
import { httpOfRecrd } from './http';

export class FeedbackApi {
  /**
   * @param {object} props 
   * @param {number} props.pageState
   * @param {number} props.limit
   * @param {Recrd<string, string>} props.filter
   */
  static async getFeedbacks(props) {
    const {
      user_id,
      feedback_id,
      user_name,
      status,
      subject,
      limit,
      offset,
    } = props;
    const params = new URLSearchParams();
    const setter = urlSearchParamSetter(params);
    setter('user_id', user_id);
    setter('feedback_id', feedback_id);
    setter('user_name', user_name);
    setter('status', status);
    setter('subject', subject);
    setter('limit', limit ?? 10);
    setter('offset', offset ?? 0);
    const response = await httpOfRecrd.get(`/engage_feedback/v3/feedback/list?${params}`);
    const { data } = response; 
    const feedbacks = data?.feedback.map(v => new FeedbackModel(v));
    return {
      data: feedbacks,
      meta: { 
        total: data?.total ?? 0,
      },
      response,
    };
  }


  static async updateFeedback(feedbackId, data) {
    const {
      description,
      status
    } = data;
    await httpOfRecrd.put(`/engage_feedback/v3/feedback/update/${feedbackId}`, {
      description,
      status,
    });
  }
}
