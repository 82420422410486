import styled from '@emotion/styled';
import { Box as MuiBox } from '@mui/system';
import React from 'react';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { Avatar } from '../../../components/Avatar';
import { Text } from '../../../components/Text';
import { Box } from '../../../components/Box';
import { TextColors, TextFontSizes } from '../../../constants';
import { useUtility } from '../../../utilities/hooks';

const StyledContentCopyIcon = styled(ContentCopyIcon)`
  cursor: pointer;
`;

const Container = styled(MuiBox)`
  display: flex;
  width: 100%;
  margin: 0 0 25px 0;
`;

/** @typedef {import('../../../models/user.model').UserModel} UserModel */

/**
 * @param {object} props 
 * @param {UserModel} props.user
 * @returns 
 */
export const Header = ({
  user,
}) => {
  const {
    copyClick,
  } = useUtility();
  return (
    <Container>
      <Box>
        <Avatar
          image={user?.userSettings?.image_url} 
          userName={user?.user_name} 
        />
      </Box>
      <Box flexDirection="column" marginLeft="20px">
        <Box>
          <Text color={TextColors.Name2}>{user?.full_name ? user?.full_name : <>&lt;No Full Name&gt;</>}</Text>
        </Box>
        <Box>
          <Text color={TextColors.Name}>@{user?.user_name}</Text>
        </Box>
        <Box>
          <Text color={TextColors.Label} fontSize={TextFontSizes.Small}>
            {user?.userSettings?.bio_short ? user?.userSettings?.bio_short : <>&lt;No Short Bio&gt;</>}
          </Text>
        </Box>
      </Box>
      <Box flex="1" justifyContent="flex-end">
        <Box gap="10px">
          <Box>
            <Text color={TextColors.ID} fontSize={TextFontSizes.Small}>
              {user?.user_id}
            </Text>
          </Box>
          <Box>
            <StyledContentCopyIcon onClick={copyClick(user.user_id, 'the user ID has been copied')} />
          </Box>
        </Box>
      </Box>
    </Container>
  )
};