import React, { useCallback } from 'react';
import { Box } from '../../Box';
import { Row } from '../../Row';
import styled from '@emotion/styled';
import { Collapse } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { LogApi } from '../../../api/log.api';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupIcon from '@mui/icons-material/Group';
import FlagIcon from '@mui/icons-material/Flag';
import HttpsIcon from '@mui/icons-material/Https';
import ViewListIcon from '@mui/icons-material/ViewList';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import CampaignIcon from '@mui/icons-material/Campaign';
import ReportIcon from '@mui/icons-material/Report';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import HandshakeIcon from '@mui/icons-material/Handshake';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getLayout, setLayout } from '../../../store/ui';
import { Feature } from '../../Feature';
import { Text } from '../../Text';

const Container = styled(Box)`
  flex-direction: column;
`;

const Divider = styled.div`
  width: 205px;
  height: 1px;
  background-color: gray;
  margin: 5px 0 5px 15px;
`;

const MenuItem = styled(Box)`
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  height: 48px;
  cursor: pointer;
`;

const MenuItemIcon = styled(Box)`
  width: 56px;
  color: rgb(158, 158, 158);
`;

const MenuItemLabel = styled(Box)`
  flex: 1;
`;

/** @typedef {import('../../../models/user.model').UserModel} UserModel */

export const Menu = ({
  onMenuItemClick
}) => {
  const dispatch = useDispatch();
  const layout = useSelector(getLayout);
  const {
    collapses: {
      reports
    },
  } = layout;
  const setLayoutAction = useCallback((payload) => dispatch(setLayout(payload)), [dispatch]);

  const collapseClick = useCallback((key) => () => {
    const { collapses } = layout;
    setLayoutAction({
      collapses: {
        ...collapses,
        [key]: !collapses[key]
      }
    });
  }, [layout, setLayoutAction]);

  const menuItemClick = useCallback((path) => () => {
    LogApi.create({
      message: `press on the menu item to go to ${path}`
    });
    onMenuItemClick(path);
  }, [onMenuItemClick]);
  
  return (
    <Container>
      <MenuItem onClick={menuItemClick('/users')}>
        <MenuItemIcon>
          <GroupIcon />
        </MenuItemIcon>
        <MenuItemLabel>
          <Text>
            Users
          </Text>
        </MenuItemLabel>
      </MenuItem>
      <MenuItem onClick={menuItemClick('/posts')}>
        <MenuItemIcon>
          <DynamicFeedIcon />
        </MenuItemIcon>
        <MenuItemLabel>
          <Text>
            Posts
          </Text>
        </MenuItemLabel>
      </MenuItem>
      <MenuItem onClick={collapseClick('reports')}>
        <MenuItemIcon>
          <ReportIcon />
        </MenuItemIcon>
        <MenuItemLabel>
          <Row justifyContent="space-between">
            <Text>
              Reports
            </Text>
            {reports && (
              <KeyboardArrowUpIcon />
            )}
            {!reports && (
              <KeyboardArrowDownIcon />
            )}
          </Row>
        </MenuItemLabel>
      </MenuItem>
      <Collapse in={reports}>
        <Container padding="0 0 0 15px">
          <Feature features={['read-menu-reported-users']}>
            <MenuItem onClick={menuItemClick('/reports/users')}>
              <MenuItemIcon>
                <FlagCircleIcon />
              </MenuItemIcon>
              <MenuItemLabel>
                <Text>
                  Users
                </Text>
              </MenuItemLabel>
            </MenuItem>
          </Feature>
          <Feature features={['read-menu-reported-posts']}>
            <MenuItem onClick={menuItemClick('/reports/posts')}>
              <MenuItemIcon>
                <FlagIcon />
              </MenuItemIcon>
              <MenuItemLabel>
                <Text>
                  Posts
                </Text>
              </MenuItemLabel>
            </MenuItem>
          </Feature>
        </Container>
      </Collapse>
      <Divider />
      <Feature features={['menu-partners']}>
        <MenuItem onClick={menuItemClick('/partners')}>
          <MenuItemIcon>
            <HandshakeIcon />
          </MenuItemIcon>
          <MenuItemLabel>
            <Text>
              Partners
            </Text>
          </MenuItemLabel>
        </MenuItem>
      </Feature>
      <Divider />
      <Feature features={['read-menu-editorial']}>
        <MenuItem onClick={menuItemClick('/editorial-content')}>
          <MenuItemIcon>
            <LocationOnIcon />
          </MenuItemIcon>
          <MenuItemLabel>
            <Text>
              Editorial
            </Text>
          </MenuItemLabel>
        </MenuItem>
      </Feature>
      <Divider />
      <Feature features={['read-menu-feedback']}>
        <MenuItem onClick={menuItemClick('/feedback')}>
          <MenuItemIcon>
            <QuestionAnswerIcon />
          </MenuItemIcon>
          <MenuItemLabel>
            <Text>
              Feedback
            </Text>
          </MenuItemLabel>
        </MenuItem>
      </Feature>
      <Divider />
      <Feature features={['read-menu-activity-logs']}>
        <MenuItem onClick={menuItemClick('/activity-logs')}>
          <MenuItemIcon>
            <ViewListIcon />
          </MenuItemIcon>
          <MenuItemLabel>
            <Text>
              Activity Logs
            </Text>
          </MenuItemLabel>
        </MenuItem>
      </Feature>
      <Feature features={['read-menu-privileges']}>
        <MenuItem onClick={menuItemClick('/privileges')}>
          <MenuItemIcon>
            <AdminPanelSettingsIcon />
          </MenuItemIcon>
          <MenuItemLabel>
            <Text>
              Privileges
            </Text>
          </MenuItemLabel>
        </MenuItem>
      </Feature>
      <Feature features={['read-menu-privileges-external']}>
        <MenuItem onClick={menuItemClick('/privileges/external')}>
          <MenuItemIcon>
            <AdminPanelSettingsIcon />
          </MenuItemIcon>
          <MenuItemLabel>
            <Text>
              Privileges (External)
            </Text>
          </MenuItemLabel>
        </MenuItem>
      </Feature>
      <Feature features={['read-menu-feature-access']}>
        <MenuItem onClick={menuItemClick('/features')}>
          <MenuItemIcon>
            <HttpsIcon />
          </MenuItemIcon>
          <MenuItemLabel>
            <Text>
              Feature Access
            </Text>
          </MenuItemLabel>
        </MenuItem>
      </Feature>
      <Divider />
      <Feature features={['read-menu-campaigns']} workInProgress>
        <MenuItem onClick={menuItemClick('/campaigns')}>
          <MenuItemIcon>
            <CampaignIcon />
          </MenuItemIcon>
          <MenuItemLabel>
            <Text>
              Campaigns (WIP)
            </Text>
          </MenuItemLabel>
        </MenuItem>
      </Feature>
      <Feature features={['read-menu-celebrities']} workInProgress>
        <MenuItem onClick={menuItemClick('/celebrities')}>
          <MenuItemIcon>
            <WorkspacePremiumIcon />
          </MenuItemIcon>
          <MenuItemLabel>
            <Text>
              Celebrities (WIP)
            </Text>
          </MenuItemLabel>
        </MenuItem>
      </Feature>
      <Feature features={['read-menu-tv-producer']} workInProgress>
        <MenuItem onClick={menuItemClick('/tv')}>
          <MenuItemIcon>
            <LiveTvIcon />
          </MenuItemIcon>
          <MenuItemLabel>
            <Text>
              TV Producer (WIP)
            </Text>
          </MenuItemLabel>
        </MenuItem>
      </Feature>
    </Container>
  );
}