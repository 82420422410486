import React from 'react';
import { Dialog as MuiDialog } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { getDialog, setDialog } from "../../store/ui"
import { useCallback } from "react";

export const Dialog = () => {
  const dispatch = useDispatch();
  const dialogProps = useSelector(getDialog);

  const closeDialog = useCallback(() => {
    dispatch(setDialog({
      ...dialogProps,
      open: false,
    }));
  }, [dialogProps, dispatch]);

  return (
    <MuiDialog
      open={dialogProps.open}
      onClose={closeDialog}
    >
      {dialogProps.children}
    </MuiDialog>
  )
}