import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Row } from '../Row';
import { Text } from '../Text';
import CloseIcon from '@mui/icons-material/Close';
import { Column } from '../Column';
import { 
  AmberAutomatedReportedPosts,
  GeneralRoles, 
  ManuallyReportedPosts,
  RedAutomatedReportedPosts,
} from '../../constants/roles';
import { Checkbox } from './Checkbox';
import { Accesses } from '../../constants/access';

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: 80vw !important;
    height: 80vh !important;
    max-width: 80vw !important;
    max-height: 80vh !important;
  }

  & .MuiDialogContent-root {
    scrollbar-color: #333 #111;
    &::-webkit-scrollbar {
      background: #111;
    }
    &::-webkit-scrollbar-thumb {
      background: #333;
    }
  }
`;

/**
 * @param {object} props 
 * @param {string} props.access
 * @param {boolean} props.open
 * @param {string?} props.title
 * @param {string[]} props.value
 * @param {boolean} props.readonly
 * @param {(selectedRoles: string[]) => void} props.onSubmit
 * @param {() => void} props.onClose
 * @returns {JSX.Element}
 */
export const DialogOfRoles = ({
  access = Accesses.Internal,
  open,
  value,
  readonly,
  onSubmit,
  onClose,
}) => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setRoles(value);
  }, [value])

  const roleLabel = useCallback((role) => {
    return `${role.value} - ${role.label}`
  }, []);

  const roleChecked = useCallback((role, isModeratorRole) => {
    const selectedValue = role.value;
    const roleIndex = roles.findIndex(v => {
      if (isModeratorRole) {
        return v?.toString() === `Mo-${selectedValue}`
      }
      return v?.toString() === selectedValue
    });
    return roleIndex !== -1;
  }, [roles]);

  const roleChange = useCallback((role, isModeratorRole) => () => {
    if (readonly) {
      return;
    }
    const selectedValue = role.value;
    const roleIndex = roles.findIndex(v => {
      if (isModeratorRole) {
        return v?.toString() === `Mo-${selectedValue}`
      }
      return v?.toString() === selectedValue
    });
    const newRoles = [...roles];
    if (roleIndex !== -1)
      newRoles.splice(roleIndex, 1);
    else {
      if (isModeratorRole) {
        newRoles.push(`Mo-${selectedValue}`);
      } else {
        newRoles.push(selectedValue);
      }
    }
    setRoles(newRoles);
  }, [roles, readonly]);

  const submitClick = useCallback(() => {
    const newRoles = [...roles];
    onSubmit(newRoles);
    onClose();
  }, [roles, onSubmit, onClose]);

  const cancelClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const allRoleChecked = useCallback((selectedRoles) => {
    const areAllRolesSelected = selectedRoles.every(
      v => roles.findIndex(r => r.toString() === `Mo-${v.value}`) !== -1
    );
    return areAllRolesSelected;
  }, [roles]);

  const allRoleChange = useCallback((selectedRoles, checked) => () => {
    if (readonly) {
      return;
    }
    const newRoles = [...roles];
    selectedRoles.forEach(v => {
      const roleIndex = newRoles.findIndex(r => r.toString() === `Mo-${v.value}`);
      if (!checked) {
        if (roleIndex !== -1) return;
        newRoles.push(`Mo-${v.value}`)
      } else {
        if (roleIndex === -1) return;
        newRoles.splice(roleIndex, 1);
      }
    });
    setRoles(newRoles);
  }, [roles, readonly]);

  const generalRoleAccessFilter = useCallback((v) => {
    if (access === Accesses.Internal) {
      return true;
    } else if (access === Accesses.External) {
      return v.value !== 'Sa';
    } else {
      throw Error();
    }
  }, [access]);

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle />
      <DialogContent>
        <Row gap="10px">
          <Column gap="100px">
            <Row>
              <Text fontWeight="bold">
                General Roles
              </Text>
            </Row>
            <Column gap="1px" width="210px">
              {GeneralRoles.filter(generalRoleAccessFilter).map(v => (
                <Checkbox 
                  key={v.value}
                  label={roleLabel(v)}
                  checked={roleChecked(v)} 
                  onChange={roleChange(v)}
                />
              ))}
            </Column>
          </Column>
          <Column gap="25px">
            <Row justifyContent="space-between">
              <Text fontWeight="bold">
                Moderator Roles
              </Text>
              <CloseIcon onClick={onClose} />
            </Row>
            <Row height="70vh" gap="10px">
              <Column gap="10px">
                <Row>
                  <Text fontWeight="bold">
                    Manually Reported Posts
                  </Text>
                </Row>
                <Column gap="1px" width="240px">
                  <Checkbox 
                    label="All"
                    checked={allRoleChecked(ManuallyReportedPosts)} 
                      onChange={allRoleChange(
                        ManuallyReportedPosts,
                        allRoleChecked(ManuallyReportedPosts),
                      )}
                  />
                  {ManuallyReportedPosts.map(v => (
                    <Checkbox
                      key={v.value}
                      label={roleLabel(v)}
                      checked={roleChecked(v, true)} 
                      onChange={roleChange(v, true)}
                    />
                  ))}
                </Column>
              </Column>
              <Column gap="10px">
                <Row>
                  <Text fontWeight="bold">
                    Amber Automated Reported Posts
                  </Text>
                </Row>
                <Column gap="1px" width="310px">
                  <Checkbox 
                    label="All"
                    checked={allRoleChecked(AmberAutomatedReportedPosts)} 
                      onChange={allRoleChange(
                        AmberAutomatedReportedPosts,
                        allRoleChecked(AmberAutomatedReportedPosts),
                      )}
                  />
                  {AmberAutomatedReportedPosts.map(v => (
                    <Checkbox 
                      key={v.value}
                      label={roleLabel(v)}
                      checked={roleChecked(v, true)} 
                      onChange={roleChange(v, true)}
                    />
                  ))}
                </Column>
              </Column>
              <Column gap="10px">
                <Row>
                  <Text fontWeight="bold">
                    Red Automated Reported Posts
                  </Text>
                </Row>
                <Column gap="1px">
                  <Checkbox 
                    label="All"
                    checked={allRoleChecked(RedAutomatedReportedPosts)} 
                      onChange={allRoleChange(
                        RedAutomatedReportedPosts,
                        allRoleChecked(RedAutomatedReportedPosts),
                      )}
                  />
                  {RedAutomatedReportedPosts.map(v => (
                    <Checkbox 
                      key={v.value}
                      label={roleLabel(v)}
                      checked={roleChecked(v, true)} 
                      onChange={roleChange(v, true)}
                    />
                  ))}
                </Column>
              </Column>
            </Row>
          </Column>
        </Row>
      </DialogContent>
      <DialogActions>
        {!readonly && (
          <Row gap="10px" justifyContent="center">
            <Button
              variant="contained"
              color="error"
              onClick={cancelClick}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="info"
              onClick={submitClick}
            >
              Ok
            </Button>
          </Row>
        )}
      </DialogActions>
    </StyledDialog>
  );
}