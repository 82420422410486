import { httpOfRecrd } from "./http";
import { S3PostModel } from '../models/s3-post.model'

export class AwsApi {
  static async getS3Posts({ postId }) {
    const response = await httpOfRecrd.get(
      `/internal/v3/s3/${postId}`,
    );
    const s3Posts = response.data.map(v => new S3PostModel(v));
    return {
      s3Posts,
    };
  }
}