import React, { useCallback } from 'react';
import { Container } from "../../../../components/Container";
import { Button, Table } from '@mui/material';
import { TableBody } from '../../../../components/Table/TableBody';
import { TableRow } from '../../../../components/Table/TableRow';
import { TableCell } from '../../../../components/Table/TableCell';
import { useFormikContext } from 'formik';
import { Row } from '../../../../components/Row';
import { Text } from '../../../../components/Text';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { useUtility } from '../../../../utilities/hooks';
import { Box } from '../../../../components/Box';
import { PostCard } from '../../../../components/PostCard';
import { TextColors } from '../../../../constants';

/** @typedef {import('../../../../models/user.model').UserModel} UserModel */
/** @typedef {import('../../../../models/post.model').PostModel} PostModel */

/**
 * @param {object} props
 * @param {UserModel} props.user 
 * @param {PostModel[]} props.posts
 * @param {() => void} props.onSuspend
 * @returns {JSX.Element}
 */
export const NFT = ({
  posts,
  onSuspend,
}) => {
  const form = useFormikContext();
  const {
    values,
  } = form;

  const isSuspended = values.sale_allowed && values.sale_trusted;

  const {
    copyClick,
    navigateClick,
  } = useUtility();

  const postClick = useCallback((post) => navigateClick(`/posts/${post.post_id}`), [navigateClick]);

  return (
    <Container gap="10px">
      <Table>
        <TableBody backgroundColor="transparent">
          <TableRow>
            <TableCell>
              <Box gap="10px">
                <Text color={TextColors.Label}>
                  Allowed:
                </Text>
                <Text color={TextColors.Choice}>
                  {values.sale_allowed ? 'Yes' : 'No'}
                </Text>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Box gap="10px">
                <Text color={TextColors.Label}>
                  Trusted:
                </Text>
                <Text color={TextColors.Choice}>
                  {values.sale_trusted ? 'Yes' : 'No'}
                </Text>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Box gap="10px">
                <Text color={TextColors.Label}>
                  Wallet Address:
                </Text>
                <Text color={TextColors.ID}>
                  {values.sale_wallet_addresses}
                </Text>
                {values.sale_wallet_addresses && (
                  <ContentCopyIcon onClick={copyClick(
                    values.sale_wallet_addresses, 
                    'The wallet address has been copied',
                  )} />
                )}
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Row justifyContent="flex-end">
        <Button
          variant="contained"
          color="info"
          onClick={onSuspend}
        >
          {isSuspended && 'Suspend'}
          {!isSuspended && 'Unsuspend'}
        </Button>
      </Row>
      <Container>
        {posts.map(post => (
          <PostCard
            key={post.post_id}
            post={post}
            onClick={postClick(post)}
          />
        ))}
      </Container>
    </Container>
  );
}