import styled from '@emotion/styled';
import { css } from '@emotion/react';
import React, { useCallback } from 'react';

const commonImage = css`
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 12px;
  cursor: pointer;
`;

const AvatarImage = styled.img`
  ${commonImage}
`;

const AvatarWithoutImage = styled.div`
  ${commonImage}

  display: flex;
  justify-content: center;
  align-items: center;

  line-height: 48px;
  font-size: 48px;
  font-weight: normal;
  color: white;

  background: linear-gradient(45deg, rgba(0, 28, 227, 1) 0%, rgba(0, 226, 26, 1) 100%);
  z-index: 1;
  transition: opacity 0.4s linear;

  & span {
    position: relative;
    z-index: 3;
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 90px;
    height: 90px;
    border-radius: 12px;
    cursor: pointer;
    background: linear-gradient(45deg, rgba(0, 226, 26, 1) 0%, rgba(0, 28, 227, 1) 100%);
    transition: opacity 0.4s linear;
    z-index: 2;
    opacity: 0;
  }
`;

/**
 *
 * @param {object} props
 * @param {string|null|undefined} props.image
 * @param {string} props.userName
 * @param {() => void|undefined} props.onPress
 * @returns
 */
export const Avatar = ({ 
  image, 
  userName, 
  onPress = () => {},
}) => {
  const handlePress = useCallback(() => {
    onPress();
  }, [onPress]);

  return (
    <>
      {image && <AvatarImage src={image} alt="avtar" onClick={handlePress} />}
      {!image && (
        <AvatarWithoutImage onClick={handlePress}>
          <span>{!image && userName && userName[0]?.toUpperCase()}</span>
        </AvatarWithoutImage>
      )}
    </>
  );
};