
export const UserAttributeReadFeatures = {
  Private: 'read-attribute-private',
  Campaign: 'read-attribute-campaign',
  Celebrity: 'read-attribute-celeb',
  DemoMode: 'read-attribute-demo-mode',
  SafeContent: 'read-attribute-safe-content',
  Internal: 'read-attribute-internal',
  Tester: 'read-attribute-tester',
};

export const PartnerAttributeReadFeatures = {
  Silver: 'read-attribute-silver',
  Gold: 'read-attribute-gold',
  Platinum: 'read-attribute-platinum',
  Invitation: 'read-attribute-invitation',
};

export const UserAttributeEditFeatures = {
  Private: 'edit-attribute-private',
  Campaign: 'edit-attribute-campaign',
  Celebrity: 'edit-attribute-celeb',
  DemoMode: 'edit-attribute-demo-mode',
  SafeContent: 'edit-attribute-safe-content',
  Internal: 'read-attribute-internal',
  Tester: 'edit-attribute-tester',
};

export const PartnerAttributeEditFeatures = {
  Silver: 'edit-attribute-silver',
  Gold: 'edit-attribute-gold',
  Platinum: 'edit-attribute-platinum',
  Invitation: 'edit-attribute-invitation',
};

export const ReadFeatures = {
  UserAttributeReadFeatures: { ...UserAttributeReadFeatures },
  PartnerAttributeReadFeatures: { ...PartnerAttributeReadFeatures },
};

export const EditFeatures = {
  UserAttributeEditFeatures: { ...UserAttributeEditFeatures },
  PartnerAttributeEditFeatures: { ...PartnerAttributeEditFeatures },
};

export const Features = {
  ...ReadFeatures,
  ...EditFeatures,
}