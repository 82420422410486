import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { authKey, authReducer } from './auth';
import { uiKey, uiReducer } from './ui';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  combineReducers({
    [uiKey]: uiReducer,
    [authKey]: authReducer,
  }),
  undefined,
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware
    )
  )
);

export const getStore = () => store;