import AuthApi from '../../api/auth.api';
import { ActionStatuses } from '../../constants';

const initialState = {
  user: null,
  loadUser: ActionStatuses.Idle,
}

export const authKey = 'auth';

const SET_USER = `${authKey}/SET_USER`;
const LOAD_USER = `${authKey}/LOAD_USER`;

const setUser = user => ({
  type: SET_USER,
  payload: user,
});

const setLoadUser = actionStatus => ({
  type: LOAD_USER,
  payload: actionStatus,
});

export const loadUserAction = () => async dispatch => {
  try {
    dispatch(setLoadUser(ActionStatuses.Pending));
    const user = await AuthApi.getUser();
    if (!user) {
      throw Error();
    }
    dispatch(setUser(user));
    dispatch(setLoadUser(ActionStatuses.Fulfilled));
  } catch (error) {
    dispatch(setLoadUser(ActionStatuses.Rejected));
  }
};

export const logoutAction = () => async dispatch => {
  dispatch(setLoadUser(ActionStatuses.Idle));
  dispatch(setUser(null));
}

export const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER:
      return {
        ...state,
        user: payload,
      };
    case LOAD_USER:
      return {
        ...state,
        loadUser: payload,
      }
    default:
      return state;
  };
};

export const getAuth = state => state[authKey];

export const getUser = state => getAuth(state).user;

export const getLoadUser = state => getAuth(state).loadUser;

/** 
 * @param {string[]} privileges 
 * @returns {boolean}
*/
export const hasPrivileges = privileges => state => {
  if (privileges === undefined) {
    return true;
  }
  const authState = getAuth(state);
  const user = authState?.user;
  if (!user) {
    return false;
  }
  const userPrivileges = user?.userPrivileges;
  if (!userPrivileges) {
    return;
  }
  const attributes = userPrivileges.attributes ?? [];
  return privileges.every(privilege => attributes.includes(privilege));
}

/** 
 * @param {string[]} features 
 * @returns {boolean}
*/
export const hasFeatures = features => state => {
  if (features === undefined) {
    return true;
  }
  const authState = getAuth(state);
  const user = authState?.user;
  if (!user) {
    return false;
  }
  const userFeatures = user.userFeatures ?? [];
  if (!userFeatures) {
    return false;
  }
  const slugs = userFeatures.map(userFeature => userFeature.feature_slug);
  return features.every(feature => slugs.includes(feature));
};
