import styled from '@emotion/styled';

export const Text = styled.span`
  display: ${({ display }) => display ?? 'inline'};
  font-family: ${({ fontFamily }) => fontFamily ?? 'Suisse Intl Regular'};
  font-size: ${({ fontSize }) => fontSize ?? '14px'};
  font-weight: ${({ fontWeight }) => fontWeight ?? 'normal'};
  line-height: ${({ lineHeight }) => lineHeight ?? 'normal'};
  color: ${({ color }) => color ?? '#FFFFFF'};
  opacity: ${({ opacity }) => opacity ?? 1};
  letter-spacing: ${({ letterSpacing }) => letterSpacing ?? 'normal'};
  text-transform: ${({ textTransform }) => textTransform ?? 'none'};
  white-space: ${({ whiteSpace }) => whiteSpace ?? 'normal'};
`;

export const Image = styled.img`
  display: inline-flex;
  border-radius: ${({ borderRadius }) => borderRadius ?? '0px'};
  border: ${({ border }) => border ?? 'none'};
  width: ${({ width }) => width ?? 'auto'};
  height: ${({ height }) => height ?? 'auto'};
  object-fit: ${({ objectFit }) => objectFit ?? 'fill'};
`;

export const Icon = styled.img`
  display: inline-flex;
  width: ${({ width }) => width ?? 'auto'};
  height: ${({ height }) => height ?? 'auto'};
  object-fit: ${({ objectFit }) => objectFit ?? 'fill'};
`;