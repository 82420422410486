import React from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog as MuiDialog } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { getConfirmDialog, setConfirmDialog } from "../../store/ui"
import { useCallback } from "react";

export const ConfirmDialog = () => {
  const dispatch = useDispatch();
  const confirmDialogProps = useSelector(getConfirmDialog);

  const closeDialog = useCallback(() => {
    dispatch(setConfirmDialog({
      ...confirmDialogProps,
      open: false,
      result: false,
      data: null,
    }));
  }, [confirmDialogProps, dispatch]);

  const cancelConfirmation = useCallback(() => {
    dispatch(setConfirmDialog({
      ...confirmDialogProps,
      open: false,
      result: false,
      data: null,
    }));
  }, [confirmDialogProps, dispatch]);

  const submitConfirmation = useCallback(() => {
    dispatch(setConfirmDialog({
      ...confirmDialogProps,
      open: false,
      result: true,
    }));
  }, [confirmDialogProps, dispatch]);

  return (
    <MuiDialog
      open={confirmDialogProps.open}
      onClose={closeDialog}
    >
      <DialogTitle>
        {confirmDialogProps.header}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {confirmDialogProps.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelConfirmation} autoFocus>
          No
        </Button>
        <Button onClick={submitConfirmation}>
          Yes
        </Button>
      </DialogActions>
    </MuiDialog>
  )
}