import React from 'react';
import styled from "@emotion/styled";
import { Icon, Image, Text } from '../../shared';

const View = styled.div`
  display: flex;
  gap: 12px;
`;

const ViewImage = styled.div`
  position: relative;
  display: flex;
  width: 161px;
  height: 162.82px;
`;

const ViewProfile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoType = styled.div`
  display: flex;
  gap: 4px;
  padding: 0 0 8px 0;
`;

const InfoDescription = styled.div`
  display: flex;
  width: 277px;
  padding: 0 0 6px 0;
`;

const InfoHashtags = styled.div`
  display: flex;
  gap: 6px;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10.62px;
`;

const ProfileImage = styled.div`
  display: flex;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const ProfileName = styled.div`
  display: flex;
`;

const ProfileReply = styled.div`
  display: flex;
  gap: 4px;
`;

const Rebound = styled.div`
  position: absolute;
  top: 9.28px;
  left: 10.2px;
  width: 40px;
  height: 40px;
`;

const ReboundImage = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #000000;
  overflow: hidden;
`;

const ReboundIcon = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Item = ({
  thumbUrl,
  rebound = false,
}) => (
  <View>
    <ViewImage>
      <Image
        src={thumbUrl}
        width="100%"
        height="100%"
        borderRadius="8px"
        objectFit="cover"
      />
      {rebound && (
        <Rebound>
          <ReboundIcon>
            <Icon src="/icons/preview-union.svg" />
          </ReboundIcon>
          <ReboundImage>
            <Image 
              src="/images/preview-thump-profile.png" 
              width="40px"
              height="40px"
              borderRadius="8px"
            />
          </ReboundImage>
        </Rebound>
      )}
    </ViewImage>
    <ViewProfile>
      <Profile>
        <ProfileImage>
          <Image
            src="/images/preview-user.png"
            width="40px"
            height="40px"
            borderRadius="50%"
            objectFit="cover"
          />
        </ProfileImage>
        <ProfileInfo>
          <ProfileName>
            <Text
              fontWeight="600"
              lineHeight="16.8px"
              color="#1B1B1B"
            >
              Franko Komljenovic
            </Text>
          </ProfileName>
          <ProfileReply>
            <Text
              fontWeight="500"
              fontSize="12px"
              lineHeight="14.4px"
              color="#1A1A1A"
              opacity="0.5"
            >
              Replied to
            </Text>
            <Text
              fontWeight="500"
              fontSize="12px"
              lineHeight="14.4px"
              color="#FF7F51"
            >
              Charleene Williamson
            </Text>
          </ProfileReply>
        </ProfileInfo>
      </Profile>
      <Info>
        <InfoType>
          {!rebound && (
            <>
              <Icon
                src="/icons/preview-union.svg"
                width="16.79px"
                height="10.89px"
              />
              <Text
                fontSize="12px"
                fontWeight="500"
                lineHeight="12px"
                color="#1B1B1B"
              >
                Original
              </Text>
            </>
          )}
          {rebound && (
            <>
              <Icon
                src="/icons/preview-camera.svg"
                width="16.79px"
                height="10.89px"
              />
              <Text
                fontSize="12px"
                fontWeight="500"
                lineHeight="12px"
                color="#1B1B1B"
              >
                Rebound
              </Text>
            </>
          )}
        </InfoType>
        <InfoDescription>
          <Text
            fontWeight="500"
            lineHeight="18.2px"
            color="#1B1B1B"
          >
            I can’t believe I’ve met a couple of singers in the cutest café!! 
            <span role="img" aria-label="wow">😱</span>
          </Text>
        </InfoDescription>
        <InfoHashtags>
          {['#comedy', '#cats', '#podcast', '#podcast'].map((v, i) => (
            <Text
              key={i}
              display="inline-flex"
              fontSize="12px"
              fontWeight="500"
              lineHeight="14.4px"
              color="#AEAEB2"
            >
              {v}
            </Text>
          ))}
        </InfoHashtags>
      </Info>
    </ViewProfile>
  </View>
)