import { UserModel } from "../models/user.model";
import { http } from "./http";

/** 
 * @typedef {object} WfeAuthModel 
 * @property {string} token
 * @property {string} refresh
 */

/**
 * @typedef {object} MusiAuthModel
 * @property {string} token
 */

/** 
 * @typedef {object} SignInByGoogleTokenResponse
 * @property {WfeAuthModel} wfe
 * @property {MusiAuthModel} musi
 */

export default class AuthApi {
  /**
   * @returns {Promise<UserModel>}
   */
  static async getUser() {
    const params = new URLSearchParams({
      includes: ['userSettings', 'userPrivileges', 'userFeatures'],
    });
    const response = await http.get(`/auth/user?${params}`);
    const user = new UserModel(response.data.user);
    return user;
  }

  /**
   * @param {object} props
   * @param {string} props.email
   * @param {string} props.password
   * @returns {Promise<string>}
   */
  static async loginByEmail({
    email,
    password,
  }) {
    const response = await http.post('/auth/login/email', {
      email,
      password,
    });
    const token = response.data;
    return token;
  }
  /**
   * @param {object} params
   * @param {string} params.token
   * @returns {Promise<SignInByGoogleTokenResponse>}
   */
  static async loginByGoogle({
    token,
  }) {
    try {
      const response = await http.post('/auth/login/google', {
        token,
      });
      return  response.data;
    } catch (error) {
      console.error(error);
    }
  }
}
