
/** @typedef {import('./user.model').UserModel} UserModel */

export class UserOfReportedUserModel {
  /** @type {string} */
  user_id;
  /** @type {string} */
  user_name;
  /** @type {string} */
  user_fullname;
  /** @type {string|null} */
  user_image;
  /** @type {string} */
  bio_full;
  /** @type {string} */
  bio_short;
  /** @param {UserOfReportedUserModel} data */
  constructor(data) {
    Object.assign(this, data);
  }
}

export class ReportedUserModel {
  /** @type {string} */
  reportId;
  /** @type {string} */
  status;
  /** @type {UserOfReportedUserModel} */
  userId;
  /** @type {UserOfReportedUserModel} */
  reporterId;
  /** @type {string} */
  description;
  /** @type {number} */
  reason;
  /// CONSTRUCTOR
  /** @param {Partial<ReportedUserModel>} data */
  constructor(data) {
    Object.assign(this, data);
  }
}

/** @type {ReportedUserModel[]} */
export const reportedUsersMock = [
  {
    "reportId": "4fa85f64-5717-4562-b3fc-2c963f66afa6",
    "userId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "reporterId": "21d0c210-15fa-11ed-90e8-c5364c0f0b5c",
    "status": "PENDING",
    "description": "test",
    "reason": 1301,
    "user": {
      "user_id": '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      "user_name": "user name",
      "user_fullname": "",
      "user_image": "https://content.recrd.com/12669040-4044-11ef-9a66-9bb365196c80_avatar",
      "bio_full": "",
      "bio_short": "",
    },
    "reporter": {
      "user_id": '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      "user_name": "reporter name",
      "user_fullname": "",
      "user_image": "https://content.recrd.com/12669040-4044-11ef-9a66-9bb365196c80_avatar",
      "bio_full": "",
      "bio_short": "",
    }
  }
]