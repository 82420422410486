import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Navigation } from './Navigation';
import { Preview } from './Preview';
import { useLocation } from 'react-router-dom';
import { PreviewTypes } from './constants';
import { Explore } from './Explore';
import { EditorialApi } from '../../api/editorial.api';
import { EditorialPlacements, FeaturedPlacements } from '../../constants';
import { PostApi } from '../../api/post.api';

/// design

const Page = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 50px 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EditorialContentPreview = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const placementParam = query.get('placement');
  const zoneParam = query.get('zone');
  const [, setZone] = useState(null);
  const [posts, setPosts] = useState([]);
  const [texts, setTexts] = useState(null);
  const [prompts, setPrompts] = useState([]);

  const type = useMemo(() => {
    switch (placementParam) {
      case PreviewTypes.Editorial:
        return PreviewTypes.Editorial;
      case PreviewTypes.Featured:
      default:
        return PreviewTypes.Featured;
    }
  }, [placementParam]);

  const loadZone = useCallback(async () => {
    const { data: zones } = await EditorialApi.getZones();
    const foundZone = zones.find(v => v.zone === zoneParam);
    if (!foundZone) {
      throw Error();
    }
    return {
      zone: foundZone,
    };
  }, [zoneParam]);

  const loadFeatured = useCallback(async () => {
    const postsOfFeatured = await Promise.all([
      ...FeaturedPlacements.map(v => {
        return EditorialApi.getEditorialPosts({
          zone: zoneParam,
          placement: v,
        })
      })
    ]);
    const data = await Promise.all([
      PostApi.getPostsOfRecrdById({
        postIds: postsOfFeatured[0].map(v => v.postId),
      }),
      PostApi.getPostsOfRecrdById({
        postIds: postsOfFeatured[1].map(v => v.postId),
      }),
      PostApi.getPostsOfRecrdById({
        postIds: postsOfFeatured[2].map(v => v.postId),
      }),
    ]);
    const posts = [
      data[0].data[0],
      data[1].data[0],
      data[2].data[0],
    ];
    const originalPostIds = posts.filter(post => post.origin_post_id?.length > 0)
      .map(post => post.origin_post_id);
    if (originalPostIds?.length > 0) {
      const { data: originalPosts } = await PostApi.getPostsOfRecrdById({
        postIds: originalPostIds,
      });
      posts.forEach(v => {
        v.origin = originalPosts.find(w => w.post_id === v.origin_post_id);
      });
    }
    return {
      posts,
    };
  }, [zoneParam]);

  const loadEditorial = useCallback(async () => {
    const postsOfEditorial = await Promise.all([
      ...EditorialPlacements.map(v => {
        return EditorialApi.getEditorialPosts({
          zone: zoneParam,
          placement: v,
        })
      })
    ]);
    const data = await Promise.all([
      PostApi.getPostsOfRecrdById({
        postIds: postsOfEditorial[0].map(v => v.postId),
      }),
      PostApi.getPostsOfRecrdById({
        postIds: postsOfEditorial[1].map(v => v.postId),
      }),
      PostApi.getPostsOfRecrdById({
        postIds: postsOfEditorial[2].map(v => v.postId),
      }),
      PostApi.getPostsOfRecrdById({
        postIds: postsOfEditorial[3].map(v => v.postId),
      }),
    ]);
    const posts = [
      data[0].data[0],
      data[1].data[0],
      data[2].data[0],
      data[3].data[0],
    ];
    const originalPostIds = posts.filter(post => post.origin_post_id?.length > 0)
      .map(post => post.origin_post_id);
    if (originalPostIds?.length > 0) {
      const { data: originalPosts } = await PostApi.getPostsOfRecrdById({
        postIds: originalPostIds,
      });
      posts.forEach(v => {
        v.origin = originalPosts.find(w => w.post_id === v.origin_post_id);
      });
    }
    return {
      posts,
    };
  }, [zoneParam]);

  const loadPrompts = useCallback(async () => {
    const { data: prompts } = await EditorialApi.getEditorialPrompts({
      zone: zoneParam,
    });
    return {
      prompts,
    };
  }, [zoneParam]);

  const loadTexts = useCallback(async () => {
    const { data: texts } = await EditorialApi.getEditorialTexts({
      zone: zoneParam,
      placement: placementParam,
    });
    return {
      texts,
    };
  }, [zoneParam, placementParam]);

  const load = useCallback(async () => {
    try {
      const { zone: newZone } = await loadZone(); 
      let newPosts = [];
      switch (type) {
        case PreviewTypes.Featured:
          newPosts = (await loadFeatured()).posts;
          break;
        case PreviewTypes.Editorial:
          newPosts = (await loadEditorial()).posts;
          break;
        default:
          throw Error();
      }
      const { prompts: newPrompts } = await loadPrompts();
      const { texts: newTexts } = await loadTexts();
      setZone(newZone);
      setPosts([
        newPosts[0],
        newPosts[1],
        newPosts[2],
        newPosts[3],
      ]);
      setTexts(newTexts);
      setPrompts(newPrompts);
    } catch (error) {
      console.error(error);
    }
  }, [
    type,
    loadZone,
    loadFeatured,
    loadEditorial,
    loadPrompts,
    loadTexts,
  ]);

  useEffect(() => {
    load();
  }, [load]);

  console.log(posts);

  return (
    <Page>
      <Navigation />
      <Content>
        <Container>
          <Preview 
            type={type}
            posts={posts}
            texts={texts}
            prompts={prompts}
          />
        </Container>
        <Container>
          <Explore />
        </Container>
      </Content>
    </Page>
  )
};