import React, { useCallback, useEffect, useState } from 'react';
import { Container } from "../../components/Container";
import { Layout } from '../../components/Layout';
import { Button } from '@mui/material';
import { Row } from '../../components/Row';
import { EditorialPlacements, FeaturedPlacements, Placements, TextColors } from '../../constants';
import { EditorialApi } from '../../api/editorial.api';
import { Column } from '../../components/Column';
import { Loader } from '../../components/Loader';
import { useParams } from 'react-router-dom';
import { Text } from '../../components/Text';
import { Prompts } from './Prompts';
import { Texts } from './Texts';
import { useUtility } from '../../utilities/hooks';
import { Box } from '../../components/Box';
import styled from '@emotion/styled';

/** @typedef {import('../../models/editorial-zone.model').EditorialZoneModel} EditorialZoneModel */

const PlacementRow = styled(Row)`
  justify-content: space-between;
  padding: 10px 10px;
  border-radius: 5px;
  transition: .3s all;
  width: calc(100% + 10px);
  margin: 0 0 0 -10px;
  cursor: pointer;
  &:hover {
    background-color: #202020;
  }
`;

export const EditorialContentFeatures = () => {
  const { zone: zoneParam } = useParams();
  /** @type {[EditorialZoneModel, (v: EditorialZoneModel) => void]} */
  const [zone, setZone] = useState(null);
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [featuredText, setFeaturedText] = useState(null);
  const [editorialText, setEditorialText] = useState(null);
  const { navigateClick } = useUtility();
  const [postCountsOfPlacements, setPostCountOfPlacements] = useState({});

  const [promptsProps, setPromptsProps] = useState({
    open: false,
    zone: null,
  });

  const [textsProps, setTextsProps] = useState({
    open: false,
    zone: null,
    data: null,
  });

  const loadPostCounts = useCallback(async () => {
    const recrds = {};
    Placements.forEach(async v => {
      try {
        const result = await EditorialApi.getEditorialPosts({
          placement: v,
          zone: zoneParam,
          page: 0,
          size: 100,
        });
        recrds[v] = result.length;
        setPostCountOfPlacements(prev => ({
          ...prev,
          ...recrds,
        }))
      } catch {
        recrds[v] = 0;
      }
    });
  }, [zoneParam]);

  const loadEditorialText = useCallback(async (zone) => {
    if (zone === null) {
      return;
    }
    try {
      const { data: editorialText } = await EditorialApi.getEditorialTexts({
        zone,
        placement: 'editorial_text',
      });
      setEditorialText(editorialText);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const loadFeaturedText = useCallback(async (zone) => {
    if (zone === null) {
      return;
    }
    try {
      const { data: featuredText } = await EditorialApi.getEditorialTexts({
        zone,
        placement: 'featured_text',
      });
      setFeaturedText(featuredText);
    } catch (error) {
      console.error(error);
    }
  }, [])

  const loadTexts = useCallback(async (zone) => {
    if (zone === null) {
      return;
    }
    loadEditorialText(zone);
    loadFeaturedText(zone);
  }, [loadEditorialText, loadFeaturedText]);

  const loadPrompts = useCallback(async (zone) => {
    if (zone === null) {
      return;
    }
    try {
      setLoading(true);
      const { data } = await EditorialApi.getEditorialPrompts({
        zone,
      });
      setPrompts(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const load = useCallback(async () => {
    try {
      setLoading(true);
      const { data: newZones } = await EditorialApi.getZones();
      const foundZone = newZones.find(v => v.zone === zoneParam);
      if (!foundZone) {
        throw Error();
      }
      setZone(foundZone);
      loadPostCounts();
      loadPrompts(foundZone.zone);
      loadTexts(foundZone.zone);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [zoneParam, loadPrompts, loadTexts, loadPostCounts]);

  useEffect(() => {
    load();
  }, [load]);

  const promptsClose = useCallback(() => {
    setPromptsProps(v => ({
      ...v,
      open: false,
    }))
  }, []);

  const promptsCreate = useCallback(async (newPrompt) => {
    try {
      await EditorialApi.createEditorialPrompt({
        zone: zone.zone,
        prompts: [...prompts, newPrompt],
      });
      loadPrompts(zone.zone);
    } catch (error) {
      console.error(error);
    }
  }, [zone, prompts, loadPrompts]);

  const textsClose = useCallback(() => {
    setTextsProps(v => ({
      ...v,
      open: false,
    }))
  }, []);

  const textsCreate = useCallback(async ({ placement, title, body }) => {
    try {
      await EditorialApi.createEditorialText({
        zone: zone.zone,
        placement,
        title,
        body,
      });
      loadTexts(zone.zone);
    } catch (error) {
      console.error(error);
    } finally {
      setTextsProps((prev) => ({
        ...prev,
        open: false,
      }))
    }
  }, [zone, loadTexts]);

  const postsClick = useCallback(
    (placement) => navigateClick(`/editorial-content/posts/${zone?.zone}/${placement}`, { label: 'Editorial Posts' }), 
    [navigateClick, zone]
  );

  const zonePreviewClick = useCallback(
    (placement) => {
      const params = new URLSearchParams({
        placement,
        zone: zone?.zone,
      })
      return () => {
        window.open(`/editorial-content/preview?${params}`);
      }
    }, [zone],
  );

  const promptsClick = useCallback(
    /** 
     * @param {string} placement
     */
    (placement) => {
      const { zone: selectedZone } = zone;
      setPromptsProps(prev => ({
        ...prev,
        open: true,
        placement,
        zone: selectedZone,
      }))
    }, [zone],
  );

  const textsClick = useCallback(
    /** 
     * @param {string} placement
     */
    (placement) => () => {
      const { zone: selectedZone } = zone;
      setTextsProps(prev => ({
        ...prev,
        open: true,
        placement,
        zone: selectedZone,
        data: placement === 'featured_text'
          ? featuredText
          : editorialText
      }));
    }, [zone, featuredText, editorialText],
  );

  const getPostCount = useCallback((placement) => {
    const result = postCountsOfPlacements[placement] ?? 0;
    return result;
  }, [postCountsOfPlacements]);

  return (
    <Layout
      breadcrumbs={[
        {
          link: '/',
          label: 'Home',
        },
        {
          label: 'Editorial Content',
          link: '/editorial-content',
        },
        {
          label: 'Editorial Feature',
          link: `/editorial-content/features/${zone}`,
        }
      ]}
    >
      <Container>
        <Row padding="0 0 20px 0" justifyContent="space-between">
          <Row gap="5px">
            <Text color={TextColors.Label}>Zone: </Text>
            <Text color={TextColors.ID}>{zone?.zone}</Text>
          </Row>
        </Row>
        <Container>
          <Column width="100px">
            <Button
              variant="outlined"
              color="info"
              onClick={zonePreviewClick('featured_text')}
            >
              Preview
            </Button>
          </Column>
          <Row>
            <Column justifyContent="center">
              <Text color={TextColors.Label}>Featured Text:</Text>
            </Column>
            <Column margin="0 0 0 25px">
              <Box gap="5px">
                <Text color={TextColors.Label}>Title:</Text>
                <Text color={TextColors.Title}>{featuredText?.title}</Text>
              </Box>
              <Box gap="5px">
                <Text color={TextColors.Label}>Body:</Text>
                <Text color={TextColors.Message}>{featuredText?.body}</Text>
              </Box>
            </Column>
            <Column flex="1" alignItems="flex-end" justifyContent="center">
              <Button
                variant="outlined"
                color="warning"
                onClick={textsClick('featured_text')}
              >
                Edit text
              </Button>
            </Column>
          </Row>
          <Column gap="10px">
            {FeaturedPlacements.map((placement, index) => (
              <PlacementRow 
                key={placement + index}
                onClick={postsClick(placement)}
              >
                <Text color={TextColors.ID}>
                  {placement}
                </Text>
                <Text color={TextColors.Numbers}>
                  {getPostCount(placement)}
                </Text>
              </PlacementRow>
            ))}
          </Column>
          <Column width="100px" margin="30px 0 0 0">
            <Button
              variant="outlined"
              color="info"
              onClick={zonePreviewClick('editorial_text')}
            >
              Preview
            </Button>
          </Column>
          <Row>
            <Column justifyContent="center">
              <Text color={TextColors.Label}>Editorial Text:</Text>
            </Column>
            <Column margin="0 0 0 25px">
              <Box gap="5px">
                <Text color={TextColors.Label}>Title:</Text>
                <Text color={TextColors.Title}>{editorialText?.title}</Text>
              </Box>
              <Box gap="5px">
                <Text color={TextColors.Label}>Body:</Text>
                <Text color={TextColors.Message}>{editorialText?.body}</Text>
              </Box>
            </Column>
            <Column flex="1" alignItems="flex-end" justifyContent="center">
              <Button
                variant="outlined"
                color="warning"
                onClick={textsClick('editorial_text')}
              >
                Edit text
              </Button>
            </Column>
          </Row>
          <Column gap="10px">
            {EditorialPlacements.map((placement, index) => (
              <PlacementRow 
                key={placement + index}
                onClick={postsClick(placement)}
              >
                <Text color={TextColors.ID}>
                  {placement}
                </Text>
                <Text>
                  {getPostCount(placement)}
                </Text>
              </PlacementRow>
            ))}
          </Column>
          <Row margin="30px 0 30px 0">
            <Column>
              <Text color={TextColors.Label}>Prompts:</Text>
            </Column>
            <Row justifyContent="flex-end">
              <Button
                variant="outlined"
                color="info"
                onClick={promptsClick}
              >
                Prompt List
              </Button>
            </Row>
          </Row>
        </Container>
        <Prompts
          open={promptsProps.open}
          zone={promptsProps.zone}
          prompts={prompts}
          onClose={promptsClose}
          onCreate={promptsCreate}
        />
        <Texts
          open={textsProps.open}
          placement={textsProps.placement}
          zone={textsProps.zone}
          data={textsProps.data}
          onClose={textsClose}
          onCreate={textsCreate}
        />
        <Loader loading={loading} />
      </Container>
    </Layout>
  )
};