import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormTypes, TextColors } from '../../constants';
import { useFormikContext } from 'formik';
import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, TextField, CircularProgress } from '@mui/material';
import { Text } from '../Text';
import { Box } from '../Box';
import { Container } from '../Container';
import styled from '@emotion/styled';
import { DialogOfRoles } from '../DialogOfRoles';
import { Accesses } from '../../constants/access';
import { UserApi } from '../../api/user.api';
import { uniqBy } from 'lodash';

const Dialog = styled(MuiDialog)`
  & .MuiDialog-paper {
    max-width: 100vw;
  }
`;

const Autocomplete = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: ${({ open }) => open ? 'flex' : 'none'};
  flex-direction: column;
  z-index: 1500;
  width: 150px;
  height: 300px;
  overflow: hidden auto;
  background-color: #000;
  gap: 5px;
  border-radius: 4px;

  &::-webkit-scrollbar {
    background-color: #111;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #444;
  }
`;

const AutocompleteItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 10px;
  background-color: #222;
  border-radius: 4px;
  cursor: pointer;
`;

const AutocompleteLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;

export const PrivilegeForm = ({
  access = Accesses.Internal,
  formType,
  show,
  onSubmit,
  onCancel,
}) => {
  const {
    values,
    errors,
    touched,
    isValid,
    setValues,
    handleChange,
    handleBlur,
  } = useFormikContext();
  /** @type {import('react').MutableRefObject<HTMLDivElement>} */
  const emailRef = useRef();
  /** @type {import('react').MutableRefObject<HTMLDivElement>} */
  const autocompleteRef = useRef();
  const timerRef = useRef();
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [autocompleteItems, setAutocompleteItems] = useState([]);

  const setPositionForAutocomplete = useCallback(() => {
    const rect = emailRef.current?.getBoundingClientRect();
    if (!rect) return;
    autocompleteRef.current.style.left = rect.left - 25 + 'px';
    autocompleteRef.current.style.top = rect.top + 65 + 'px';
    autocompleteRef.current.style.width = rect.width + 50 + 'px';
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setPositionForAutocomplete);
    return () => {
      window.removeEventListener('resize', setPositionForAutocomplete);
      setAutocompleteOpen(false);
    }
  }, [show, setPositionForAutocomplete])

  const loadUsers = useCallback(async (value) => {
    try {
      setAutocompleteLoading(true);
      const [
        { users: usersById }, 
        { users: usersByName }, 
        { users: usersByEmail },
      ] = await Promise.all([
        UserApi.getUsers({
          userId: value,
          limit: 10,
        }),
        UserApi.getUsers({
          userName: value,
          limit: 10,
        }),
        UserApi.getUsers({
          email: access === Accesses.Internal ? value + '@recrd.com' : value,
          limit: 10,
        }),
      ]);
      const newAutocompleteItems = uniqBy(
        [...usersById, ...usersByName, ...usersByEmail], 
        (value) => value.user_id,
      );
      if (access === Accesses.External) {
        const newExternalAutocompleteItems = newAutocompleteItems
          .filter((value) => !value.email_address?.includes('@recrd.com'));
        setAutocompleteItems(newExternalAutocompleteItems);
      }
      if (access === Accesses.Internal) {
        const newInternalAutocompleteItems = newAutocompleteItems
          .filter((value) => value.email_address?.includes('@recrd.com'));
        setAutocompleteItems(newInternalAutocompleteItems);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setAutocompleteLoading(false);
    }
  }, [access]);

  useEffect(() => {
    if (autocompleteValue.length > 0) {
      loadUsers(autocompleteValue);
    }
  }, [autocompleteValue, loadUsers]);

  const emailHintClick = useCallback(() => {
    emailRef.current.focus();
  }, []);
  const [dialogOfRolesOpen, setDialogOfRolesOpen] = useState(false);

  const attributesClick = useCallback(() => {
    setDialogOfRolesOpen(true)
  }, []);

  const dialogOfRolesClose = useCallback(() => {
    setDialogOfRolesOpen(false)
  }, []);

  const dialogOfRolesSubmit = useCallback((newRoles) => {
    const newValues = {
      ...values,
      attributes: [...newRoles],
    }
    setValues(newValues);
  }, [values, setValues]);

  const textFieldChange = useCallback((event) => {
    const newValue = event.target.value;
    handleChange(event);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setAutocompleteValue(newValue);
    }, 300);
  }, [handleChange]);

  const textFieldFocus = useCallback(() => {
    setPositionForAutocomplete();
    setAutocompleteOpen(true);
  }, [setPositionForAutocomplete]);

  const textFieldBlur = useCallback((event) => {
    handleBlur(event);
  }, [handleBlur]);

  const textFieldKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      emailRef.current.blur();
      setAutocompleteOpen(false);
    }
  }, []);

  const autocompleteItemClick = useCallback((item) => () => {
    const index = item.email_address.indexOf('@');
      if (index === -1) {
        return;
      }
    setAutocompleteOpen(false);
    if (access === Accesses.Internal) {
      const email = item.email_address.slice(0, index);
      setValues({
        ...values,
        email,
      });
    }
    if (access === Accesses.External) {
      setValues({
        ...values,
        email: item.email_address,
      });
    }
  }, [values, access, setValues]);

  return (
    <>
      <Dialog
        open={show}
        onClose={onCancel}
      >
        <DialogTitle>
          <Text>
            {formType === FormTypes.Create && <>Create</>}
            {formType === FormTypes.Update && <>Edit</>}
          </Text>
        </DialogTitle>
        <DialogContent>
          <Box flexDirection="column" width="320px" padding="10px 0" gap="10px">
            <Box position="relative">
              <TextField
                name="email"
                label="Email"
                fullWidth
                autoComplete="off"
                disabled={formType === FormTypes.Update}
                value={values.email}
                onChange={textFieldChange}
                onKeyDown={textFieldKeyDown}
                onFocus={textFieldFocus}
                onBlur={textFieldBlur}
                inputRef={emailRef}
                InputLabelProps={{ shrink: true }}
                error={touched.email && errors.email}
                helperText={touched.email && errors.email}
              />
              {access === Accesses.Internal && (
                <Box 
                  position="absolute" 
                  top="15px"
                  left={14 + values.email.length * 8.8 + `px`} 
                  onClick={emailHintClick}
                >
                  <Text color="rgb(158, 158, 158)" size="16px">
                    @recrd.com
                  </Text>
                </Box>
              )}
            </Box>
            <Container>
              <Button
                fullWidth
                variant="outlined"
                color={errors.attributes ? 'error' : 'success'}
                onClick={attributesClick}
              >
                Attributes
              </Button>
              {errors.attributes && (
                <Text color={TextColors.Error} size="12px">
                  {errors.attributes}
                </Text>
              )}
              <DialogOfRoles
                access={access}
                title="Attributes"
                open={dialogOfRolesOpen}
                value={values.attributes}
                onSubmit={dialogOfRolesSubmit}
                onClose={dialogOfRolesClose}
              />
            </Container>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box gap="10px">
            <Box>
              <Button
                variant="contained"
                color="error"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="info"
                disabled={!isValid}
                onClick={onSubmit}
              >
                {formType === FormTypes.Create && <>Create</>}
                {formType === FormTypes.Update && <>Update</>}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <Autocomplete 
        ref={autocompleteRef}
        open={autocompleteOpen}
      >
        {autocompleteLoading && (
          <AutocompleteLoading>
            <CircularProgress />
          </AutocompleteLoading>
        )}
        {!autocompleteLoading && autocompleteItems.map((value, index) => (
          <AutocompleteItem key={index} onClick={autocompleteItemClick(value)}>
            <Text color={TextColors.Primary}>
              {value.user_id}
            </Text>
            <Text color={TextColors.Primary}>
              {value.user_name}
            </Text>
            <Text color={TextColors.Primary}>
              {value.email_address}
            </Text>
            <Text color={TextColors.Primary}>
              {value.phone_number}
            </Text>
          </AutocompleteItem>
        ))}
      </Autocomplete>
    </>
  )
}