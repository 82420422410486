import { Button, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Column } from '../../../components/Column';
import { Row } from '../../../components/Row';
import { Box } from '../../../components/Box';
import CloseIcon from '@mui/icons-material/Close';

/** @typedef {import('../../../models/editorial-text.model').EditorialTextModel} EditorialTextModel */

/**
 * 
 * @param {object} props
 * @param {boolean} props.open
 * @param {string} props.zone
 * @param {string} props.placement
 * @param {() => void} props.onClose 
 * @returns 
 */
export const Texts = ({
  open,
  zone,
  placement,
  data,
  onClose,
  onCreate,
}) => {
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    setValues,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues: {
      title: '',
      body: '',
    },
    validationSchema: yup.object().shape({
      title: yup.string()
        .required(),
      body: yup.string()
        .required(),
    })
  });

  useEffect(() => {
    if (open) {
      setValues({
        title: data?.title ?? '',
        body: data?.body ?? '',
      })
    }
  }, [open, data, setValues])

  const createTextClick = useCallback(async () => {
    if (!isValid) {
      return;
    }
    const { title, body  } = values;
    onCreate({ placement, title, body });
  }, [values, isValid, placement, onCreate]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Row justifyContent="space-between">
          <Box>
            Texts: {placement} - {zone}
          </Box>
          <Box>
            <CloseIcon onClick={onClose} />
          </Box>
        </Row>
      </DialogTitle>
      <DialogContent>
        <Column width="100%" gap="10px">
          <Row gap="10px" alignItems="center">
            <Column width="100%" flex="1" gap="10px">
              <Row>
                <TextField 
                  fullWidth
                  error={touched.title && errors.title}
                  helperText={touched.title && errors.title}
                  label="Title"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Row>
              <Row>
                <TextField 
                  fullWidth
                  error={touched.body && errors.body}
                  helperText={touched.body && errors.body}
                  label="Body"
                  name="body"
                  value={values.body}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Row>
            </Column>
            <Box position="relative">
              <Button
                variant="contained"
                color="success"
                disabled={!dirty || !isValid}
                onClick={createTextClick}
              >
                Update
              </Button>
            </Box>
          </Row>
        </Column>
      </DialogContent>
    </Dialog>
  )
}