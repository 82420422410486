import uuidTime from 'uuid-time';
import { SaleStatuses, TextLabels, confirmDialogHeaderText } from '../constants';
import { PostStatusKeys, PostStatusLabels } from '../constants/post-statuses';
import { ManuallyReportedPosts } from '../constants/roles';
import { format } from 'date-fns';

export const getPromiseWithResolvers = () => {
  let resolve, reject;
  const promise = new Promise((resolveFunc, rejectFunc) => {
    resolve = resolveFunc;
    reject = rejectFunc;
  });
  return {
    promise,
    resolve,
    reject,
  };
}

/** @param {URLSearchParams} params */
export const urlSearchParamSetter = (params) => {
  return (key, value) => {
    if (value === undefined) {
      return;
    }
    if (Array.isArray(value)) {
      value.forEach((v, i) => params.append(`${key}[${i}]`, v));
      return;
    }
    params.set(key, value);
  }
};

/** @param {Date?} date */
export const getFormattedTextByDate = date => {
  if (isNaN(date?.getTime())) {
    return TextLabels.Empty;
  }
  return format(date, 'dd MMM yyyy - HH:mm:ss');
}

/** @param {string?} iso */
export const getFormattedTextByIsoString = iso => {
  const date = new Date(iso);
  return getFormattedTextByDate(date);
}

export const uuidToISOStringOfDate = v => {
  if (v == null) {
    return null;
  }
  const time = uuidTime.v1(v);
  if (!time || isNaN(time)) {
    return null;
  }
  const date = new Date(time);
  if (isNaN(date.getTime())) {
    return null;
  }
  return date.toISOString();
};

export const getConfirmDialogHeader = () => confirmDialogHeaderText;

export const getConfirmDialogContent = entity => `This action is not reversible. All the data related to ${entity} will be deleted.`;

export const getFormattedTextByStatusNumber = status => {
  if (!status) {
    return TextLabels.Empty;
  }
  const item = ManuallyReportedPosts.find(v => v.value === status);
  if (!item) {
    return `Unknown (${status})`;
  }
  return `${item.label} (${item.value})`;
}

export const getReportReason = reason => {
  const value = +reason;
  if (isNaN(value)) {
    return '<No reason>';
  }
  const item = ManuallyReportedPosts.find(v => v.value === value);
  const label = item ? `(${item.label})` : '<Unknown>';
  return `${label} (${reason})`;
}

export const getSaleStatus = saleStatus => {
  switch (saleStatus) {
    case SaleStatuses.ForSale:
      return 'FOR SALE + $PRICE';
    case SaleStatuses.Suspended:
      return 'SALE SUSPENDED';
    case SaleStatuses.Blocked:
      return 'SALE BLOCKED';
    case SaleStatuses.Removed:
      return 'SALE REMOVED';
    case SaleStatuses.Sold:
      return 'SOLD + $PRICE';
    case SaleStatuses.Pending:
      return 'SALE PENDING';
    default:
      return null;
  }
}

/** @returns {string} */
export const getPostStatusLabel = (postStatus, detailed = false) => {
  const result = PostStatusLabels[postStatus] ?? TextLabels.Empty;
  if (!detailed) {
    return result.replace(/(\(.*\))*/gi, '');
  }
  return result;
}

/** @returns {"success" | "primary" | "error" | "warning" | "info" | "secondary" | "default"} */
export const getChipColorOfPostStatus = postStatus => {
  switch (postStatus) {
    case 'ACTIVE': return 'success';
    case PostStatusKeys.C: return 'success';
    case PostStatusKeys.P: return 'primary';
    case PostStatusKeys.V: return 'error';
    case PostStatusKeys.T: return 'error';
    case PostStatusKeys.H: return 'error';
    case PostStatusKeys.A: return 'success';
    case PostStatusKeys.D: return 'warning'
    case PostStatusKeys.S: return 'info';
    case PostStatusKeys.F: return 'secondary';
    default: return 'default';
  }
}