import { Typography } from '@mui/material';
import React from 'react';
import { Box } from '../../../../components/Box';
import { Container } from '../../../../components/Container';
import { Row } from '../../../../components/Row';

/** @typedef {import('../../../../models/reaction.model').ReactionModel} ReactionModel */

/**
 * @param {object} props
 * @param {ReactionModel[]} props.reactions
 */
export const Reactions = ({
  reaction,
}) => {
  const reactions = reaction?.reactions ?? [];

  return (
    <Container>
      {reactions.length === 0 && (
        <Row>
          <Typography>No reactions</Typography>
        </Row>
      )}
      {reactions.length > 0 && reactions.map((reaction, index) => {
        return (
          <Row key={index} width="100%" gap="10px">
            <Box>
              {reaction.user?.user_name ?? 'Unknown'}:
            </Box>
            <Box>
              {reaction.type.emoji}
            </Box>
          </Row>
        );
      })}
    </Container>
  )
};