import React from 'react';
import { Container } from "../Container";
import { Text } from "../Text";
import { Layout } from '../Layout';

export const NoAccess = () => {
  return (
    <Layout>
      <Container>
        <Text>
          You don't have access.
        </Text>
      </Container>
    </Layout>
  )
};