import React, { useCallback, useMemo } from 'react';
import { Row } from '../../../components/Row';
import { Column } from '../../../components/Column';
import { Avatar } from '../../../components/Avatar';
import { Box } from '../../../components/Box';
import ContentCopy from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { TextColors, TextLabels } from '../../../constants';
import { useUtility } from '../../../utilities/hooks';
import { Text } from '../../../components/Text';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { hasFeatures } from '../../../store/auth';

/**
 * @typedef {object} PartnerCardProps
 * @property {object} partner
 * @property {(partner) => void} onEditClick
 */

/**
 * @param {PartnerCardProps} props 
 * @returns 
 */
export const PartnerCard = ({
  partner,
  onEditClick,
}) => {
  const {
    copyClick,
    navigateClick,
  } = useUtility();

  const canUpdate = useSelector(hasFeatures(['update-partners']));

  const user = partner?.user_details;

  const editClick = useCallback(() => {
    onEditClick(partner);
  }, [partner, onEditClick]);

  const url= useMemo(() => {
    if (!partner) {
      return '';
    }
    const url = process.env.REACT_APP_RECRD_WEB_URL;
    if (!url) {
      return '';
    }
    const { code } = partner;
    return `${url}?code=${code}`;
  }, [partner]);

  return (
    <Row key={partner?.code} gap="10px">
      <Column gap="10px">
        <Avatar
          image={user?.image_url} 
          userName={user?.user_name ?? TextLabels.Empty} 
        />
      </Column>
      <Column flex="1">
        <Column>
          <Box gap="10px">
            <Text color={TextColors.Label}>
              User Name:
            </Text>
            <Text color={TextColors.Name}>
              {user?.user_name ?? TextLabels.Empty}
            </Text>
            <OpenInNewIcon 
              onClick={navigateClick(`/users/${partner.user_id}`, { label: 'User' })}
            />
          </Box>
        </Column>
        <Column>
          <Box gap="10px">
            <Text color={TextColors.Label}>
              User ID:
            </Text>
            <Text color={TextColors.ID}>
              {partner.user_id ?? TextLabels.Empty}
            </Text>
            <ContentCopy 
              onClick={copyClick(
                partner.user_id ?? 'Unknown', 
                'the user ID has just been copied',
              )}
            />
          </Box>
        </Column>
      </Column>
      <Column gap="5px" flex="1">
        <Column>
          <Box gap="10px" alignItems="center">
            <Text color={TextColors.Url}>
              {url}
            </Text>
            <ContentCopy
              onClick={copyClick(
                url, 
                url,
              )}
            />
            {canUpdate && (
              <Button
                variant="outlined"
                color="info"
                onClick={editClick}
              >
                Change
              </Button>
            )}
          </Box>
        </Column>
        <Column>
          <Box gap="10px" alignItems="center">
            <Text color={TextColors.Label}>User Count:</Text>
            <Text color={TextColors.Choice}>{partner.users_count ?? 0}</Text>
            <Button
              variant="outlined"
              color="success"
              onClick={navigateClick(`/partners/${partner.code}`, { label: 'Partner Details' })}
            >
              Open
            </Button>
          </Box>
        </Column>
      </Column>
    </Row>
  )
};