import styled from "@emotion/styled";
import { TextColors, TextFontSizes } from "../../constants";

export const Text = styled.span`
  display: ${({ display }) => display ?? 'inline'};
  color: ${({ color }) => color ?? TextColors.Primary};
  font-size: ${({ size }) => size ?? TextFontSizes.Normal};
  font-weight: ${({ fontWeight }) => fontWeight ?? 'normal'};
  word-wrap: ${({ wordWrap }) => wordWrap ?? 'normal'};
  width: ${({ width }) => width ?? 'auto'};
  height: ${({ height }) => height ?? 'auto'};
`;