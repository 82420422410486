import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import hls from 'hls.js';
import { Box } from '../../../components/Box';
import { Text } from '../../../components/Text';
import { Button } from '@mui/material';

const Container = styled(Box)`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Video = styled.video`
  display: flex;
  width: 750px;
  height: 415px;
`;

const Image = styled.img`
  display: flex;
  width: 750px;
  height: 415px;
  object-fit: contain;
`;

/**
 * @param {object} props 
 * @param {import('../../../models/post.model').PostModel} props.post
 * @returns 
 */
export const Header = ({
  post,
  onCampaignLinkClick,
  onRerenderVideoClick,
}) => {
  /** @type {import('react').MutableRefObject<HTMLVideoElement>} */
  const videoRef = useRef(null);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState({
    status: 200,
    message: '',
  })

  useEffect(() => {
    setError(false);
  }, [post])

  useEffect(() => {
    if (!hls.isSupported()) return;
    try {
      const player = videoRef.current;
      const instance = new hls();
      instance.loadSource(post.media_name);
      instance.attachMedia(player);
      instance.on(hls.Events.ERROR, (_, data) => {
        setErrorData(state => ({
          ...state,
          status: data?.response?.code ?? 200,
          message: data?.error?.message ?? '',
        }))
        setError(true);
      })
    } catch (error) {
      
    }
  }, [post]);

  return (
    <Container>
      {!error && (
        <Video ref={videoRef} controls preload="auto" onError={() => alert(1)} />
      )}
      {error && (
        <Box flexDirection="column">
          <Image src={post.rebound_image} />
          <Box width="100%" justifyContent="center">
            <Text color="#c7c7c7">
              Http Status: {errorData.status}; Error Message: {errorData.message}{errorData.code}
            </Text>
          </Box>
        </Box>
      )}
      <Box position="absolute" flexDirection="column" right="0px" top="0px" gap="5px">
        <Button
          variant="contained"
          color="info"
          onClick={onCampaignLinkClick}
        >
          Campaign Link
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onRerenderVideoClick}
        >
          Rerender Video
        </Button>
      </Box>
    </Container>
  )
};