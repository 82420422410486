
export const GeneralRoles = [
  {
    label: 'Super Admin (all)',
    value: 'Sa',
  },
  {
    label: 'Marketing team',
    value: 'Ma',
  },
  {
    label: 'QA team',
    value: 'Qa',
  },
  {
    label: 'Developer team',
    value: 'De',
  },
  {
    label: 'Moderator team',
    value: 'Mo',
  },
  {
    label: 'Advertiser team',
    value: 'Ad',
  },
  {
    label: 'DevOps team',
    value: 'Do',
  },
];

export const ManuallyReportedPosts = [
  {
    label: 'CopyrightClaim',
    value: 1001,
  },
  {
    label: 'FakeNews',
    value: 1101,
  },
  {
    label: 'Nudity',
    value: 1201,
  },
  {
    label: 'HateSpeech',
    value: 1301,
  },
  {
    label: 'Spam',
    value: 1401,
  },
  {
    label: 'SellingIllegalGoods',
    value: 1501,
  },
  {
    label: 'BullyingHarassment',
    value: 1601,
  },
  {
    label: 'SelfHarmSuicideThreat',
    value: 1701,
  },
  {
    label: 'IDontWantToSeeThis',
    value: 1801,
  },
  {
    label: 'AnimalCruelty',
    value: 1901,
  },
  {
    label: 'ChildAbuse',
    value: 2001,
  },
];

export const AmberAutomatedReportedPosts = [
  {
    label: 'a_little_bloody',
    value: 3001,
  },
  {
    label: 'animal_genitalia_only',
    value: 3002,
  },
  {
    label: 'animated_corpse',
    value: 3003,
  },
  {
    label: 'animated_gun',
    value: 3004,
  },
  {
    label: 'general_suggestive',
    value: 3005,
  },
  {
    label: 'gun_in_hand',
    value: 3006,
  },
  {
    label: 'knife_in_hand',
    value: 3007,
  },
  {
    label: 'noose',
    value: 3008,
  },
  {
    label: 'other_blood',
    value: 3009,
  },
  {
    label: 'very_bloody',
    value: 3010,
  },
  {
    label: 'yes_confederate',
    value: 3011,
  },
  {
    label: 'yes_gambling',
    value: 3012,
  },
  {
    label: 'yes_middle_finger',
    value: 3013,
  },
  {
    label: 'yes_self_harm',
    value: 3014,
  },
  {
    label: 'yes_sex_toy',
    value: 3015,
  },
  {
    label: 'yes_sexual_intent',
    value: 3016,
  },
  {
    label: 'yes_undressed',
    value: 3017,
  },
];

export const RedAutomatedReportedPosts = [
  {
    label: 'animal_genitalia_and_human',
    value: 4001,
  },
  {
    label: 'animated_animal_genitalia',
    value: 4002,
  },
  {
    label: 'general_nsfw',
    value: 4003,
  },
  {
    label: 'hanging',
    value: 4004,
  },
  {
    label: 'illicit_injectables',
    value: 4004,
  },
  {
    label: 'yes_female_nudity',
    value: 4005,
  },
  {
    label: 'yes_kkk',
    value: 4006,
  },
  {
    label: 'yes_male_nudity',
    value: 4007,
  },
  {
    label: 'yes_nazi',
    value: 4008,
  },
  {
    label: 'yes_realistic_nsfw',
    value: 4009,
  },
  {
    label: 'yes_sexual_activity',
    value: 4010,
  },
  {
    label: 'yes_terrorist',
    value: 4011,
  },
]