import { SnackbarTypes } from "../../constants";

const initialState = {
  layout: {
    open: true,
    once: false,
    collapses: {
      reports: false,
    },
  },
  snackbar: {
    open: false,
    type: 'info',
    content: '',
    autoHideDuration: 3000,
    anchorOrigin: {
      horizontal: 'right',
      vertical: 'top',
    }
  },
  dialog: {
    open: false,
    children: null,
  },
  confirmDialog: {
    open: false,
    header: '',
    content: '',
    result: null,
    data: null,
  },
  breadcrumbs: [],
};

export const uiKey = 'ui';

export const SET_LAYOUT = `${uiKey}/SET_LAYOUT`;
export const SET_SNACKBAR = `${uiKey}/SET_SNACKBAR`;
export const SET_DIALOG = `${uiKey}/SET_DIALOG`;
export const SET_CONFIRM_DIALOG = `${uiKey}/SET_CONFIRM_DIALOG`;
export const SET_BREADCRUMBS = `${uiKey}/SET_BREADCRUMBS`;
export const ADD_BREADCRUMB = `${uiKey}/ADD_BREADCRUMB`;

export const setLayout = payload => ({
  type: SET_LAYOUT,
  payload,
});

/** 
 * @typedef {object} SnackbarPayload 
 * @property {boolean} open
 * @property {string} type - SnackbarTypes.Info
 * @property {string} content
*/

/**
 * @param {SnackbarPayload} payload 
 */
export const setSnackbar = payload => ({
  type: SET_SNACKBAR,
  payload,
});

export const setDialog = payload => ({
  type: SET_DIALOG,
  payload,
});

export const setBreadcrumbs = payload => ({
  type: SET_BREADCRUMBS,
  payload,
});

export const addBreadcrumb = payload => ({
  type: ADD_BREADCRUMB,
  payload,
});

/**
 * 
 * @typedef {object} ConfirmDialogPayload
 * @property {boolean} open
 * @property {string} header
 * @property {string} content
 * @property {boolean|null} result
 * @property {object|null} data
 */

/**
 * @param {ConfirmDialogPayload} payload 
 */
export const setConfirmDialog = payload => ({
  type: SET_CONFIRM_DIALOG,
  payload,
})

export const uiReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LAYOUT:
      return {
        ...state,
        layout: {
          ...state.layout,
          ...payload,
          collapses: {
            ...state.layout.collapses,
            ...payload.collapses,
          },
        },
      };
    case SET_SNACKBAR: 
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          ...payload,
        }
      };
    case SET_DIALOG: 
      return {
        ...state,
        dialog: {
          ...state.dialog,
          ...payload,
          type: payload.type ?? SnackbarTypes.Info,
        },
      };
    case SET_CONFIRM_DIALOG: 
      return {
        ...state,
        confirmDialog: {
          ...state.confirmDialog,
          ...payload,
        },
      };
    case SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: payload,
      };
    case ADD_BREADCRUMB:
      return {
        ...state,
        breadcrumbs: [
          ...state.breadcrumbs,
          payload,
        ],
      };
    default:
      return state;
  };
};

export const getUi = state => state[uiKey];

export const getLayout = state => getUi(state).layout;

export const getSnackbar = state => getUi(state).snackbar;

export const getDialog = state => getUi(state).dialog;

export const getConfirmDialog = state => getUi(state).confirmDialog;

export const getBreadcrumbs = state => getUi(state).breadcrumbs;