import React from "react";
import styled from "@emotion/styled";
import { Icon, Image, Text } from "../../shared";
import { CardTypes } from "../../constants";

const View = styled.div`
  display: flex;
  flex-direction: column;
`;

const ViewPanel = styled.div`
  position: relative;
  display: flex;
  width: ${({ type }) => {
    switch (type) {
      case CardTypes.Large: return '392.5px';
      case CardTypes.Small: return '364px';
      case CardTypes.Default: default: return '364px';
    }
  }};
  height: ${({ type }) => {
    switch (type) {
      case CardTypes.Large: return '604.61px';
      case CardTypes.Small: return '272.5px';
      case CardTypes.Default: default: return '323px';
    }
  }};
`;

const VideoImage = styled.img`
  position: absolute;
  left: 0px;
  top: 0px;
  display: inline-flex;
  width: 100%;
  height: 100%;
  border-radius: 9.4px;
  object-fit: cover;
`;

const ProfileContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  display: flex;
  padding: 16.72px 0 0 15.5px;
`;

const Profile = styled.div`
  display: flex;
  gap: 8px;
`;

const ProfileLeft = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
`;

const ProfileRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ProfileImage = styled.img`
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #000000;
  object-fit: cover;
  z-index: 1;
`;

const ProfileIcon = styled.img`
  position: relative;
  display: inline-flex;
  width: 14.31px;
  height: 14.31px;
  z-index: 2;
`;

const ProfileTitle = styled.div`
  display: flex;
`;

const ProfileType = styled.div`
  display: flex;
  opacity: 0.5;
`;

const InfoContainer = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px; 
  display: flex;
  width: 100%;
  padding: 0 0 16.76px 16px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 280px;
`;

const InfoTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const InfoDescription = styled.div`
  display: flex;
  margin: 12px 0 0 0;
`;

const InfoHashtags = styled.div`
  display: flex;
  margin: 8px 0 0 0;
  gap: 6px;
`;

const InfoHashtag = styled.div`
  display: inline-flex;
  opacity: 0.5;
`;

const ViewUser = styled.div`
  display: flex;
  margin: 6.59px 0 0 0;
`;

const User = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10.62px;
`;

const UserImage = styled.div`
  display: flex;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const UserName = styled.div`
  display: flex;
`;

const UserReply = styled.div`
  display: flex;
  gap: 4px;
`;

export const Card = ({
  type = CardTypes.Default,
  postImageUrl,
  postDescription,
  postHashtags,
  userName,
  userImage,
  repliedUserName,
  repliedUserImage,
  repliedPostTitle,
}) => (
  <View>
    <ViewPanel type={type}>
      <VideoImage 
        src={postImageUrl} 
      />
      <ProfileContainer>
        {repliedUserName && (
          <Profile>
            <ProfileLeft>
              <ProfileIcon src="/icons/preview-union.svg" />
              <ProfileImage src={repliedUserImage ?? "/images/preview-thump-profile.png"} />
            </ProfileLeft>
            <ProfileRight>
              <ProfileType>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="14px"
                  color="#FFFFFF"
                >
                  Original Video
                </Text>
              </ProfileType>
              <ProfileTitle>
                <Text
                  fontWeight="600"
                  fontSize="14px"
                  lineHeight="18.2px"
                >
                  {repliedPostTitle ?? 'Holocene, PT II from...'}
                </Text>
              </ProfileTitle>
            </ProfileRight>
          </Profile>
        )}
      </ProfileContainer>
      <InfoContainer>
        <Info>
          {repliedUserName && (
            <InfoTitle>
              <Icon
                src="/icons/preview-camera.svg"
                width="16.79px"
                height="10.89px"
              />
              <Text
                fontWeight="500"
                fontSize="12px"
                lineHeight="12px"
              >
                Rebound
              </Text>
            </InfoTitle>
          )}
          <InfoDescription>
            <Text
              fontWeight="600"
              lineHeight="18.2px"
            >
              {postDescription ?? `
                I can’t believe I’ve met a couple of singers in the cutest café!! 😱
              `}
            </Text>
          </InfoDescription>
          <InfoHashtags>
            {postHashtags.map((v, i) => (
              <InfoHashtag key={i}>
                <Text
                  fontWeight="500"
                  fontSize="12px"
                  lineHeight="14.4px"
                >
                  #{v}
                </Text>
              </InfoHashtag>
            ))}
          </InfoHashtags>
        </Info>
      </InfoContainer>
    </ViewPanel>
    <ViewUser>
      <User>
        <UserImage>
          <Image
            src={userImage ?? "/images/preview-user.png"}
            width="40px"
            height="40px"
            borderRadius="50%"
            objectFit="cover"
          />
        </UserImage>
        <UserInfo>
          <UserName>
            <Text
              fontWeight="600"
              lineHeight="16.8px"
              color="#1A1A1A"
            >
              {userName || 'Franko Komljenovic'}
            </Text>
          </UserName>
          {repliedUserName && (
            <UserReply>
              <Text
                fontWeight="500"
                fontSize="12px"
                lineHeight="14.4px"
                color="#1A1A1A"
                opacity="0.5"
              >
                Replied to
              </Text>
              <Text
                fontWeight="500"
                fontSize="12px"
                lineHeight="14.4px"
                color="#FF7F51"
              >
                {repliedUserName ?? 'Charleene Williamson'}
              </Text>
            </UserReply>
          )}
        </UserInfo>
      </User>
    </ViewUser>
  </View>
);