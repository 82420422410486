import React, { useCallback, useEffect, useState } from 'react';
import { Pagination } from '../../components/Pagination';
import { Layout } from '../../components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../store/ui';
import { FormTypes, SnackbarTypes } from '../../constants';
import { Container } from '../../components/Container';
import { Loader } from '../../components/Loader';
import { PartnerApi } from '../../api/partner.api';
import { Button } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { getUser } from '../../store/auth';
import { Form } from './Form';
import { Box } from '../../components/Box';
import { Search } from '../../components/Search';
import { useSearch } from '../../components/Search/hook';
import { PartnerCard } from './PartnerCard';
import { Row } from '../../components/Row';
import { usePagination } from '../../utilities/hooks';

/**
 * @typedef {object} DialogProps
 * @property {boolean} open
 * @property {import('../../models/user.model').UserModel|null} user
 */

export const PartnerKeys = {
  Code: 'code',
  UserID: 'user_id',
}

const searchItems = [
  {
    label: 'Code',
    value: PartnerKeys.Code,
  },
  {
    label: 'User ID',
    value: PartnerKeys.UserID,
  }
];

/**
 * @typedef {object} SnackbarProps
 * @property {boolean} open
 * @property {string} message
 * @property {import('@mui/material').SnackbarOrigin} anchorOrigin
 * @property {number} autoHideDuration
 */

/** @typedef {import('../../models/user.model').UserModel} UserModel */

export const Partners = () => {
  const dispatch = useDispatch();
  const [partners, setPartners] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector(getUser);
  const [isFormShown, setIsFormShown] = useState(false);
  const [formType, setFormType] = useState(FormTypes.Create);
  const [updatedPartner, setUpdatedPartner] = useState(null);

  const {
    searchItem,
    searchText,
    searchInputChange,
    searchSelectChange,
  } = useSearch({
    key: 'search:partners',
    item: searchItems[0],
    text: '',
  })

  const form = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: yup.object().shape({
      code: yup.string()
        .min(1)
        .required(),
    }),
  });

  const {
    values,
    isValid,
    setValues,
  } = form;

  const {
    page,
    limit,
    setPageState,
    getPageState,
    next,
    previous,
  } = usePagination({
    key: 'partners',
    defaultPageState: -1
  });

  const loadPartners = useCallback(async (code = undefined) => {
    try {
      setLoading(true);
      const { data: newPartners, metadata } = await PartnerApi.getPartners({
        size: limit,
        code,
        cursor: getPageState(),
      });
      setPageState(metadata.pageState)
      setPartners(newPartners);
    } catch (error) {
      setPartners([]);
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "The partners can't be loaded",
      }))
    } finally {
      setLoading(false);
    }
  }, [limit, dispatch, setPageState, getPageState]);

  useEffect(() => {
    const {
      value,
    } = searchItem;
    if (searchText.length === 0) {
      setRows(partners);
      return;
    }
    if (value === PartnerKeys.Code) {
      const filteredRows = partners.filter(
        v => v.code.toLowerCase().includes(searchText.toLowerCase())
      );
      setRows(filteredRows);
    }
    if (value === PartnerKeys.UserID) {
      const filteredRows = partners.filter(
        v => v.user_id.toLowerCase().includes(searchText.toLowerCase())
      );
      setRows(filteredRows);
    }
  }, [partners, searchItem, searchText]);

  useEffect(() => {
    loadPartners();
  }, [loadPartners]);


  const paginationNextClick = useCallback(() => {
    next();
    loadPartners();
  }, [next, loadPartners]);

  const paginationPreviousClick = useCallback(() => {
    previous();
    loadPartners();
  }, [previous, loadPartners]);

  const createClick = useCallback(() => {
    setValues({
      code: '',
    });
    setFormType(FormTypes.Create);
    setIsFormShown(true);
  }, [setValues]);

  const formCancel = useCallback(() => {
    setIsFormShown(false);
  }, []);

  const createPartner = useCallback(async () => {
    try {
      setLoading(true)
      const { user_id: userId } = user;
      const { code } = values;
      await PartnerApi.createPartner({
        userId,
        code,
      })
      setIsFormShown(false);
      loadPartners();
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: "The partner has been created",
      }))
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "Error: the partner hasn't been created",
      }))
    } finally {
      setLoading(false);
    }
  }, [user, values, loadPartners, dispatch]);

  const updatePartner = useCallback(async () => {
    try {
      setLoading(true);
      const { code: codeNew } = values;
      const { code: codeOld } = updatedPartner;
      await PartnerApi.updatePartner({
        codeOld,
        codeNew,
      })
      setIsFormShown(false);
      loadPartners();
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: "The partner has been updated",
      }))
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "Error: the partner hasn't been updated",
      }))
    } finally {
      setLoading(false);
    }
  }, [values, updatedPartner, dispatch, loadPartners]);

  const formSubmit = useCallback(async () => {
    if (!isValid) {
      return;
    }
    if (formType === FormTypes.Create) {
      createPartner();
    }
    if (formType === FormTypes.Update) {
      updatePartner();
    }
  }, [formType, isValid, createPartner, updatePartner]);

  const partnerCardEditClick = useCallback((partner) => {
    setUpdatedPartner(partner);
    setValues({
      code: partner.code,
    });
    setFormType(FormTypes.Update);
    setIsFormShown(true);
  }, [setValues]);

  return (
    <Layout
      breadcrumbs={[
        {
          link: '/',
          label: 'Home',
        },
        {
          label: 'Partners',
          link: '/partners',
        },
      ]}
    >
      <Container>
        <Row gap="10px">
          <Box flex="1">
            <Search
              items={searchItems}
              item={searchItem}
              text={searchText}
              onInputChange={searchInputChange}
              onSelectChange={searchSelectChange}
            />
          </Box>
          <Box padding="0px 0 0 180px" height="56px">
            <Button
              variant="contained"
              color="warning"
              onClick={createClick}
            >
              Create
            </Button>
          </Box>
        </Row>
        <Container gap="15px">
          {rows.map(partner => (
            <PartnerCard
              key={partner.code}
              partner={partner}
              onEditClick={partnerCardEditClick}
            />
          ))}
        </Container>
        <FormikProvider value={form}>
          <Form
            formType={formType}
            show={isFormShown}
            onCancel={formCancel}
            onSubmit={formSubmit}
          />
        </FormikProvider>
        {!loading && (
          <Pagination
            page={page}
            isPrevious={page > 1}
            isNext={partners.length === limit}
            onNextClick={paginationNextClick}
            onPreviousClick={paginationPreviousClick}
          />
        )}
        <Loader loading={loading} />
      </Container>
    </Layout>
  );
};  