import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { Tab, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { Attributes } from './Attributes';
import { Box } from '../../../components/Box';
import { About } from './About';
import { PostCard } from '../../../components/PostCard';
import { TABS } from '..';
import { NFT } from './NFT';
import { useUtility } from '../../../utilities/hooks';
import InfiniteScroll from 'react-infinite-scroll-component';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

/** @typedef {import('../../../models/user.model').UserModel} UserModel */
/** @typedef {import('../../../models/post.model').PostModel} PostModel */

/**
 * @param {object} props 
 * @param {UserModel} props.user
 * @param {PostModel[]} props.originalPosts
 * @param {MetaModel|null} props.metaOfOriginalPosts
 * @param {PostModel[]} props.reboundPosts
 * @param {MetaModel|null} props.metaOfReboundPosts
 * @param {PostModel[]} props.nftPosts
 * @param {string} props.tab
 * @param {(field) => void} props.onAboutSave
 * @param {(actions) => void} props.onActionSave
 * @param {() => void} props.onActionChange
 * @param {() => void} props.onNftSuspend
 * @param {(tab) => void} props.onTabChange
 * @param {(post: PostModel) => void} props.onPostDeleteClick
 * @returns {ReactNode}
 */
export const Content = ({
  user,
  originalPosts,
  metaOfOriginalPosts,
  reboundPosts,
  metaOfReboundPosts,
  nftPosts,
  attributes,
  tab,
  onPostClick,
  onPostDeleteClick,
  onNftSuspend,
  onTabChange,
  onAttributesSave,
  onAboutSave,
  onPostsNext,
  onReboundsNext,
}) => {
  const tabsChange = useCallback((_, newTab) => {
    onTabChange(newTab);
  }, [onTabChange]);

  const postClick = useCallback((post) => () => {
    onPostClick(post)
  }, [onPostClick]);

  const postDelete = useCallback((post) => () => {
    onPostDeleteClick(post)
  }, [onPostDeleteClick]);

  const {
    copyClick,
  } = useUtility();

  return (
    <Container>
      <TabContext value={tab}>
        <Tabs value={tab} onChange={tabsChange} aria-label="basic tabs example">
          <Tab label={`POSTS`} value={TABS.POSTS} />
          <Tab label={`REBOUNDS`} value={TABS.REBOUNDS} />
          <Tab label="ABOUT" value={TABS.ABOUT} />
          <Tab label="Attributes" value={TABS.Attributes} />
          {user?.userSettings?.sale_started && (
            <Tab label="NFT" value={TABS.NFT} />
          )}
        </Tabs>
        <TabPanel value={TABS.POSTS}>
          <InfiniteScroll
            dataLength={originalPosts.length}
            next={onPostsNext}
            style={{ display: 'flex', flexDirection: 'column' }}
            hasMore={true}
            scrollableTarget="main"
          >
            {originalPosts.map(post => (
              <PostCard
                key={post.post_id}
                post={post}
                isDeleteButton
                onClick={postClick(post)}
                onCopy={copyClick(post.post_id, 'The post ID has been copied')}
                onDelete={postDelete(post)}
              />
            ))}
          </InfiniteScroll>
        </TabPanel>
        <TabPanel value={TABS.REBOUNDS}>
        <InfiniteScroll
            dataLength={reboundPosts.length}
            next={onReboundsNext}
            style={{ display: 'flex', flexDirection: 'column' }}
            hasMore={true}
            scrollableTarget="main"
          >
            {reboundPosts.map(post => (
              <PostCard
                key={post.post_id}
                post={post}
                isDeleteButton
                onClick={postClick(post)}
                onCopy={copyClick(post.post_id, 'The post ID has been copied')}
                onDelete={postDelete(post)}
              />
            ))}
          </InfiniteScroll>
        </TabPanel>
        <TabPanel value={TABS.ABOUT}>
          <About user={user} onSave={onAboutSave} />
        </TabPanel>
        <TabPanel value={TABS.Attributes}>
          <Attributes 
            attributes={attributes} 
            onSave={onAttributesSave} 
          />
        </TabPanel>
        {user?.userSettings?.sale_started && (
          <TabPanel value={TABS.NFT}>
            <NFT 
              user={user} 
              posts={nftPosts}
              onSuspend={onNftSuspend}
            />
          </TabPanel>
        )}
      </TabContext>
    </Container>
  )
};