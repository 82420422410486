import React, { useCallback } from 'react';
import { Row } from '../Row';
import { Box } from '../Box';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

/** @typedef {import('react').ReactNode} ReactNode */
/** @typedef {import('@mui/material').SelectChangeEvent<Item>} SelectChangeEvent */
/** @typedef {import('react').KeyboardEvent<HTMLInputElement>} KeyboardEvent */
/** @typedef {import('@mui/material').TextFieldProps} TextFieldProps */

/** 
 * @typedef {object} Item 
 * @property {string} label
 * @property {string} value
*/

/** 
 * @callback InputChange
 * @param {string} text
 * @returns {void}
*/

/**
 * @callback SelectChange
 * @param {Item} item
 * @returns {void}
 */

/**
 * @typedef {object} Props
 * @property {Item} item
 * @property {Item[]} items
 * @property {string} text
 * @property {TextFieldProps} textFieldProps
 * @property {SelectChange} onSelectChange
 * @property {InputChange} onInputChange
 */

/**
 * @param {Props} props
 * @returns {ReactNode}
 */
export const Search = ({
  item,
  items,
  text,
  textFieldProps = {
    fullWidth: true,
    label: "Filter query",
    placeholder: "Enter filter",
  },
  onSelectChange,
  onInputChange,
}) => {

  const selectChange = useCallback(
    /** @param {SelectChangeEvent} event @returns {void} */
    event => {
      const { target: { value } } = event;
      const item = items.find(item => item.value === value);
      if (!item) {
        return;
      }
      onSelectChange(item);
    }, [items, onSelectChange],
  );

  const inputChange = useCallback(
    /** @param {KeyboardEvent} event @returns {void} */
    event => {
      const { target: { value } } = event;
      onInputChange(value);
    }, [onInputChange],
  );

  return (
    <Row gap="16px" marginBottom="20px">
      <Box minWidth="175px">
        <FormControl fullWidth>
          <InputLabel id="search-label">
            Select a filter type
          </InputLabel>
          <Select
            labelId="search-label"
            id="search-select"
            value={item.value}
            label="Select a filter type"
            placeholder="Select a filter type"
            onChange={selectChange}
          >
            {items.map(item => (
              <MenuItem 
                key={item.value} 
                value={item.value}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box flex="1">
        <TextField 
          fullWidth
          value={text}
          onChange={inputChange}
          {...textFieldProps}
        />
      </Box>
    </Row>
  )
}