import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getSnackbar, setSnackbar } from "../../store/ui";
import { Alert, Snackbar as MuiSnackbar } from "@mui/material";

export const Snackbar = () => {
  const dispatch = useDispatch();
  const snackbarProps = useSelector(getSnackbar);

  const closeSnackbar = useCallback(() => {
    dispatch(setSnackbar({
      ...snackbarProps,
      open: false,
    }))
  }, [snackbarProps, dispatch]);

  return (
    <MuiSnackbar
      open={snackbarProps.open}
      anchorOrigin={snackbarProps.anchorOrigin}
      autoHideDuration={snackbarProps.autoHideDuration}
      onClose={closeSnackbar}
    >
      <Alert
        variant='filled'
        severity={snackbarProps.type}
        onClose={closeSnackbar}
      >
        {snackbarProps.content}
      </Alert>
    </MuiSnackbar>
  );
}