import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import React from 'react';

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, .3);
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-width: 100px;
  min-height: 100px;
  z-index: 1000;
  background: rgba(0, 0, 0, .3);
`;

export const LoaderTypes = {
  Default: 'default',
  Container: 'container',
}

export const Loader = ({
  type = LoaderTypes.Default,
  color = 'secondary',
  loading,
}) => {
  if (!loading) {
    return null;
  }
  switch (type) {
    case LoaderTypes.Container:
      return (
        <Container>
          <CircularProgress variant='indeterminate' color={color} />
        </Container>
      )
    case LoaderTypes.Default:
    default:
      return (
        <Loading>
          <CircularProgress variant='indeterminate' color={color} />
        </Loading>
      );
  }
}