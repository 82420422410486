import { UserModel } from "../models/user.model";
import { urlSearchParamSetter } from "../utilities";
import { http, httpOfRecrd } from "./http";

/**
 * @typedef {object} UsersResult
 * @property {import('../models/user.model').UserModel[]} users
 * @property {string} pageState
 * @property {number} count
 */

export class UserApi {
  static async getUsersOfRecrd({
    q,
    page,
  }) {
    try {
      const params = new URLSearchParams({
        q,
        page: page ?? 1,
      });
      const response = await httpOfRecrd.get(`/engage_search/v3/users?${params}`);
      return {
        users: response.data,
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  /**
   * @param {object} props 
   * @param {number} props.pageState
   * @param {number} props.limit
   * @param {Recrd<string, string>} props.filter
   * @returns {Promise<UsersResult>}
   */
  static async getUsers(props) {
    const {
      userId,
      userIds,
      userName,
      email,
      phone,
      isNft,
      limit,
      pageState,
    } = props;
    const params = new URLSearchParams();
    const setter = urlSearchParamSetter(params);
    setter('limit', limit);
    setter('pageState', pageState);
    setter('userId', userId);
    setter('email', email);
    setter('phone', phone);
    setter('userName', userName);
    setter('userIds', userIds);
    setter('isNft', isNft);
    if (isNft) {
      params.delete('pageState');
    }
    const response = await http.get(`/users?${params}`);
    return response.data;
  }

  /**
   * @param {object} props 
   * @param {number} props.userId
   * @returns {Promise<import('../models/user.model').UserModel>}
   */
  static async getUser(props) {
    const response = await http.get(`/users/${props.userId}`);
    const user = new UserModel(response.data.user);
    return user;
  }

  static async updateUser(userId, data) {
    const {
      userName,
      email,
      phoneNumber,
      password,
      fullBio,
      shortBio,
      attributes,
    } = data;
    await http.put(`/users/${userId}`, {
      userName,
      email,
      phoneNumber,
      password,
      fullBio,
      shortBio,
      attributes,
    });
  }

  /**
   * @param {object} props 
   * @param {string} props.userId
   * @returns {void}
   */
  static async deleteUser(props) {
    const {
      userId
    } = props;
    const params = new URLSearchParams({
      user_id: userId,
    })
    await httpOfRecrd.delete(`/auth_support/v3/delete?${params}`);
  }
}