

export class UserSettingsModel {
  static Attributes = {
    'Celeb': '1',
    'DemoModeAllowed': '1',
    'Private': '1',
    'isInternal': '1',
    'isTester': '1',
  };
  /// FIELDS
  /** @type {string} */
  user_id;
  /** @type {string[]|null} */
  attributes;
  /** @type {string|null} */
  bio_full;
  /** @type {string|null} */
  bio_short;
  /** @type {string|null} */
  fullname;
  /** @type {string|null} */
  image_url;
  /** @type {string|null} */
  language;
  /** @type {boolean|null} */
  monetise;
  /** @type {string|null} */
  name;
  /** @type {string[]|null} */
  notify_attributes;
  /** @type {boolean|null} */
  sale_allowed;
  /** @type {boolean|null} */
  sale_started;
  /** @type {boolean|null} */
  sale_trusted;
  /** @type {string|null} */
  sale_wallet_addresses;
  /** @type {string[]|null} */
  social_accounts_v4;
  /** @type {boolean|null} */
  stripe_ok;
  /** @type {string|null} */
  stripe_token;
  /** @type {string|null} */
  sui_object;
  /// RELATIONS
  /** @type {import('./user.model').UserModel|null} */
  user;
  /** @param {Partial<UserSettingsModel>} data */
  constructor(data) {
    Object.assign(this, data);
  }
}