import React from 'react';
import styled from '@emotion/styled';
import { Image, Text } from '../shared';
import { Item } from './Item';

const View = styled.div`
  display: flex;
  flex-direction: column;
  padding: 113.13px 0 0 0;
`;

const Title = styled.div`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  padding: 25px 0 0 0;
  gap: 10.8px;
`;

const Main = styled.div`
  display: flex;
`;

const Card = styled.div`
  position: relative;
  display: flex;
  width: 682.43px;
  height: 529.37px;
`;

const CardImage = styled.img`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
`;

const CardImageShadow = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: transparent;
  background: linear-gradient(rgba(0, 0, 0, 0.3) 0%, transparent 30%), linear-gradient(transparent 80%, rgba(0, 0, 0, 0.3) 100%);
`;

const CardOriginal = styled.div`
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  display: flex;
  padding: 18.44px 0 0 18.01px;
`;

const CardProfile = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 0 18px 18.36px;
`;

const Origin = styled.div`
  display: flex;
  gap: 8px;
`;

const OriginLeft = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
`;

const OriginRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const OriginImage = styled.img`
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #000000;
  object-fit: cover;
  z-index: 1;
`;

const OriginIcon = styled.img`
  position: relative;
  display: inline-flex;
  width: 14.31px;
  height: 14.31px;
  z-index: 2;
`;

const OriginTitle = styled.div`
  display: flex;
`;

const OriginType = styled.div`
  display: flex;
  opacity: 0.5;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10.62px;
`;

const ProfileImage = styled.div`
  display: flex;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const ProfileName = styled.div`
  display: flex;
`;

const ProfileReply = styled.div`
  display: flex;
  gap: 4px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DescriptionText = styled.div`
  display: flex;
`;

const DescriptionHashtags = styled.div`
  display: flex;
  gap: 6px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.div`
  display: flex;
`;

const ListDivider = styled.div`
  margin: 9.23px 0;
  border: 1px solid #1A1A1A;
  opacity: 0.1;
`;

export const Explore = () => (
  <View>
    <Title>
      <Text
        fontFamily="Suisse Intl Condensed"
        fontSize="90px"
        fontWeight="700"
        lineHeight="79.2px"
        color="#1A1A1A"
      >
        EXPLORE
      </Text>
    </Title>
    <Content>
      <Main>
        <Card>
          <CardImage src="/images/explore-thumb.png" />
          <CardImageShadow />
          <CardOriginal>
            <Origin>
              <OriginLeft>
                <OriginIcon src="/icons/preview-union.svg" />
                <OriginImage src="/images/preview-thump-profile.png" />
              </OriginLeft>
              <OriginRight>
                <OriginType>
                  <Text
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="14px"
                    color="#FFFFFF"
                  >
                    Original Video
                  </Text>
                </OriginType>
                <OriginTitle>
                  <Text
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="18.2px"
                  >
                    Holocene, PT II from...
                  </Text>
                </OriginTitle>
              </OriginRight>
            </Origin>
          </CardOriginal>
          <CardProfile>
            <Profile>
              <ProfileImage>
                <Image
                  src="/images/preview-user.png"
                  width="40px"
                  height="40px"
                  borderRadius="50%"
                  objectFit="cover"
                />
              </ProfileImage>
              <ProfileInfo>
                <ProfileName>
                  <Text
                    fontWeight="600"
                    lineHeight="16.8px"
                    color="#FFFFFF"
                  >
                    Franko Komljenovic
                  </Text>
                </ProfileName>
                <ProfileReply>
                  <Text
                    fontWeight="500"
                    fontSize="12px"
                    lineHeight="14.4px"
                    color="#1A1A1A"
                    opacity="0.5"
                  >
                    Replied to
                  </Text>
                  <Text
                    fontWeight="500"
                    fontSize="12px"
                    lineHeight="14.4px"
                    color="#FF7F51"
                  >
                    Charleene Williamson
                  </Text>
                </ProfileReply>
              </ProfileInfo>
            </Profile>
            <Description>
              <DescriptionText>
                <Text
                  fontSize="21px"
                  fontWeight="600"
                  lineHeight="23.1px"
                >
                  I can’t believe I’ve met a couple of singers in the cutest café!! 
                  <span role="img" aria-label="wow">😱</span>
                </Text>
              </DescriptionText>
              <DescriptionHashtags>
                {['#comedy', '#cats', '#podcast', '#podcast'].map((v, i) => (
                  <Text
                    key={i}
                    display="inline-flex"
                    fontSize="12px"
                    fontWeight="500"
                    lineHeight="14.4px"
                    color="#FFFFFF"
                    opacity="0.5"
                  >
                    {v}
                  </Text>
                ))}
              </DescriptionHashtags>
            </Description>
          </CardProfile>
        </Card>
      </Main>
      <List>
        <ListItem>
          <Item 
            thumbUrl="/images/explore-list-image-1.png"
            rebound 
          />
        </ListItem>
        <ListDivider />
        <ListItem>
          <Item
            thumbUrl="/images/explore-list-image-2.png" 
            rebound 
          />
        </ListItem>
        <ListDivider />
        <ListItem>
          <Item
            thumbUrl="/images/explore-list-image-3.png"
          />
        </ListItem>
      </List>
    </Content>
  </View>
);