import { 
  ReportedUserModel, 
} from "../models/reported-user.model";
import { urlSearchParamSetter } from "../utilities";
import { http, httpOfRecrd } from "./http";

export class ReportApi {
  static async getUsers(data) {
    const {
      reportId,
      reporterId,
      userId,
      limit,
      offset,
    } = data;
    const params = new URLSearchParams();
    const setter = urlSearchParamSetter(params);
    setter('report_id', reportId);
    setter('reporterId', reporterId);
    setter('userId', userId);
    setter('offset', offset ?? 0);
    setter('limit', limit ?? 10);
    const response = await httpOfRecrd.get(`/engage_report/v3/report/user?${params}`);
    const reports = response.data.content.map(v => new ReportedUserModel(v));
    return {
      data: {
        reports,
      },
      response,
    };
  }

  static async updateUser(reportId, data) {
    const {
      status
    } = data;
    await httpOfRecrd.put(`/engage_report/v3/report/user`, {
      report_id: reportId,
      status,
    });
  }

  static async getPosts(data) {
    const {
      reportedPostId,
      reporterId,
      limit,
      pageState,
    } = data;
    const params = new URLSearchParams({
      includes: ['post', 'post.user', 'post.user.userSettings', 'reporter']
    });
    if (pageState !== undefined) {
      params.set('pageState', pageState);
    }
    if (limit !== undefined) {
      params.set('limit', limit);
    }
    if (reportedPostId !== undefined) {
      params.set('reportedPostId', reportedPostId);
    }
    if (reporterId !== undefined) {
      params.set('reporterId', reporterId);
    }
    const response = await http.get(`/reports/posts?${params}`);
    return response.data;
  }

  static async getComments(data) {
    const {
      reportedCommentId,
      reporterId,
      limit,
      pageState,
      includes,
    } = data;
    const params = new URLSearchParams({
      includes,
    });
    if (pageState !== undefined) {
      params.set('pageState', pageState);
    }
    if (limit !== undefined) {
      params.set('limit', limit);
    }
    if (reportedCommentId !== undefined) {
      params.set('reportedCommentId', reportedCommentId);
    }
    if (reporterId !== undefined) {
      params.set('reporterId', reporterId);
    }
    const response = await http.get(`/reports/comments?${params}`);
    return response.data;
  }
}