import { urlSearchParamSetter } from "../utilities";
import { http } from "./http";

export class ReactionApi {
  static async getReactions(props) {
    const {
      objectId,
      includes,
    } = props;
    const params = new URLSearchParams();
    const setter = urlSearchParamSetter(params);
    setter('includes', includes);
    const response = await http.get(`/reactions/${objectId}?${params}`);
    return response.data;
  }
}