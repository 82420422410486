import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { Row } from '../../../components/Row';
import { Box } from '../../../components/Box';
import { FormTypes } from '../../../constants';

export const Form = ({
  formType,
  show,
  onSubmit,
  onCancel,
}) => {
  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
  } = useFormikContext();

  return (
    <Dialog
      open={show}
      onClose={onCancel}
    >
      <DialogTitle>
        {formType === FormTypes.Create && 'Create a partner'}
        {formType === FormTypes.Update && 'Edit the partner'}
      </DialogTitle>
      <DialogContent>
        <Row width="400px">
          <TextField
            fullWidth
            placeholder="Enter a code..."
            name="code"
            value={values.code}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.code && errors.code}
            hint={touched.code && errors.code}
          />
        </Row>
      </DialogContent>
      <DialogActions>
        <Box gap="10px">
          <Box>
            <Button
              variant="contained"
              color="error"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="info"
              disabled={!isValid}
              onClick={onSubmit}
            >
              {formType === FormTypes.Create && 'Create'}
              {formType === FormTypes.Update && 'Update'}
              
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}