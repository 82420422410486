
export class UserPrivilegeFeatureModel {
  /// FILEDS
  /** @type {string} */
  feature_slug;
  /** @type {string} */
  feature_description;
  /** @type {string[]} */
  privileges;
  /// RELATIONS
  
  /// CONSTRUCTOR
  /** @param {Partial<UserPrivilegeFeatureModel>} data */
  constructor(data) {
    Object.assign(this, data);
  }
}