import { UserPrivilegeFeatureModel } from '../models/user-privilege-feature.model';
import { UserPrivilegeModel } from '../models/user-privilege.model';
import { http } from './http';

export class AccessApi {
  /** @returns {Promise<UserPrivilegeModel[]>} */
  static async getPrivileges() {
    const params = new URLSearchParams({
      include: ['user'],
    })
    const response = await http.get(`/access/privileges?${params}`);
    const privileges = response.data.userPrivileges.map(v => new UserPrivilegeModel(v));
    return privileges;
  }

  static async createPrivilege(data) {
    const {
      email,
      attributes,
    } = data;
    await http.post('/access/privileges', {
      email,
      attributes,
    });
  }

  static async updatePrivilege(userId, props) {
    const {
      attributes,
    } = props;
    await http.put(`/access/privileges/${userId}`, {
      attributes,
    });
  }

  static async deletePrivilege(userId) {
    await http.delete(`/access/privileges/${userId}`);
  }

  static async getFeatures() {
    const response = await http.get('/access/features');
    const features = response.data.features.map(v => new UserPrivilegeFeatureModel(v));
    return features;
  }

  static async createFeature(props) {
    const {
      featureSlug,
      featureDescription,
      privileges,
    } = props;
    await http.post('/access/features', {
      featureSlug,
      featureDescription,
      privileges,
    });
  }

  static async updateFeature(slugId, props) {
    const {
      featureSlug,
      featureDescription,
      privileges,
    } = props;
    await http.put(`/access/features/${slugId}`, {
      featureSlug,
      featureDescription,
      privileges,
    });
  }

  static async deleteFeature(featureSlug) {
    await http.delete(`/access/features/${featureSlug}`);
  }
}