
export class S3PostModel {
  /** @type {string} */
  bucket;
  /** @type {any} json */
  json;
  /** @param {PostS3Model} data */
  constructor(data) {
    Object.assign(this, data);
  }
}