import React from 'react';
import { Row } from '../../../components/Row';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  FormControl, 
  InputLabel, 
  MenuItem, 
  Select, 
  TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { Container } from '../../../components/Container';
import { FormTypes, TextColors, TextFontSizes } from '../../../constants';
import { Text } from '../../../components/Text';

/**
 * @typedef {object} CreateZoneDataCardProps
 * @property {string} formType
 * @property {boolean} isOpen
 * @property {() => void} onSubmit
 * @property {() => void} onCancel
 */

/**
 * @param {CreateZoneDataCardProps} props 
 * @returns {JSX.Element}
 */
export const Form = ({
  formType = FormTypes.Create,
  isOpen,
  countries,
  regions,
  onSubmit,
  onCancel,
}) => {
  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    handleBlur,
  } = useFormikContext();

  const isErrorShown = 
    (touched.locale || touched.country || touched.region || touched.city) &&
    (errors.locale || errors.country || errors.region || errors.city);

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
    >
      <DialogTitle>
        <Row gap="5px">
          <Text size={TextFontSizes.Large}>
            Editorial Zone: 
          </Text>
          <Text size={TextFontSizes.Large}>
            {formType === FormTypes.Create && 'New'}
            {formType === FormTypes.Update && 'Change'}
          </Text>
        </Row>
      </DialogTitle>
      <DialogContent>
        <Container gap="10px" width="500px">
          <Row>
            <TextField
              fullWidth
              variant="standard"
              label="Zone"
              name="zone"
              value={values.zone}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.zone && errors.zone}
              helperText={touched.zone && errors.zone}
            />
          </Row>
          <Row>
            <Text color={TextColors.Error} size="12px">
              {isErrorShown && (
                <>The locale, country, region or city should be filled in</>
              )}
            </Text>
          </Row>
          <Row>
            <TextField
              fullWidth
              variant="standard"
              label="Locale"
              name="locale"
              value={values.locale}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Row>
          <Row>
            <FormControl fullWidth>
              <InputLabel 
                id="select-label-region" 
                style={{ position: 'relative', left: '-14px'}}
              >
                Region
              </InputLabel>
              <Select
                labelId="select-label-region"
                label="Region"
                variant="standard"
                name="region"
                value={values.region}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <MenuItem value="">No region</MenuItem>
                {regions.map(v => (
                  <MenuItem
                    key={v}
                    value={v}
                  >
                    {v}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Row>
          <Row>
            <FormControl fullWidth>
              <InputLabel 
                id="select-label-region" 
                style={{ position: 'relative', left: '-14px'}}
              >
                Country
              </InputLabel>
              <Select
                labelId="select-label-region"
                label="Country"
                variant="standard"
                name="country"
                value={values.country}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <MenuItem value="">No country</MenuItem>
                {countries.map(v => (
                  <MenuItem
                    key={v.iso}
                    value={v.iso}
                  >
                    ({v.iso}) {v.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Row>
          <Row>
            <TextField
              fullWidth
              variant="standard"
              label="City"
              name="city"
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Row>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="outlined"
          color="success"
          disabled={!isValid}
          onClick={onSubmit}
        >
          {formType === FormTypes.Create && 'Create'}
          {formType === FormTypes.Update && 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}