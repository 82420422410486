import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

/**
 * 
 * @param {object} props 
 * @param {number} props.page
 * @param {boolean?} props.isPrevious
 * @param {boolean?} props.isNext
 * @param {() => void} props.onPreviousClick
 * @param {() => void} props.onNextClick
 * @returns {import('react').ReactNode}
 */
export const Pagination = ({
  page,
  isPrevious = true,
  isNext = true,
  onNextClick,
  onPreviousClick
}) => {
  return (
    <Container>
      <Box display="flex" alignItems="center">
        {isPrevious && (
          <Box>
            {page > 1 && (
              <Button variant="contained" color="error" onClick={onPreviousClick}>
                PREVIOUS
              </Button>
            )}
          </Box>
        )}
        <Box padding="0 25px">
          <Typography color="#c62828">
            Page: {page}
          </Typography>
        </Box>
        {isNext && (
          <Button variant="contained" color="error" onClick={onNextClick}>
            NEXT
          </Button>
        )}
      </Box>
    </Container>
  )
}