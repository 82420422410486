
export class NotifByUserModel {
  /// FILEDS
  /** @type {string} */
  notif_user_id;
  /** @type {string} */
  notif_id;
  /** @type {Recrd<string, string>} */
  details;
  /// RELATIONS
  /** @type {import('./user.model').UserModel|null} */
  user;
  /// CONSTRUCTOR
  /** @param {Partial<UserModel>} data */
  constructor(data) {
    Object.assign(this, data);
  }
}