import React, { useMemo } from 'react';
import { Tab, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { Reactions } from './Reactions';
import { useSelector } from 'react-redux';
import { hasFeatures } from '../../../store/auth';
import { Moderation } from './Moderation';
import { NFT } from './NFT';
import { S3 } from './S3';
import { Details } from './Details';
import { Box } from '../../../components/Box';
import { Text } from '../../../components/Text';
import { Container } from '../../../components/Container';
import { TABS } from '../constants';
import { TextColors } from '../../../constants';

/** @typedef {import('../../../models/post.model').PostModel} PostModel */
/** @typedef {import('../../../models/s3-post.model').S3PostModel} S3PostModel */

/**
 * @param {object} props 
 * @param {PostModel} props.post
 * @param {S3PostModel[]} props.s3Posts
 * @param {boolean} props.loadingOfS3Posts
 * @param {object} props.reaction
 * @param {string} props.tab
 * @param {(_, newTab) => void} props.tabChange
 * @returns {ReactNode}
 */
export const Content = ({
  post,
  s3Posts,
  loadingOfS3Posts,
  reaction,
  tab,
  onTabChange,
}) => {
  const isReactions = useSelector(hasFeatures(['read-post-reactions']));
  const isModeration = useSelector(hasFeatures(['read-post-moderation']));

  const hashtags = useMemo(() => {
    if (!post.hashtags) {
      return <></>;
    }
    return post.hashtags.map(v => `#${v}`).join(' ');
  }, [post]);

  return (
    <Container>
      <Box flexDirection="column">
        <Box>
          <Text color={TextColors.Title}>{post.title}</Text>
        </Box>
        <Box>
          <Text color={TextColors.Message}>{post.narrative} {hashtags}</Text>
        </Box>
      </Box>
      <TabContext value={tab}>
        <Tabs value={tab} onChange={onTabChange}>
          <Tab label="DETAILS" value={TABS.DETAILS} />
          {isReactions && (
            <Tab label={`REACTIONS (${reaction?.reactions?.length ?? 0})`} value={TABS.REACTIONS} />
          )}
          {isModeration && (
            <Tab label="MODERATION" value={TABS.MODERATION} />
          )}
          <Tab label="NFT" value={TABS.NFT} />
          <Tab label="S3" value={TABS.S3} />
        </Tabs>
        <TabPanel value={TABS.DETAILS}>
          <Details
            post={post}
          />
        </TabPanel>
        {isReactions && (
          <TabPanel value={TABS.REACTIONS}>
            <Reactions reaction={reaction} />
          </TabPanel>
        )}
        {isModeration && (
          <TabPanel value={TABS.MODERATION}>
            <Moderation post={post} />
          </TabPanel>
        )}
        <TabPanel value={TABS.NFT}>
          <NFT post={post} />
        </TabPanel>
        <TabPanel value={TABS.S3}>
          <S3
            s3Posts={s3Posts}
            loadingOfS3Posts={loadingOfS3Posts}
          />
        </TabPanel>
      </TabContext>
    </Container>
  )
};