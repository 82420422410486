
export const PostStatusKeys = {
  'C': 'C',
  'P': 'P',
  'V': 'V',
  'T': 'T',
  'H': 'H',
  'A': 'A',
  'D': 'D',
  'S': 'S',
  'F': 'F',
}

export const PostStatusLabels = {
  'C': 'CREATED (Uploading)',
  'P': 'PENDING (Processing)',
  'V': 'DECLINED (Declined - failed moderation check)',
  'T': 'DECLINED (Declined - failed transcribing check)',
  'H': 'FAULT (Video fault - failed HLS encoding)',
  'A': 'ACTIVE (and available in all post feeds)',
  'D': 'DELETED (and available in all post feeds)',
  'S': 'SUSPENDED',
  'F': 'FORCE PUBLISH under way',
}