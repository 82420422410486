import React, { useCallback, useEffect } from 'react';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { Container } from '../../../../components/Container';
import { Column } from '../../../../components/Column';
import { useFormik } from 'formik';
import { PartnerAttributeKeys, UserAttributeKeys } from '../../../../constants/attributes';
import { Row } from '../../../../components/Row';
import { useSelector } from 'react-redux';
import { Feature } from '../../../../components/Feature';
import { hasFeatures } from '../../../../store/auth';
import { 
  PartnerAttributeEditFeatures, 
  PartnerAttributeReadFeatures, 
  UserAttributeEditFeatures, 
  UserAttributeReadFeatures,
} from '../../../../constants/features';

/** @typedef {import('react').ReactNode} ReactNode */
/** @typedef {import('../../../../models/user.model').UserModel} UserModel */

/**
 * 
 * @param {object} props 
 * @param {Record<string, boolean>} props.attributes
 * @param {(values: Record<string, boolean>) => void} props.onSave
 * @returns {ReactNode}
 */
export const Attributes = ({
  attributes,
  onSave,
}) => {
  const PrivateEdit = useSelector(hasFeatures([UserAttributeEditFeatures.Private]));
  const CampaignEdit = useSelector(hasFeatures([UserAttributeEditFeatures.Campaign]));
  const CelebrityEdit = useSelector(hasFeatures([UserAttributeEditFeatures.Celebrity]));
  const DemoModeEdit = useSelector(hasFeatures([UserAttributeEditFeatures.DemoMode]));
  const SafeContentEdit = useSelector(hasFeatures([UserAttributeEditFeatures.SafeContent]));
  const InternalEdit = useSelector(hasFeatures([UserAttributeEditFeatures.Internal]));
  const TesterEdit = useSelector(hasFeatures([UserAttributeEditFeatures.Tester]));

  const GoldEdit = useSelector(hasFeatures([PartnerAttributeEditFeatures.Gold]));
  const SilverEdit = useSelector(hasFeatures([PartnerAttributeEditFeatures.Silver]));
  const PlatinumEdit = useSelector(hasFeatures([PartnerAttributeEditFeatures.Platinum]));
  const InvitationEdit = useSelector(hasFeatures([PartnerAttributeEditFeatures.Invitation]));

  const form = useFormik({
    initialValues: {
      [UserAttributeKeys.Private]: false,
      [UserAttributeKeys.Campaign]: false,
      [UserAttributeKeys.Celebrity]: false,
      [UserAttributeKeys.DemoMode]: false,
      [UserAttributeKeys.SafeContent]: false,
      [UserAttributeKeys.Internal]: false,
      [UserAttributeKeys.Tester]: false,

      [PartnerAttributeKeys.Gold]: false,
      [PartnerAttributeKeys.Silver]: false,
      [PartnerAttributeKeys.Platinum]: false,
      [PartnerAttributeKeys.Invitation]: false,
    },
  });
  const {
    values,
    setValues,
  } = form;

  useEffect(() => {
    setValues({
      ...attributes,
    })
  }, [attributes, setValues]);

  const userAttributeCheckboxChange = useCallback((event, checked) => {
    const { target: { name: field } } = event;
    const newValues = {
      ...values,
      [field]: checked,
    };
    setValues(newValues);
  }, [values, setValues]);

  const partnerAttributeCheckboxChange = useCallback((event, checked) => {
    const { target: { name: field } } = event;
    const emptyValues = {};
    Object.keys(PartnerAttributeKeys).map(key => emptyValues[PartnerAttributeKeys[key]] = false);
    const newValues = {
      ...values,
      ...emptyValues,
      [field]: checked,
    };
    setValues(newValues);
  }, [values, setValues]);

  const saveClick = useCallback(() => {
    onSave({
      ...values,
    })
  }, [values, onSave]);
  
  return (
    <Container>
      <Row gap="25px">
        <Column>
          <Row>
            <Feature features={[UserAttributeReadFeatures.Private]}>
              <FormControlLabel 
                control={
                  <Checkbox 
                    name={UserAttributeKeys.Private}
                    checked={values[UserAttributeKeys.Private]}
                    disabled={!PrivateEdit}
                    onChange={userAttributeCheckboxChange} 
                  />
                } 
                label="Private"
              />
            </Feature>
          </Row>
          <Row>
            <Feature features={[UserAttributeReadFeatures.Celebrity]}>
              <FormControlLabel 
                control={
                  <Checkbox 
                    name={UserAttributeKeys.Celebrity}
                    checked={values[UserAttributeKeys.Celebrity]} 
                    disabled={!CelebrityEdit}
                    onChange={userAttributeCheckboxChange} 
                  />
                } 
                label="Celebrity"
              />
            </Feature>
          </Row>
          <Row>
            <Feature features={[UserAttributeReadFeatures.DemoMode]}>
              <FormControlLabel 
                control={
                  <Checkbox 
                    name={UserAttributeKeys.DemoMode}
                    checked={values[UserAttributeKeys.DemoMode]} 
                    disabled={!DemoModeEdit}
                    onChange={userAttributeCheckboxChange} 
                  />
                } 
                label="Demo Mode"
              />
            </Feature>
          </Row>
          <Row>
            <Feature features={[UserAttributeReadFeatures.SafeContent]}>
              <FormControlLabel 
                control={
                  <Checkbox 
                    name={UserAttributeKeys.SafeContent}
                    checked={values[UserAttributeKeys.SafeContent]} 
                    disabled={!SafeContentEdit}
                    onChange={userAttributeCheckboxChange} 
                  />
                } 
                label="Safe Content"
              />
            </Feature>
          </Row>
          <Row>
            <Feature features={[UserAttributeReadFeatures.Campaign]}>
              <FormControlLabel 
                control={
                  <Checkbox 
                    name={UserAttributeKeys.Campaign}
                    checked={values[UserAttributeKeys.Campaign]}
                    disabled={!CampaignEdit}
                    onChange={userAttributeCheckboxChange} 
                  />
                } 
                label="Campaign"
              />
            </Feature>
          </Row>
        </Column>
        <Column>
          <Row>
            <Feature features={[UserAttributeReadFeatures.Tester]}>
              <FormControlLabel 
                control={
                  <Checkbox 
                    name={UserAttributeKeys.Tester}
                    checked={values[UserAttributeKeys.Tester]} 
                    disabled={!TesterEdit}
                    onChange={userAttributeCheckboxChange} 
                  />
                } 
                label="Tester"
              />
            </Feature>
          </Row>
          <Row>
            <Feature features={[UserAttributeReadFeatures.Internal]}>
              <FormControlLabel 
                control={
                  <Checkbox 
                    name={UserAttributeKeys.Internal}
                    checked={values[UserAttributeKeys.Internal]} 
                    disabled={!InternalEdit}
                    onChange={userAttributeCheckboxChange} 
                  />
                } 
                label="Internal"
              />
            </Feature>
          </Row>
        </Column>
        <Column>
          <Row>
            <Feature features={[PartnerAttributeReadFeatures.Silver]}>
              <FormControlLabel 
                control={
                  <Checkbox 
                    name={PartnerAttributeKeys.Silver}
                    checked={values[PartnerAttributeKeys.Silver]} 
                    disabled={!SilverEdit}
                    onChange={partnerAttributeCheckboxChange} 
                  />
                } 
                label="Silver"
              />
            </Feature>
          </Row>
          <Row>
            <Feature features={[PartnerAttributeReadFeatures.Gold]}>
              <FormControlLabel 
                control={
                  <Checkbox 
                    name={PartnerAttributeKeys.Gold}
                    checked={values[PartnerAttributeKeys.Gold]} 
                    disabled={!GoldEdit}
                    onChange={partnerAttributeCheckboxChange} 
                  />
                } 
                label="Gold"
              />
            </Feature>
          </Row>
          <Row>
            <Feature features={[PartnerAttributeReadFeatures.Platinum]}>
              <FormControlLabel 
                control={
                  <Checkbox 
                    name={PartnerAttributeKeys.Platinum}
                    checked={values[PartnerAttributeKeys.Platinum]} 
                    disabled={!PlatinumEdit}
                    onChange={partnerAttributeCheckboxChange} 
                  />
                } 
                label="Platinum"
              />
            </Feature>
          </Row>
          <Row>
            <Feature features={[PartnerAttributeReadFeatures.Invitation]}>
              <FormControlLabel 
                control={
                  <Checkbox 
                    name={PartnerAttributeKeys.Invitation}
                    checked={values[PartnerAttributeKeys.Invitation]} 
                    disabled={!InvitationEdit}
                    onChange={partnerAttributeCheckboxChange} 
                  />
                } 
                label="Invitation"
              />
            </Feature>
          </Row>
        </Column>
      </Row>
      <Row justifyContent="flex-end">
        <Button
          variant="contained"
          color="info"
          onClick={saveClick}
        >
          Save
        </Button>
      </Row>
    </Container>
  )
}