
export const UserAttributeKeys = {
  Private: 'Private',
  Celebrity: 'Celeb',
  Campaign: 'isCampaign',
  DemoMode: 'isDemoModeAllowed',
  SafeContent: 'isSafeContent',
  Internal: 'isInternal',
  Tester: 'isTester',
};

export const PartnerAttributeKeys = {
  Silver: 'isSilver',
  Gold: 'isGold',
  Platinum: 'isPlatinum',
  Invitation: 'isInvite',
};

export const AttributeKeys = {
  UserAttributeKeys: { ...UserAttributeKeys },
  PartnerAttributeKeys: { ...PartnerAttributeKeys },
}