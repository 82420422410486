import { NotifByUserModel } from "../models/notif-by-user.model";
import { http } from "./http";

export class LogApi {
  static async getActivityLogs(props) {
    const {
      limit,
      pageState,
      userId,
      userName,
      includes,
    } = props;
    const params = new URLSearchParams({
      includes,
    });
    if (limit !== undefined) {
      params.set('limit', limit.toFixed(0))
    }
    if (!(pageState === undefined || pageState === null)) {
      params.set('pageState', pageState);
    }
    if (userId !== undefined) {
      params.set('userId', userId);
    }
    if (userName !== undefined) {
      params.set('userName', userName);
    }
    const response = await http.get(`/log/activity-logs?${params}`);
    return {
      models: response.data.models.map(v => new NotifByUserModel(v)),
      pageState: response.data.pageState,
    };
  }

  static async create(data) {
    await http.post(
      `/log/activity-logs`,
      data,
    );
  }
}