import React, { useCallback, useState } from 'react';
import { 
  Button, Dialog, DialogActions,
   DialogContent, DialogTitle, TextField 
} from "@mui/material";
import { Text } from "../../../components/Text";
import { Box } from "../../../components/Box";
import { Column } from '../../../components/Column';
import { FormTypes, TextColors } from "../../../constants";
import { useFormikContext } from "formik";
import { DialogOfRoles } from '../../../components/DialogOfRoles';


export const Form = ({
  formType,
  show,
  privileges,
  onCancel,
  onSubmit,
}) => {
  const {
    values,
    isValid,
    errors,
    touched,
    setValues,
    handleChange,
    handleBlur,
  } = useFormikContext();

  const [dialogOfRolesOpen, setDialogOfRolesOpen] = useState(false);

  const privilegesClick = useCallback(() => {
    setDialogOfRolesOpen(true)
  }, []);

  const dialogOfRolesClose = useCallback(() => {
    setDialogOfRolesOpen(false)
  }, []);

  const dialogOfRolesSubmit = useCallback((newRoles) => {
    const newValues = {
      ...values,
      privileges: [...newRoles],
    }
    setValues(newValues);
  }, [values, setValues]);

  return (
    <Dialog
      open={show}
      onClose={onCancel}
    >
      <DialogTitle>
        <Text>
          {formType === FormTypes.Create && <>Create</>}
          {formType === FormTypes.Update && <>Edit</>}
        </Text>
      </DialogTitle>
      <DialogContent>
        <Box flexDirection="column" width="320px" padding="10px 0" gap="10px">
          <Box>
            <TextField
              name="featureSlug"
              label="Feature Slug"
              fullWidth
              disabled={formType === FormTypes.Update}
              value={values.featureSlug}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.featureSlug && errors.featureSlug}
              helperText={touched.featureSlug && errors.featureSlug}
            />
          </Box>
          <Box>
            <TextField
              name="featureDescription"
              label="Feature Description"
              fullWidth
              value={values.featureDescription}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.featureDescription && errors.featureDescription}
              helperText={touched.featureDescription && errors.featureDescription}
            />
          </Box>
          <Column>
            <Button
              fullWidth
              variant="outlined"
              color={errors.privileges ? 'error' : 'success'}
              onClick={privilegesClick}
            >
              Privileges
            </Button>
            {errors.privileges && (
              <Text color={TextColors.Error} size="12px">
                {errors.privileges}
              </Text>
            )}
            <DialogOfRoles
              title="Privileges"
              open={dialogOfRolesOpen}
              value={values.privileges}
              onSubmit={dialogOfRolesSubmit}
              onClose={dialogOfRolesClose}
            />
          </Column>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box gap="10px">
          <Box>
            <Button
              variant="contained"
              color="error"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="info"
              onClick={onSubmit}
              disabled={!isValid}
            >
              {formType === FormTypes.Create && <>Create</>}
              {formType === FormTypes.Update && <>Update</>}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
};