
export class UserPrivilegeModel {
  /// FILEDS
  /** @type {string} */
  email_address;
  /** @type {string[]} */
  attributes;
  /// RELATIONS
  /** @type {import('./user.model').User|null} */
  user;
  /// CONSTRUCTOR
  /** @param {Partial<UserPrivilegeModel>} data */
  constructor(data) {
    Object.assign(this, data);
  }
}