import styled from "@emotion/styled";
import { TableRow as MuiTableRow } from "@mui/material";

export const TableRow = styled(MuiTableRow)((/*{ theme }*/) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#111',
    border: 0,
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#222',
    border: 0,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));