import React, { useCallback } from 'react';
import { Row } from "../../Row"
import { Text } from "../../Text"

export const Checkbox = ({
  label,
  checked,
  onChange,
}) => {
  const toggleClick = useCallback(() => {
    onChange(!checked);
  }, [checked, onChange]);

  return (
    <Row bgcolor={checked ? 'red' : 'transparent'} cursor="pointer" padding="5px 10px" onClick={toggleClick}>
      <Text>{label}</Text>
    </Row>
  )
}