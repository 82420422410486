
/** @typedef {import('./user.model').UserModel} UserModel */

export class PostModel {
  /// CONSTANTS
  static PostTypes = {
    Post: 'POST',
    Rebound: 'REBOUND',
  }
  /// FIELDS
  /** @type {string} */
  author_id;
  /** @type {string} */
  post_type;
  /** @type {string} */
  post_id;
  /** @type {string[]} */
  ancestors;
  /** @type {number} */
  bumper_main_timestamp;
  /** @type {number[]} */
  bumper_timestamps;
  /** @type {string} */
  cta_url;
  /** @type {string} */
  device_meta;
  /** @type {string} */
  first_frame;
  /** @type {string[]} */
  hashtags;
  /** @type {number} */
  height;
  /** @type {string[]} */
  keywords;
  /** @type {string} */
  locale;
  /** @type {string} */
  media_name;
  /** @type {string} */
  narrative;
  /** @type {string} */
  post_origin_id;
  /** @type {string} */
  post_parent_id;
  /** @type {string} */
  post_status;
  /** @type {string} */
  rebound_image;
  /** @type {number} */
  sale_creator_commission_percentage;
  /** @type {string} */
  sale_creator_user_id;
  /** @type {string} */
  sale_datestamp;
  /** @type {number} */
  sale_price;
  /** @type {number} */
  sale_status;
  /** @type {string} */
  thumb_url;
  /** @type {string} */
  title;
  /** @type {string[]} */
  user_tags;
  /** @type {number} */
  video_length_ms;
  /** @type {string} */
  video_type;
  /** @type {number} */
  width;
  /// RELATIONS
  /** @type {UserModel|null} */
  user;
  /** @type {UserModel|null} */
  saleCreatorUser;
  /** @param {PostModel} data */
  constructor(data) {
    Object.assign(this, data);
  }
}

export class PostRecrdModel {
  /** @type {string} */
  '@timestamp';
  /** @type {string} */
  author_id;
  /** @type {string} */
  first_frame;
  /** @type {string[]} */
  hashtags;
  /** @type {number} */
  height;
  /** @type {string} */
  locale;
  /** @type {string} */
  media_name;
  /** @type {string} */
  narrative;
  /** @type {string} */
  post_id;
  /** @type {string|null} */
  post_origin_id;
  /** @type {string|null} */
  post_parent_id;
  /** @type {string} */
  post_status;
  /** @type {string} */
  post_type;
  /** @type {string} */
  rebound_image;
  /** @type {string} */
  thumb_url;
  /** @type {string} */
  title;
  /** @type {number} */
  video_length_ms;
  /** @type {string} */
  video_type;
  /** @type {number} */
  width;
}