import React from "react"
import styled from '@emotion/styled';
import { Icon, Text } from "../shared";

const View = styled.div`
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
`;

const ViewLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 23px;
  padding: 20px 0 20px 32px;
`;

const ViewRight = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 32px 16px 0;
`;

const Logo = styled.img`
  display: inline-flex;
  width: 93px;
  height: 21px;
  cursor: pointer;
`;

const Menu = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: 40px;
  border-radius: 6px;
  background-color: #252525;
  color: #FFFFFF;
  outline: none;
  border: none;
  cursor: pointer;
`;

const Search = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: #252525;
  border: 1px solid #FFFFFF0D;
  border-radius: 50%;
  cursor: pointer;
`;

export const Navigation = () => (
  <View>
    <ViewLeft>
      <Logo src="/icons/preview-logo-black.svg" alt="logo" />
      <Menu>
        <Text
          fontSize="12px"
          fontWeight="500"
          lineHeight="12px"
        >
          [ Menu ]
        </Text>
      </Menu>
    </ViewLeft>
    <ViewRight>
      <Search>
        <Icon
          src="/icons/preview-search.svg"
          width="14.33px"
          height="14.33px"
        />
      </Search>
    </ViewRight>
  </View>
);