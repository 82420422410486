import React from 'react';
import { useSelector } from 'react-redux';
import { hasFeatures, hasPrivileges } from '../../store/auth';

/** @typedef {import('react').ReactNode} ReactNode */
/** @typedef {import('../../models/user-privilege.model').UserPrivilegeModel} UserPrivilegeModel */
/** @typedef {import('../../models/user-privilege-feature.model').UserPrivilegeFeatureModel} UserPrivilegeFeatureModel */

/** 
 * @typedef {object} FeatureProps 
 * @property {UserPrivilegeModel[]} privileges
 * @property {UserPrivilegeFeatureModel[]} features
 * @property {boolean} workInProgress
 * @property {ReactNode} children
 */

/**
 * @param {FeatureProps} props 
 * @returns {ReactNode}
 */
export const Feature = ({
  privileges,
  features,
  workInProgress,
  children,
}) => {
  const isPrivileges = useSelector(hasPrivileges(privileges));
  const isFeatures = useSelector(hasFeatures(features));

  if (!isPrivileges) {
    return null;
  }

  if (!isFeatures) {
    return null;
  }

  if (workInProgress) {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
      return null;
    }
  }

  return <>{children}</>;
};