import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Drawer as MuiDrawer, Box as MuiBox, Typography, css, Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { logoutAction } from '../../store/auth';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import { getBreadcrumbs, getLayout, setBreadcrumbs, setLayout } from '../../store/ui';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Menu } from './Menu';
import { LogApi } from '../../api/log.api';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useUtility } from '../../utilities/hooks';

export const Breadcrumbs = styled(MuiBreadcrumbs)`
  max-width: 66vw;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    background-color: #000;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #404040;
  }

  & ol {
    flex-wrap: nowrap;

    & li > button > svg {
      background: #fff;
      fill: #000;
      border-radius: 4px;
    }
  }
`;

const BreadcrumbLink = styled(Typography)`
  color: ${({ text }) => !!text ? '#808080' : '#fff'};
  cursor: pointer;
  &:hover {
    text-decoration: ${({ text }) => !!text ? 'none' : 'underline'};
  }
`;

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    overflowY: 'auto',
    height: '100%',
    padding: '25px',
    background: 'linear-gradient(rgb(28, 39, 29) 0%, rgb(0, 0, 0) 79.98%) 0% 0% / cover',
    marginTop: '64px',
    marginLeft: '0px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
    scrollbarColor: '#333 #111',
    '&::-webkit-scrollbar': {
      background: '#111',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#333',
    }
  }),
);

const Drawer = styled(MuiDrawer)`
  background-color: #000;
  & .MuiDrawer-paper {
    background-color: #000;
    scrollbar-color: #333 #111;
    &::-webkit-scrollbar {
      background: #111;
    }
    &::-webkit-scrollbar-thumb {
      background: #333;
    }
  }
  ${({ open }) => open ? css`
    width: ${drawerWidth}px;
    flex-shrink: 0;
    & .MuiDrawer-paper {
      width: ${drawerWidth}px;
      box-sizing: border-box;
    }
  ` : null}
`;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: '#000',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0 8px',
  ...theme.mixins.toolbar,
  backgroundColor: '#000',
  justifyContent: 'flex-start',
}));

const Box = styled(MuiBox)`
  display: flex;
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  box-sizing: border-box;
  overflow: hidden;
`;

const TitleTypography = styled(Typography)`
  color: #fff;
`;

const Logo = styled.img`
  width: 80px;
  height: 20px;
  cursor: pointer;
`;

export const Layout = ({
  breadcrumbs: defaultBreadcrumbs,
  title,
  children
}) => {
  const dispatch = useDispatch();
  const breadcrumbs = useSelector(getBreadcrumbs);
  const { open, once } = useSelector(getLayout);
  const { goBack: back } = useHistory();
  const { navigate } = useUtility();

  useEffect(() => {
    if (!once) {
      dispatch(setLayout({ once: true }));
      dispatch(setBreadcrumbs(defaultBreadcrumbs));
    }
  }, [once, defaultBreadcrumbs, dispatch])

  const menuItemClick = useCallback((path) => {
    const label = path.replace(/^\//g, '')
      .replace(/[-/]+/g, ' ')
      .trim()
      .split(' ')
      .map(v => v.slice(0, 1).toUpperCase() + v.slice(1).toLowerCase())
      .join(' ');
    dispatch(setBreadcrumbs([{
      label,
      link: path,
    }]));
    navigate(path)
  }, [navigate, dispatch]);

  const logoutClick = useCallback(() => {
    localStorage.removeItem('method');
    localStorage.removeItem('token');
    localStorage.removeItem('token:web');
    dispatch(logoutAction());
    LogApi.create({
      message: 'sign out'
    });
  }, [dispatch]);

  const drawerOpen = useCallback(() => {
    dispatch(setLayout({ open: true }));
  }, [dispatch]);

  const drawerClose = useCallback(() => {
    dispatch(setLayout({ open: false }));
  }, [dispatch]);

  const backClick = useCallback(() => {
    dispatch(setBreadcrumbs([...breadcrumbs.slice(0, breadcrumbs.length - 1)]))
    back();
  }, [back, breadcrumbs, dispatch]);

  const breadcrumbLinks = useMemo(() => {
    return breadcrumbs.slice(0, breadcrumbs.length - 1);
  }, [breadcrumbs]);

  const breadcrumbLinkClick = useCallback(
    item => () => {
      const index = breadcrumbs.findIndex(v => v.link === item.link);
      dispatch(setBreadcrumbs([...breadcrumbs.slice(0, index + 1)]))
      navigate(item.link);
    }, [breadcrumbs, navigate, dispatch],
  );

  return (
    <Container>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {!open && (
            <MenuIcon onClick={drawerOpen} />
          )}
          <Box position="relative" flex="1" justifyContent="space-between" alignItems="center">
            <Box gap="25px" position="absolute" left={open ? '0px' : '10px'}>
              <ArrowBackIosIcon onClick={backClick} />
              {breadcrumbs.length > 0 && (
                <Breadcrumbs 
                  maxItems={2}
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  {breadcrumbLinks.map(v => (
                    <BreadcrumbLink key={v.link} onClick={breadcrumbLinkClick(v)}>
                      {v.label}
                    </BreadcrumbLink>
                  ))}
                  <BreadcrumbLink text="true" color="gray">
                    {breadcrumbs[breadcrumbs.length - 1].label}
                  </BreadcrumbLink>
                </Breadcrumbs>
              )}
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              width="100%" 
              marginLeft={open ? '0px' : '10px'}
            >
              <TitleTypography>
                {title}
              </TitleTypography>
            </Box>
            <Box
              position="absolute"
              right="0px"
            >
              <Button onClick={logoutClick}>
                LOGOUT
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        open={open}
        variant="persistent"
        anchor="left"
      >
        <DrawerHeader>
          {open && (
            <Logo src="/recrdlogoWhite.svg" onClick={drawerClose} />
          )}
        </DrawerHeader>
        <Divider />
        <Menu onMenuItemClick={menuItemClick} />
      </Drawer>
      <Main open={open} id="main">
        {children}
      </Main>
    </Container>
  )
}