import styled from "@emotion/styled";
import { TableCell as MuiTableCell, tableCellClasses } from "@mui/material";

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 0,
    color: '#fff',
  },
}));