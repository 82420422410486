
export class UserModel {
  /// FILEDS
  /** @type {string} [TimeUuid] */
  user_id;
  /** @type {string} */
  code_sms;
  /** @type {string} format: dd-MM-YYYY */
  dob;
  /** @type {string|null} */
  email_address;
  /** @type {boolean|null} */
  email_verified;
  /** @type {string|null} */
  full_name;
  /** @type {string|null} */
  last_login_tms;
  /** @type {string} */
  password_hash;
  /** @type {string|null} */
  phone_number;
  /** @type {boolean|null} */
  phone_verified;
  /** @type {string|null} */
  refresh_token;
  /** @type {string|null} */
  refreshed_at;
  /** @type {string|null} */
  user_name;
  /** @type {string|null} */
  user_status;
  /** @type {string|null} */
  user_type;
  /// RELATIONS
  /** @type {import('./user-settings.model').UserSettingsModel|null} */
  userSettings;
  /** @type {import('./user-privilege.model').UserPrivilegeModel|null} */
  UserPrivileges;
  /** @type {import('./user-privilege-feature.model').UserPrivilegeFeatureModel|null} */
  userFeatures;
  /// CONSTRUCTOR
  /** @param {Partial<UserModel>} data */
  constructor(data) {
    Object.assign(this, data);
  }
}