import React, { useCallback } from 'react';
import { Container } from "../../../../components/Container";
import { Row } from '../../../../components/Row';
import { Button } from '@mui/material';
import { PostApi } from '../../../../api/post.api';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../../store/ui';
import { SnackbarTypes } from '../../../../constants';

/** @typedef {import('react').ReactNode} ReactNode */
/** @typedef {import('../../../../models/post.model').PostModel} PostModel */

/** 
 * @typedef {object} ModerationProps
 * @property {PostModel} post
 * 
*/

/**
 * @param {ModerationProps} props 
 * @returns {ReactNode}
 */
export const Moderation = ({
  post,
}) => {
  const dispatch = useDispatch();
  const {
    post_id: postId,
  } = post ?? {};

  const moderate = useCallback(async (action, kind) => {
    try {
      if (postId === undefined) {
        throw Error();
      }
      await PostApi.moderatePost({
        postId,
        action,
      });
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: `the ${kind ?? action} moderation has been succeeded`,
      }));
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: `the ${kind ?? action} moderation has been failed`,
      }));
    }
  }, [postId, dispatch])

  const rawModerationClick = useCallback(() => {
    moderate('content_url', 'view raw uploaded post');
  }, [moderate]);

  const videoModerationClick = useCallback(() => {
    moderate('vm_url', 'view video moderation of this post');
  }, [moderate]);

  const textModerationClick = useCallback(async () => {
    moderate('tm_url', 'view text moderation of this post');
  }, [moderate]);

  const categoryModerationClick = useCallback(async () => {
    moderate('csc_url', 'view video categorisation of this post');
  }, [moderate]);

  return (
    <Container gap="10px">
      <Row>
        <Button
          variant="contained"
          color="error"
          onClick={rawModerationClick}
        >
          View raw uploaded post
        </Button>
      </Row>
      <Row>
        <Button
          variant="contained"
          color="error"
          onClick={videoModerationClick}
        >
          View video moderation of this post
        </Button>
      </Row>
      <Row>
        <Button
          variant="contained"
          color="error"
          onClick={textModerationClick}
        >
          View text moderation of this post
        </Button>
      </Row>
      <Row>
        <Button
          variant="contained"
          color="error"
          onClick={categoryModerationClick}
        >
          View video categorisation of this post
        </Button>
      </Row>
    </Container>
  );
}