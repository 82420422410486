import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { getLoadUser, getUser, hasFeatures, hasPrivileges, loadUserAction, logoutAction } from '../../store/auth';
import { ActionStatuses } from '../../constants';
import { NoAccess } from '../NoAccess';

export const AuthRoute = ({
  path,
  component,

  features,
  privileges,
}) => {
  const { push: navigate } = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const userHasFeatures = useSelector(hasFeatures(features));
  const userHasPrivileges = useSelector(hasPrivileges(privileges));
  const loadUser = useSelector(getLoadUser);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (loadUser === ActionStatuses.Rejected) {
      localStorage.removeItem('token');
      localStorage.removeItem('token:web');
      dispatch(logoutAction());
      navigate('/login');
    }
    if (!user && !token) {
      navigate('/login');
      return;
    }
    if (!user && token) {
      localStorage.setItem('token', token);
      dispatch(loadUserAction());
      return;
    }
    if (user && token) {
      return;
    }
  }, [user, token, loadUser, navigate, dispatch]);

  if (!user || !token) {
    return null;
  }

  if (privileges !== undefined && !userHasPrivileges) {
    return <NoAccess />
  }

  if (features !== undefined && !userHasFeatures) {
    return <NoAccess />
  }

  return (
    <Route 
      path={path}
      exact
      component={component}
    />
  );
};