

export const PreviewTypes = {
  Featured: 'featured_text',
  Editorial: 'editorial_text',
}

export const CardTypes = {
  Default: 0,
  Large: 1,
  Small: 2,
}
