
export class FeedbackUserModel {
  /** @type {string} */
  user_id;
  /** @type {string} */
  user_name;
  /** @type {string} */
  user_fullname;
  /** @type {string|null} */
  user_image;
  /** @type {string} */
  bio_full;
  /** @type {string} */
  bio_short;
  /** @param {FeedbackUserModel} data */
  constructor(data) {
    Object.assign(this, data);
  }
}

export class FeedbackHistoryModel {
  /** @type {string} */
  user_id;
  /** @type {string} */
  action_timestamp;
  /** @type {string} */
  status;
  /** @type {string} */
  description;
  /** @type {FeedbackUserModel} */
  user;
  /** @param {FeedbackHistoryModel} data */
  constructor(data) {
    Object.assign(this, data);
  }
}

export class FeedbackModel {
  /** @type {string} */
  feedback_id;
  /** @type {string} */
  user_id;
  /** @type {string} */
  created_at;
  /** @type {string} */
  user_name;
  /** @type {string[]} */
  user_attributes;
  /** @type {string} */
  message;
  /** @type {string} */
  subject;
  /** @type {string} */
  status;
  /** @type {FeedbackHistoryModel[]} */
  history;
  /** @type {FeedbackUserModel} */
  user;
  /** @param {FeedbackModel} data */
  constructor(data) {
    Object.assign(this, data);
  }
}