import { httpOfRecrd } from "./http";

export class NftApi {
  static async suspend({
    userId,
    saleAllowed,
    saleTrusted,
  }) {
    await httpOfRecrd.post(
      '/money_nft/v3/suspend', {
        userId,
        saleAllowed,
        saleTrusted,
      }
    );
  }
}