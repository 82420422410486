import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import AuthApi from '../../api/auth.api';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLoadUser, getUser, loadUserAction, logoutAction } from '../../store/auth';
import GoogleIcon from '@mui/icons-material/Google'
import { ActionStatuses } from '../../constants';
import { LogApi } from '../../api/log.api';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #000;
`;

const Logo = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 8px;
`;

const Image = styled.img`
  width: 80px;
  height: 20px;
  cursor: pointer;
`;

export const Login = () => {
  const { push: navigate } = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const loadUser = useSelector(getLoadUser);
  const [googleLoader, setGoogleLoader] = useState(true);
  const [isGoogleOneTapClosed, setIsGoogleOneTapClosed] = useState(false);

  useEffect(() => {
    const handler = (ev) => {
      if (ev.key === '\\') {
        localStorage.removeItem('method');
        localStorage.removeItem('token');
        localStorage.removeItem('token:web');
        dispatch(logoutAction());
        LogApi.create({
          message: 'sign out'
        });
        const url = `${window.location.protocol}//${window.location.host}`;
        const params = new URLSearchParams({
          continue: ['https://appengine.google.com/_ah/logout', url]
        });
        document.location.href = `https://www.google.com/accounts/Logout?${params}`;
      }
    }
    window.addEventListener('keyup', handler);
    return () => window.removeEventListener('keyup', handler);
  }, [dispatch]);

  useEffect(() => {
    if (!user) {
      return;
    }
    navigate(`/users`);
  }, [user, navigate]);

  useEffect(() => {
    if (isGoogleOneTapClosed) {
      window.google.accounts.id.renderButton(
        document.getElementById('googleLoginBtn'),
        { shape: 'pill', size: 'large', theme: 'outline' }
      );
    }
  }, [isGoogleOneTapClosed]);

  useEffect(() => {
    if (loadUser === ActionStatuses.Fulfilled) {
      navigate('/users');
    }
  }, [loadUser, navigate]);

  const handleCredentialResponse = useCallback(async result => {
    try {
      const { credential } = result;

      const response = await AuthApi.loginByGoogle({
        token: credential,
      });

      if (response?.message) {
        setIsGoogleOneTapClosed(false);
        return;
      }

      const { token, tokenWeb } = response;
      localStorage.setItem('method', 'google');
      localStorage.setItem('token', token);
      localStorage.setItem('token:web', tokenWeb)
      dispatch(loadUserAction());

      LogApi.create({
        message: 'sign in by google'
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsGoogleOneTapClosed(false);
    }
  }, [dispatch]);

  useEffect(() => {
    setGoogleLoader(true)
    if (!window.google?.accounts?.id) {
      setGoogleLoader(false);
      return;
    }
    window.google.accounts.id.initialize({
      callback: handleCredentialResponse,
      cancel_on_tap_outside: false,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      context: 'signin',
      prompt_parent_id: 'signin',
      ux_mode: 'popup',
    });
    window.google.accounts.id.prompt(notification => {
      if (notification.isNotDisplayed()) {
        console.error('authorization is not displayed');
        setGoogleLoader(false);
        setIsGoogleOneTapClosed(true);
      }
      if (notification.isSkippedMoment()) {
        console.error('Skip authorization');
        setGoogleLoader(false);
        setIsGoogleOneTapClosed(true);
      }
    });
  }, [handleCredentialResponse]);

  const {
    values,
    isValid,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email()
        .required(),
      password: Yup.string()
        .required(),
    })
  });

  const loginClick = useCallback(async () => {
    const { token, tokenWeb } = await AuthApi.loginByEmail({
      email: values.email,
      password: values.password,
    });
    if (!token) {
      throw Error();
    }
    localStorage.setItem('method', 'email');
    localStorage.setItem('token', token);
    localStorage.setItem('token:web', tokenWeb);
    dispatch(loadUserAction());
    LogApi.create({
      message: 'sign in by email'
    });
  }, [values, dispatch]);

  return (
    <Container>
      <Logo>
        <Image src="/recrdlogoWhite.svg" />
      </Logo>
      <Box display="flex" flexDirection="column" gap="10px" width="300px">
        <Box>
          <TextField
            label="Email"
            type="email"
            name="email" 
            fullWidth
            onChange={handleChange} 
            onBlur={handleBlur} 
          />
        </Box>
        <Box>
          <TextField
            label="Password"
            type="password"
            name="password" 
            fullWidth
            onChange={handleChange} 
            onBlur={handleBlur} 
          />
        </Box>
        <Box>
          <Button
            disabled={!isValid}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={loginClick}
          >
            Login
          </Button>
        </Box>
        <Box 
          id="signin"
          width="auto"
          height="auto"
          display="flex"
          justifyContent="center"
        >
          {googleLoader && (
            <Box width="100%" display="flex" justifyContent="center" alignItems="center" gap="10px">
              <GoogleIcon style={{ width: '47px', height: '47px' }} />
              <CircularProgress />
            </Box>
          )}
          {isGoogleOneTapClosed && <div id="googleLoginBtn" />}
        </Box>
      </Box>
    </Container>
  )
};