import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { FormTypes, TextColors, TextFontSizes, TextLabels } from '../../../constants';
import { Row } from '../../../components/Row';
import { Text } from '../../../components/Text';
import { useFormikContext } from 'formik';
import { Container } from '../../../components/Container';
import { PostApi } from '../../../api/post.api';
import { Column } from '../../../components/Column';

export const Form = ({
  formType = FormTypes.Create,
  isOpen = false,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const [options, setOptions] = useState([]);

  const {
    values,
    errors,
    isValid,
    setValues,
  } = useFormikContext();

  const loadPosts = useCallback(async () => {
    try {
      const { postId: inputValue } = values;
      if (inputValue === '') {
        const { posts: newPosts } = await PostApi.getPosts({
          limit: 10,
        });
        setOptions(newPosts);
      } else {
        const { data: newPostsOfMusi } = await PostApi.getPostsOfRecrdByTitle({
          q: inputValue
        });
        const { posts: newPostsOfRecord } = await PostApi.getPosts({
          postId: inputValue,
        });
        setOptions([...newPostsOfMusi, ...newPostsOfRecord]);
      }
    } catch (error) {
      console.error(error);
    }
  }, [values]);

  useEffect(() => {
    loadPosts();
  }, [loadPosts]);

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
    >
      <DialogTitle>
        <Row gap="5px">
          <Text size={TextFontSizes.Large}>
            Editorial Post: 
          </Text>
          <Text size={TextFontSizes.Large}>
            {formType === FormTypes.Create && 'New'}
            {formType === FormTypes.Update && 'Change'}
          </Text>
        </Row>
      </DialogTitle>
      <DialogContent>
        <Container gap="10px" width="300px">
          <Column>
            <Autocomplete
              sx={{ width: 300 }}
              options={options}
              inputValue={values.postId}
              getOptionLabel={(option) => option.post_id}
              filterOptions={(options) => {
                if (values.postId === '') {
                  return options;
                }
                // return options;
                return options.filter(
                  option => (
                    option?.post_id?.includes(values.postId) || 
                    option?.title?.includes(values.postId)
                  ),
                );
              }}
              autoComplete
              includeInputInList
              filterSelectedOptions
              onChange={(_event, newValue) => {
                console.log(newValue);
                setOptions(newValue ? [newValue, ...options] : [options]);
                setValues({ 
                  ...values,
                  postId: newValue?.post_id ?? '',
                })
              }}
              onInputChange={(event, newInputValue) => {
                setValues({ 
                  ...values,
                  postId: newInputValue,
                })
              }}
              renderInput={(params) => (
                <TextField {...params} label="Post ID" fullWidth />
              )}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    <Column width="100%" padding="5px 10px">
                      <Row gap="5px">
                        <Text color={TextColors.Label}>Post ID:</Text>
                        <Text color={TextColors.ID}>{option.post_id ?? TextLabels.Empty}</Text>
                      </Row>
                      <Row gap="5px">
                        <Text color={TextColors.Label}>Title:</Text>
                        <Text color={TextColors.Title}>{option.title ?? TextLabels.Empty}</Text>
                      </Row>
                    </Column>
                  </li>
                )
              }}
            />  
          </Column>
          <Column width="100%">
            {errors.postId && (
              <Text color={TextColors.Error}>
                {errors.postId}
              </Text>
            )}
          </Column>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          disabled={!isValid}
          variant="outlined"
          color="success"
          onClick={onSubmit}
        >
          {formType === FormTypes.Create && 'Create'}
          {formType === FormTypes.Update && 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}