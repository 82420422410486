
/** @typedef {import('./post.model').PostModel} PostModel */

export class EditorialZoneDataModel {
  /** @type {string}  */
  locale;
  /** @type {string} */
  country;
  /** @type {string} */
  region;
  /** @type {string} */
  city;
  /** @type {string} */
  zone;
  /** @type {number} */
  zonePostCount;
  /** @type {PostModel|undefined} */
  post;
}

export class EditorialZoneModel {
  /** @type {string} */
  placement;
  /** @type {EditorialZoneDataModel[]} */
  data;
  /** @param {Partial<EditorialZoneModel>} */
  constructor(data) {
    Object.assign(this, data);
  }
}