import React, { useCallback, useEffect, useState } from 'react';
import { Container } from "../../../../components/Container";
import { Loader, LoaderTypes } from '../../../../components/Loader';
import { S3Card } from './S3Card';
import { TextField } from '@mui/material';
import { Row } from '../../../../components/Row';
import { Text } from '../../../../components/Text';
import { Box } from '../../../../components/Box';
import CloseIcon from '@mui/icons-material/CloseRounded';

/** @typedef {import('../../../../models/s3-post.model').S3PostModel} S3PostModel */

/**
 * @param {object} props
 * @param {S3PostModel[]} props.s3Posts 
 * @param {boolean} props.loadingOfS3Posts
 * @return {import('react').ReactNode}
 */
export const S3 = ({
  s3Posts,
  loadingOfS3Posts,
}) => {
  const [searchValue, setSearchValue] = useState('');
  /** @type {[S3PostModel[], (v: S3PostModel) => void]} */
  const [posts, setPosts] = useState([]);
  const [amountOfMatches, setAmountOfMatches] = useState(0);

  useEffect(() => {
    if (searchValue === '') {
      setPosts(s3Posts);
      setAmountOfMatches(0);
      return;
    }
    const newPosts = s3Posts.filter(v => {
      if (v.bucket.includes(searchValue)) {
        return true;
      }
      try {
        const jsonText = JSON.stringify(v.json, null, 2);
        if (jsonText.includes(searchValue)) {
          return true;
        }
      } catch {}
      return false;
    });
    setAmountOfMatches(newPosts.length);
    setPosts(newPosts);
  }, [searchValue, s3Posts]);

  const searchChange = useCallback((event) => {
    const { target: { value } } = event;
    setSearchValue(value);
  }, []);

  const closeSearchClick = useCallback(() => {
    setSearchValue('');
  }, []);

  return (
    <Container>
      <Loader type={LoaderTypes.Container} loading={loadingOfS3Posts} />
      {!loadingOfS3Posts && (
        <>
          <Row position="relative" padding="10px 0">
            <TextField
              label="Search"
              placeholder="Enter a text..."
              fullWidth
              value={searchValue}
              onChange={searchChange}
            />
            {searchValue?.length > 0 && (
              <Box 
                position="absolute" 
                alignItems="center" 
                height="100%" 
                right="0" 
                top="0" 
                padding="0 20px 0 0"
                gap="5px"
              >
                <Box>
                  <Text>Matches: {amountOfMatches}</Text>
                </Box>
                <Box>
                  <CloseIcon onClick={closeSearchClick} />
                </Box>
              </Box>
            )}
          </Row>
          {posts.map(s3Post => (
            <S3Card
              key={s3Post.bucket}
              s3Post={s3Post}
              searchValue={searchValue}
            />
          ))}
        </>
      )}
    </Container>
  )
}