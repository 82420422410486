import { Button, Dialog, DialogContent, DialogTitle, Table, TableHead, TextField } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Column } from '../../../components/Column';
import { Row } from '../../../components/Row';
import { Box } from '../../../components/Box';
import CloseIcon from '@mui/icons-material/Close'
import { TableRow } from '../../../components/Table/TableRow';
import { TableBody } from '../../../components/Table/TableBody';
import { TableCell } from '../../../components/Table/TableCell';

/**
 * 
 * @param {object} props
 * @param {boolean} props.open
 * @param {string} props.zone
 * @param {string} props.prompts
 * @param {() => void} props.onClose 
 * @returns 
 */
export const Prompts = ({
  open,
  zone,
  prompts,
  onClose,
  onCreate,
}) => {
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    setValues,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues: {
      prompt: '',
    },
    validationSchema: yup.object().shape({
      prompt: yup.string()
        .required(),
    })
  });

  useEffect(() => {
    if (open) {
      setValues({
        prompt: '',
      });
    }
  }, [open, setValues])

  const createPromptClick = useCallback(async () => {
    if (!isValid) {
      return;
    }
    const { prompt: newPrompt } = values;
    onCreate(newPrompt);
  }, [values, isValid, onCreate]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Row justifyContent="space-between">
          <Box>
            Prompts: {zone}
          </Box>
          <Box>
            <CloseIcon onClick={onClose} />
          </Box>
        </Row>
      </DialogTitle>
      <DialogContent>
        <Column width="100%" gap="10px">
          <Row gap="10px" alignItems="center">
            <Box flex="1">
              <TextField 
                fullWidth
                error={touched.prompt && errors.prompt}
                helperText={touched.prompt && errors.prompt}
                label="Prompt"
                name="prompt"
                value={values.prompt}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            <Box position="relative" top={touched.prompt && errors.prompt ? '-15px' : '0px'}>
              <Button
                variant="contained"
                color="success"
                disabled={!dirty || !isValid}
                onClick={createPromptClick}
              >
                Create
              </Button>
            </Box>
          </Row>
          <Row>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Prompt
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prompts.lenght === 0 && (
                  <TableRow>
                    <TableCell>
                      No prompts
                    </TableCell>
                  </TableRow>
                )}
                {prompts.map((prompt, index) => (
                  <TableRow key={prompt + index}>
                    <TableCell>
                      {prompt}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Row>
        </Column>
      </DialogContent>
    </Dialog>
  )
}