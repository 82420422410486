import axios from "axios";
import { EditorialZoneModel } from "../models/editorial-zone.model";
import { EditorialPostModel } from "../models/eidtorial-post.model";
import { httpOfRecrd } from "./http";

/** @typedef {import("axios").AxiosResponse} AxiosResponse */
/** @typedef {import('../models/editorial-text.model').EditorialTextModel} EditorialTextModel */

export class EditorialApi {
  static async getZones() {
    const response = await httpOfRecrd.get(`/internal/editorial/v3/zones`);
    return {
      data: response.data,
      response,
    };
  }

  /**
   * @returns {Promise<EditorialZoneModel>}
   */
  static async getEditorialZone({ placement }) {
    const params = new URLSearchParams({ placement });
    const response = await httpOfRecrd.get(`/internal/editorial/v3/zone?${params}`);
    const models = response.data.map(v => new EditorialZoneModel(v));
    const [model] = models;
    return model;
  }

  static async createEditorialZone({
    placement,
    zone,
    country,
    region,
    city,
    locale,
  }) {
    await httpOfRecrd.post(
      `/internal/editorial/v3/zone`,
      {
        placement,
        zone,
        country,
        region,
        city,
        locale,
      }
    )
  }

  static async deleteEditorialZone({ 
    zone,
    country,
    region,
    city,
    locale,
  }) {
    await httpOfRecrd.delete(
      `/internal/editorial/v3/zone`,
      {
        data: {
          zone,
          country,
          region,
          city,
          locale,
        }
      }
    )
  }

  /**
   * @returns {Promise<EditorialPostModel[]>}
   */
  static async getEditorialPosts({
    placement,
    zone,

    page,
    size,
  }) {
    const params = new URLSearchParams({
      placement,
      zone,
      page: page !== undefined ? page : 0,
      size: size !== undefined ? size : 25,
    });
    const response = await httpOfRecrd.get(`/internal/editorial/v3/post?${params}`);
    const models = response.data.postData.map(v => new EditorialPostModel(v));
    return models;
  }

  static async createEditorialPost({
    placement,
    zone,
    postId,
  }) {
    await httpOfRecrd.post(
      `/internal/editorial/v3/post`,
      {
        placement,
        zone,
        post_id: postId,
      }
    )
  }

  static async deleteEditorialPost({
    placement,
    zone,
    postId,
  }) {
    await httpOfRecrd.delete(
      `/internal/editorial/v3/post`,
      {
        data: {
          placement,
          zone,
          post_id: postId,
        }
      }
    )
  }

  /**
   * 
   * @param {object} props
   * @param {string} props.zone 
   * @returns {Promise<{ data: string[], response: AxiosResponse}>}
   */
  static async getEditorialPrompts({
    zone
  }) {
    const params = new URLSearchParams({ zone });
    const response = await httpOfRecrd.get(
      `/internal/editorial/v3/prompt?${params}`,
    );
    return {
      data: response.data,
      response,
    };
  }

  /**
   * 
   * @param {object} props
   * @param {string} props.zones
   * @param {string[]} props.prompts
   * @returns {Promise<{ response: AxiosResponse }>}
   */
  static async createEditorialPrompt({
    zone,
    prompts,
  }) {
    const response = await httpOfRecrd.post(
      `/internal/editorial/v3/prompt`, {
        zone,
        promptText: prompts,
      }
    );
    return { response };
  }

  /**
   * 
   * @param {object} props
   * @param {string} props.zone 
   * @param {string} props.placement
   * @returns {Promise<{ data: EditorialTextModel[], response: AxiosResponse}>}
   */
  static async getEditorialTexts({
    zone,
    placement,
  }) {
    const params = new URLSearchParams({ 
      zone, 
      placement,
    });
    const response = await httpOfRecrd.get(
      `/internal/editorial/v3/text?${params}`,
    );
    return {
      data: response.data,
      response,
    };
  }

  /**
   * 
   * @param {object} props
   * @param {string} props.zone
   * @param {string} props.placement
   * @param {string} props.title
   * @param {string} props.body
   * @returns {Promise<{ response: AxiosResponse }>}
   */
  static async createEditorialText({
    zone,
    placement,
    title,
    body,
  }) {
    const response = await httpOfRecrd.post(
      `/internal/editorial/v3/text`, {
        zone,
        placement,
        title,
        body,
      }
    );
    return { response };
  }

  static async getIsoCodes() {
    const response = await axios.get('/data/iso-codes.json');
    return {
      data: response.data,
      response,
    };
  }
}